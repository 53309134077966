import React from "react";
import { useNavigate } from "react-router-dom";
import "./BannerImage.css";

import Submitbtn from "../../components/submit/Submitbtn";

export function BannerImage() {
  const navigate=useNavigate();
  const onSubmitForm=()=>{
    navigate("/Movieslist")
    window.scrollTo(0, 0)
  }
  return (
    <div className="Bannerfirst">
      <div className="textcontain">
        <h6 className="bfheading"> ACTION , ADVENTURE, SCI-FCI</h6>
        <h1 className="bsheading"> The Fantastic Four</h1>
        <div className="icon-list">
        </div>
        <p className="paragraph">
          {" "}
          One of Marvel's most iconic families makes it back to the big screen, the Fantastic Four. 
        </p>
        <Submitbtn onSubmitForm={onSubmitForm} props={"Explore More"}/>
      </div>
    </div>
  );
}
