import React,{useRef,useState,useEffect} from "react";
import "./Editprofile.css";
import { UseEditprofileformvalidation} from "./UseEditprofileformvalidation";
import Subheader from "../../../components/subheader/Subheader";
import { Footer } from "../../../components/footer/footer";
import ScrollButton from "../../../components/scrollbutton/ScrollButton";
import Sidebar from "../../../components/sidebar/Sidebar";
import Submitbtn from "../../../components/submit/Submitbtn";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import swal from "sweetalert";
import Navbarr from "../../../components/header/Navbarr";
import dayjs from "dayjs";
import {editprofilePost,editpGet} from "../../../api/handleapi"
import { Errormsg } from "../../../components/tostifymsg/Errrormsg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoginuserinfo } from '../../../feature/showSlice/loginuserinfoSlice';
import Cookies from 'js-cookie';
import Loader from "../../../components/loader/Loader";
import { editprofilesuccessfullyMsg, editprofilevalidation, errorstatuscheck, reloginMsg, SuccessTitle } from "../../../components/language/Language";
import { cookieNames } from "../../../Config/config";
import { handle401Error } from "../../../components/handle401Error";
import Googleadshorizontal from "../../../components/google-AdSense/Googleadshorizontal";


function Editprofile() {
  const Navigate=useNavigate();
  const dispatch=useDispatch();
  const [loading,setLoading]=useState(false);
  const id=Cookies.get("id");
  const [post, setPost] = useState([]);

  
  const editpFun = async (id) => {
    try {
      const response = await editpGet(id); // You might want to pass some parameters to get
      setPost(response?.data);
      setForm({firstName:response?.data?.firstName,lastName:response?.data?.lastName,gender:response?.data?.gender, Imagesfile:"",dob:response?.data?.dob})
      dispatch(setLoginuserinfo({userprofileimage:response?.data?.image,name:response?.data?.firstName+" "+response?.data?.lastName,email:response?.data?.email,userid:id}))
       Cookies.set('userprofileimage', response?.data?.image)
    } catch (error) {
      if(error?.message===errorstatuscheck){         
        await Errormsg(reloginMsg);
        await handle401Error(cookieNames)
        await Navigate("/");
      }
      
    }
  };
  useEffect(() => {
     editpFun(id);
  }, [id]);


  const [form, setForm] = useState({
    id:"",
    firstName: "",
    lastName: "",
    gender: "",
    Image:"",
    Imagesfile:"",
    dob: "",
  });

  const handleProfile = async () => {
    setLoading(true)
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("firstName", form?.firstName);
    bodyFormData.append("lastName", form?.lastName);
    bodyFormData.append("gender", form?.gender);
    bodyFormData.append("dob", form?.dob);
    bodyFormData.append("Image", form?.Image);
    bodyFormData.append("Imagesfile", form?.Imagesfile);
    try{
      const response = await editprofilePost(id,bodyFormData)
         if (response?.status === 200) {
           setLoading(false);
          editpFun(Cookies.get("id"));  
        Cookies.set('userprofileimage', response?.data?.image)
         await swal({
          title: SuccessTitle,
          text: editprofilesuccessfullyMsg,
          icon: "success",
          buttons: "ok",
          closeOnClickOutside:false,
          dangerMode: false
        })  
    }else {
      await swal({
        title: "",
        text: editprofilevalidation,
        icon: "error",
        buttons: "Ok",
        closeOnClickOutside:false,
        dangerMode: true,
      });
    }
  }catch (error) {
    if (error.response) {
      setLoading(false)
      // The request was made and the server responded with a status code
    } 
  return;
  }
  };
  

  const { errors, validateForm, onBlurField } =
  UseEditprofileformvalidation(form);
  
  const onUpdateField = (e) => {
    const field = e.target.name;
    const nextFormState = { ...form, [field]: e.target.value };
    setForm(nextFormState);
    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    let isAnyFieldFilled = false;

  // Check if any field has been updated
    if (form.firstName  || form.lastName|| form.gender || form.Imagesfile  || form.dob) {
      isAnyFieldFilled = true;
    }
       const { isValid } = validateForm({
      form,
      errors,
      forceTouchErrors: true,
    });
  
    if (isValid) {
      handleProfile();
    } else {
      swal({
        title: "",
        text: editprofilevalidation,
        icon: "error",
        buttons:"OK",
        closeOnClickOutside:false,
        dangerMode: true,
      });
    }
  };

 
  
  const onUpdateFielddob = (date) => {
    const nextFormState = { ...form, dob: date };
    setForm(nextFormState);
    if (errors.dob?.dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: "dob",
      });
    }
  };
 

  
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Update the state with the selected file
     setForm({ ...form, Imagesfile: file   });
        // Perform validation only if the file is selected and there are existing errors
      if (errors.Imagesfile?.dirty) {
        validateForm({
         form: { ...form, Imagesfile: file}, // Pass the updated state with the file
          errors,
          field: "Imagesfile",
        });
      }
    }
  };
  
  return (
    <div>
      <Navbarr/>
      <div className="after-nav containers con_div ">
      <Googleadshorizontal props="pt-2 pb-2 text-center"/>
        <Subheader />
        <br />
        <div className="row g-0">
          <Sidebar />
          <div className="col-md-8 col-lg-9 col-xl-10">
            <div className="card   edit_primain">
              <nav className="navbar  nav-editprofile ">
                <div className="navbar-brand mb-0 h1 edit_text">
                  Edit Profile
                </div>
              </nav>
              <div className="edit_profile_seconddiv ">
                <div className="edit_pro_inpfields ">
                  <div className="row ">
                    <div className="col-md-6 col-lg-4 col-xl-4 mt-2">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label edit_pro_label"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        autocomplete="off"
                        id="inputField1"
                        name="firstName"
                        className="form-control edit_pro_input"
                       
                        aria-label="firstname"
                        value={form?.firstName}
                        onChange={onUpdateField}
                        onBlur={onBlurField}
                      />
                        {errors.firstName.dirty && errors.firstName.error ? (
              <p className="c_leagueFieldErrorMessage">
                {errors.firstName.message}
              </p>
            ) : null}
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-4 mt-2">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label edit_pro_label"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        autocomplete="off"
                        id="inputField2"
                        name="lastName"
                        className="form-control edit_pro_input"
                        
                        aria-label="lastname"
                        value={form?.lastName}
                        onChange={onUpdateField}
                         onBlur={onBlurField}
                      />
                        {errors.lastName.dirty && errors.lastName.error ? (
              <p className="c_leagueFieldErrorMessage">
                {errors.lastName.message}
              </p>
            ) : null}
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-4 mt-2">
                    <label
                        for="exampleFormControlInput1"
                        className="form-label edit_pro_label"
                      >
                        Gender
                      </label>
                      <div > 
              <select
              type="text"
              id="inputField3"
              name="gender"
              className="w-100 select_div form-select"
              aria-label="gender field"
              value={form.gender}
                      onChange={onUpdateField}
                      onBlur={onBlurField}>
       {post?.gender && (
    <option className="disable_option" value={post?.gender} disabled={true}>{post?.gender}</option>
  )}
       <option value="Male" label="Male">Male</option>
       <option value="Female" label="Female"> Female</option>
       <option value="Prefer Not to Say">Prefer Not to Say</option>
     </select>
     {errors.gender.dirty && errors.gender.error ? (
          <p className="c_leagueFieldErrorMessage">
            {errors.gender.message}
          </p>
        ) : null}
     </div>
   </div>
                  </div>
                  <div className="row secondrow_div">      
                  <div className="col-md-6 col-lg-4 col-xl-4 input-container ">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Upload Image
                    </label>
                      <input
                  type="file"
                  id="inputField4"
                   autocomplete="off"
                  accept="image/png, image/jpeg"
                  placeholder="choose file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                 
                />
                <input
              
                  value={form?.Imagesfile ?form?.Imagesfile?.name:""}
                   placeholder="Upload Image"
                   autocomplete="off"
                  name="Imagesfile"
                  className="form-control inputf-controlupload"
                  aria-label="img_upload"
                  
                />
                <button className="img-upload-btn" onClick={handleButtonClick}>
                  Upload
                </button>
                {errors.Imagesfile.dirty && errors.Imagesfile.error ? (
                  <p className="c_leagueFieldErrorMessage">
                    {errors.Imagesfile.message}
                  </p>
                ) : null}
                  </div>
                    <div className="col-md-6 col-lg-4 col-xl-4 Dob_div">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label edit_pro_label"
                      >
                        Date of Birth
                      </label>
                      <div className="zedo">
                      <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                  
                    className="w-100"
                    id="inputField5"
                    
                   name="dob"
                   value={dayjs(post?.dob)}
             
              onChange={onUpdateFielddob}
              onBlur={onBlurField}
              disableFuture
              error
              sx={{
                ".MuiOutlinedInput-root": {
                  color: "#e6e6e6 !important",
                  borderColor: "#286ac0",
                  borderRadius: "5px",
                },
                ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#286ac0 !important",
                    borderRadius: "5px !important",
                  },

                ".MuiButtonBase-root": {
                  color: "#286ac0 !important",
                },
              }}
             
            />
          </DemoContainer>
        </LocalizationProvider>
        {errors.dob.dirty && errors.dob.error ? (
          <p className="c_leagueFieldErrorMessage">{errors.dob.message}</p>
        ) : null}
        </div>
                    </div>
                    <div>
                    </div>
    
                  </div>
                  <div className="edit_submit_btn mt-5 pt-5">
                    <Submitbtn onSubmitForm={onSubmitForm} props={"SUBMIT"}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      {loading?<Loader/>:null}
      <ScrollButton />
    </div>
  );
}

export default Editprofile;
