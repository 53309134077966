import React, { useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import { MdClose } from "react-icons/md";
import { useSelector, useDispatch } from 'react-redux';
import "./Modalc.css";
import  { ReactComponent as YourIconn } from "../../assets/arrow-left.svg"

export const Modalc = ({openforgot,setOpenforgot,setToggleform,setShowotp,content,contentr,showModalTwo, setShowModalTwo,closeModal,showsubscriptionpayment}) => {
  const dispatch = useDispatch();
  const showModal = useSelector((state) => state.showModal);

  const modalRef = useRef();
  const animation = useSpring({
    config: {
      duration: 350,
    },
    opacity: showModal||showModalTwo ? 1 : 0,
    transform: showModal ||showModalTwo? `translateY(0%)` : `translateY(-100%)`,
  });
 
  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && "showModal") {
        dispatch(closeModal());
        setShowModalTwo(false);
      }
    },
    [showModal,showModalTwo]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    const disableScroll = (e) => {
      e.preventDefault(); // Prevent the default scroll behavior
      window.scroll(0, 0); // Keep the window at the top position
    };

    if (showModal||showModalTwo) {
      document.body.style.overflow = "hidden"; // Disable scrolling on the body
      window.addEventListener("scroll", disableScroll); // Prevent scrolling on the window
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling on the body
      window.removeEventListener("scroll", disableScroll); // Enable scrolling on the window
    }

    return () => {
      document.body.style.overflow = "auto"; // Make sure to enable scrolling on the body when the component is unmounted
      window.removeEventListener("scroll", disableScroll); // Enable scrolling on the window when the component is unmounted
    };
  }, [showModal,showModalTwo]);

  return (
    <div>
      {showModal ||showModalTwo? (
        <div ref={modalRef} className="background">
          <animated.div style={animation} className="closemodalcontent">
            <div  className="ModalWrapper">
            <div className="d-flex w-100">
                  {openforgot===false?"":<button className='d-flex p-1 align-items-center gap-1 go_back' onClick={()=>{setOpenforgot(false);
                  setShowotp(null);
                  setToggleform(false);
                }}> 
  <div><YourIconn fill="white" className="arrow-left" /></div>
  <div className="text-white">Back</div>
  </button>}
  {showsubscriptionpayment===false?<div
               className="CloseModalButton"
                aria-label="Close modal"
                onClick={() => {
                  dispatch(closeModal());
                  setOpenforgot(false);
                  setShowotp(null);
                  setToggleform(false);
                  setShowModalTwo(false)
                }}
              >
              <MdClose className="close_btn"/>
            </div>:null}
            </div>
              <div className="ModalContent">
              {showModal==true && showModalTwo==false?content:contentr}
              </div>
            </div>
          </animated.div>
        </div>
      ) : null}
    </div>
  );
};
