import React,{useState,useEffect} from "react";
import "../../profile/editprofile/Editprofile";
import Subheader from "../../../components/subheader/Subheader";
import { Footer } from "../../../components/footer/footer";
import ScrollButton from "../../../components/scrollbutton/ScrollButton";
import firstitem from "../../../assets/dummy-movie.png";
import Sidebar from "../../../components/sidebar/Sidebar";
import "./Watchlist.css";
import Navbarr from "../../../components/header/Navbarr";
import LoadMore from "../../../components/loadmore/LoadMore";
import { ReactComponent as YourIconclose} from "../../../assets/Group 44566.svg";
import { Succesmsg } from "../../../components/tostifymsg/Succesmsg";
import { Errormsg } from "../../../components/tostifymsg/Errrormsg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import  {WatchlistmoviesGet,watchlistmovieremovePost}  from "../../../api/handleapi";
import { useNavigate } from "react-router-dom";
import Searchmovies from "../../../components/searchmovies/Searchmovies";

import { setWatchlistcount } from '../../../feature/showSlice/watchlistcountSlice';
import { useSelector,useDispatch } from "react-redux";
import Cookies from 'js-cookie';
import { useCategory } from "../../../components/useCategory";
import { cookieNames, movieposterpath } from "../../../Config/config";
import { APIerrorTitle, errorstatuscheck, reloginMsg, removedtoWatchlist } from "../../../components/language/Language";
import { handle401Error } from "../../../components/handle401Error";
import Googleadshorizontal from "../../../components/google-AdSense/Googleadshorizontal";

function Watchlist() {
const dispatch=useDispatch();
const [post, setPost] = useState([]);
const id=Cookies.get("id");
const [hiddenButtons, setHiddenButtons] = useState({});
const [visibleItems, setVisibleItems] = useState(12);
const [showload, setShowload] = useState(true);
const [fetchdata , setFetchData] = useState(false);
const Navigate=useNavigate();

const watchlistcount = useSelector((state) => state.watchlistcount);


  const handleLoadMore = () => {
    const nextVisibleItems = Math.min(visibleItems + 6, post?.length);
    setVisibleItems(nextVisibleItems);
   
    if (nextVisibleItems == post?.length) {
      setShowload(false)}
  };
  const Categoryoptions=useCategory();
  useEffect(() => {
    const WatchlistFun = async (id) => {
      try {
        const response = await WatchlistmoviesGet(id); // You might want to pass some parameters to watchlistget
        const watchlistdata=response?.data?.data?.reverse();
        setPost(watchlistdata)
      } catch (error) {
        if(error?.message===errorstatuscheck){         
          await Errormsg(reloginMsg);
          await handle401Error(cookieNames)
          await Navigate("/");
          return;
        }
      
      }
    };
    WatchlistFun(id);
      }, [fetchdata]);

  const removewatchlistPost =async (movieId) => {
    var bodyFormData = new FormData();
    bodyFormData.append("movieId", movieId);
    bodyFormData.append("userId", id);
    try {
      const response = await watchlistmovieremovePost(bodyFormData); 
      if (response?.status === 200) {
        setFetchData(!fetchdata)
        Succesmsg(removedtoWatchlist)
        setHiddenButtons(prevState => ({ ...prevState, [movieId]: true })); 
        dispatch(setWatchlistcount(watchlistcount-1));
      }
    } catch (error) {
        // The request was made and the server responded with a status code
        await Errormsg(APIerrorTitle);
        return;
    }
  };
  const [inputText, setInputText] = useState("");

let inputHandler = (e) => {
  var trimmedText = e.target.value.trim();
  // Convert trimmed input text to lowercase
  var lowerCase = trimmedText.toLowerCase();
  setInputText(lowerCase);
};


const filteredData = post?.filter((el) => {
  const nameMatch = el.name.toLowerCase().includes(inputText);
  if (inputText !== '') {
    // Filter by movie name only
    return nameMatch;
  } else {
    // If input is empty, return all movies
    return true;
  }
});
 
  return (
    <div>
      <Navbarr/>
      <ToastContainer />
      <div className="after-nav containers con_div">
       <Googleadshorizontal props="pt-2 pb-2 text-center"/>
        <Subheader />
        <br />
        <div className="row">
          <Sidebar />
          <div className="col-xxl-10 col-lg-9 col-md-8 col-sm-12 mt-md-0 mt-3">
            <div className="card   watchlisting_primain">
              <nav className="navbar  nav-editprofile ">
                <span className=" mb-0 h1 watchl_text">
                  Watchlist
                </span>
                <span className="watchlist_searchbox">  <Searchmovies inputHandler={inputHandler}/></span>
              </nav>
              <div className="edit_profile_seconddiv ">
                <div className="watchlist_cards">
                  <div className="cards_container">
                  <div className="row g-2">
                  {filteredData?.length > 0 ? (
                  filteredData?.slice(0, visibleItems).map((item, index) => (
                    <div  key={index} className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-4 col-6">
                      <div className="ccarddm mmovielistcard">
                    {!hiddenButtons[item?.id] &&<div>
                         <div className="watchlist_img cus_iamgecstm">
                    
  {item?.poster?.includes("https://m.media-amazon.com/images") ? 
    <img src={item?.poster} alt="img"  onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem;
    }} /> : 
    <img src={movieposterpath + item?.poster} alt="img"  onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem;
    }} />
  }

                        <div><YourIconclose className="close_watchlist" onClick={() => removewatchlistPost(item?.id)}/></div>
                              </div>
                         <div className="card-body Mcbody">
                        <h5>{item?.name}</h5>
                        <span className="text-offw"> Release year : {item?.releaseYear}</span>
                        <div className="mdetails">
                          <ul className="movie_list_ul">
                              {item?.category && item?.category.split(',').map((categoryId, index) => (
            <span key={index} className="text-offw">
              {Categoryoptions.find(cat => cat?.id == categoryId)?.category}
              </span>
            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    }
                    </div>
                    
                    </div>
                  )))
                  : (
                    <div className="text-white pt-2">
                      <p>No Movies found</p>
                    </div>
                  )}
                  </div>
                  </div>
                {filteredData?.length>12?<div> {showload?<LoadMore onLoadMore={handleLoadMore} />:null}</div>:null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <ScrollButton />
    </div>
  );
}

export default Watchlist;
