import React from "react";
import "./Sidebar.css";
import { NavLink } from "react-router-dom";
import { imgpath } from "../../Config/config";
import profileimg1 from "../../assets/large profileicon.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Googleads from "../google-AdSense/Googleads";
function Sidebar() {
  const navigate=useNavigate()
  const username=Cookies.get("name")
  const userprofileimage=Cookies.get("userprofileimage")
  const loginuserinfo = useSelector((state) => state.loginuserinfo);


  const logOut = () => {
    Cookies.remove('token');
   Cookies.remove('rrefreshToken');
    Cookies.remove('email');
    Cookies.remove('name');
    Cookies.remove('id');
    Cookies.remove('username');
    Cookies.remove('userprofileimage');
    Cookies.remove('Paymentdetail');
    Cookies.remove("noofjoinplayers");
    Cookies.remove("secondpid")
    Cookies.remove("secondpusername")
    Cookies.remove("navivalue")
    Cookies.remove("trademsg")
    Cookies.remove("tradeaccept")
    Cookies.remove("cleagueid")
    localStorage.clear()

  navigate("/")
  };

  
  return (
    <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-12">
      <div className="card  edit_pro_sidebar">
      <div className="edit_pro_largicon">
        <div className="imag_set"> <img src={(loginuserinfo?.userprofileimage==undefined)?imgpath+userprofileimage:imgpath+loginuserinfo?.userprofileimage}    onError={(e) => {
    e.target.onerror = null;
    e.target.src = profileimg1 }}/></div>
       
        <p className="edit_pro_p">
          
          {(loginuserinfo?.name === undefined ? username?.charAt(0).toUpperCase() + username?.slice(1) : loginuserinfo?.name?.charAt(0).toUpperCase() + loginuserinfo?.name.slice(1))}
          </p>
      </div>
      <div className="edit_profie_a">
        <NavLink
          to="/Editprofile"
          activeClassName="active"
          className="a_ele_edit btn  myl-btn-editp "
        >
          {" "}
          Edit Profile
        </NavLink>

        <NavLink
          to="/Changepassword"
          activeClassName="active"
          className="a_ele_edit btn  myl-btn-editp "
        >
          {" "}
          Change password{" "}
        </NavLink>

        <NavLink
          to="/Watchlist"
          activeClassName="active"
          className="a_ele_edit btn  myl-btn-editp "
        >
          {" "}
          Watchlist
        </NavLink>
        <NavLink
          to="/Subscriptionpayment"
          activeClassName="active"
          className="a_ele_edit btn  myl-btn-editp "
        >
          {" "}
          Subscribe
        </NavLink>

        <NavLink
          to="/"
          activeClassName="active"
          className="a_ele_edit btn  myl-btn-editp "
          onClick={logOut}
        >
          Logout
        </NavLink>
        
      </div>
      <div className="pt-3 p-1">
                           
                          
                           <Googleads props="parent_dummyads pt-2 pb-2 text-center"/>
                           </div>
    </div>
    </div>
    
  );
}

export default Sidebar;
