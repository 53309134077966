import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homeindex from "./pages/home/Homeindex";
import Movieslist from "./pages/movies/Movieslist";
import Moviedetails from "./pages/movies/moviesdetails/Moviedetails";
import Newslisting from "./pages/news/Newslisting";
import Newsdetails from "./pages/news/newsdetails/Newsdetails";
import Howitwork from "./pages/howitwork/Howitwork";
import Createlegue from "./pages/createleague/Createlegue";
import Dashboard from "./pages/dashboard/Dashboard";
import Myleaguedlive from "./pages/myleague/live/Myleaguedlive";
import Myleagueupcoming from "./pages/myleague/upcoming/Myleagueupcoming";
import Myleagueinvites from "./pages/myleague/invites/Myleagueinvites";
import Myleaguehistory from "./pages/myleague/history/Myleaguehistory";
import MyleagueliveDetailpage from "./pages/myleague/live/viewdetails/Viewdetails";
import Joinleague from "./pages/myleague/live/joinleague/Joinleague";
import Editprofile from "./pages/profile/editprofile/Editprofile";
import Watchlist from "./pages/profile/watchlist/Watchlist";
import Changepassword from "./pages/profile/changepassword/Changepassword";
import Contactuspage from "./pages/contactus/Contactuspage";
import Privacypolicy from "./pages/privacypolicy/Privacypolicy";
import Myleagues from "./pages//myleague/myleagues/Myleagues";
import CountDownTimer from "./components/countdowntimer/CountDownTimer";
import React from "react";
import "./App.css";
import Subscriptionpayment from "./pages/subscriptionpayment/Subscriptionpayment";
import Googleads from "./components/google-AdSense/Googleads";
import Aboutus from "./pages/aboutus/Aboutus";
import Updateleague from "./pages/updateleague/Updateleague";


function App() {

  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
       
          <Route path="/" element={<Homeindex />} />
          <Route path="Movieslist" element={<Movieslist />}  />
          <Route path="/Moviedetails/:id" element={<Moviedetails />} />
          <Route path="Newslisting" element={<Newslisting />} />
          <Route path="/Newsdetails/:id" element={<Newsdetails />} />
          <Route path="Howitworks" element={<Howitwork />} />
          <Route path="Createlegue" element={<Createlegue />} />
          <Route path="Dashboard" element={<Dashboard />} />
          <Route path="Myleaguedlive" element={<Myleaguedlive />} />
          <Route path="Myleagueupcoming" element={<Myleagueupcoming />} />
          <Route path="Myleagueinvites" element={<Myleagueinvites />} />
          <Route path="Myleaguehistory" element={<Myleaguehistory />} />
          <Route path="/Draft/:id" element={<Joinleague />} />  
          <Route path="Editprofile" element={<Editprofile />} />
          <Route path="Changepassword" element={<Changepassword />} />
          <Route path="Watchlist" element={<Watchlist />} />
          <Route path="Contactuspage" element={<Contactuspage />} />
          <Route path="Privacypolicy" element={<Privacypolicy />} />
          <Route path="Aboutus" element={<Aboutus />} />
          <Route path="Myleagues" element={<Myleagues />} />
          <Route path="CountDownTimer" element={<CountDownTimer/>}/>
          <Route path="/viewdetails/:id" element={<MyleagueliveDetailpage/>}/>
          <Route path="Subscriptionpayment" element={<Subscriptionpayment/>}/>
          <Route path="googleads" element={<Googleads/>}/>
          <Route path="Updateleague" element={<Updateleague />} />
    
        </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
