import React,{useState} from "react";
import swal from "sweetalert";
import { UseChangepasswordformvalidation} from "./UseChangepasswordformvalidation";
import "../editprofile/Editprofile";
import "../editprofile/Editprofile.css";
import Subheader from "../../../components/subheader/Subheader";
import { Footer } from "../../../components/footer/footer";
import ScrollButton from "../../../components/scrollbutton/ScrollButton";
import { ReactComponent as YourIcon } from "../../../assets/passwordicon(1).svg";
import Sidebar from "../../../components/sidebar/Sidebar";
import Submitbtn from "../../../components/submit/Submitbtn";
import { ReactComponent as YourIcon2 } from "../../../assets/eye-solid.svg";
import { useNavigate } from "react-router-dom";
import Navbarr from "../../../components/header/Navbarr";
import "./Changepassword.css"
import { changepasswordPost } from "../../../api/handleapi";
import Cookies from 'js-cookie';
import Loader from "../../../components/loader/Loader";
import { changepassAuthcheck, oldpasswordvalidationMsg, passwordchangeMsg, passwordchangeTitle, WrongTitle } from "../../../components/language/Language";
import { handle401Error } from "../../../components/handle401Error";
import { cookieNames } from "../../../Config/config";
import Googleadshorizontal from "../../../components/google-AdSense/Googleadshorizontal";

function Changepassword() {
  const navigate = useNavigate();
  const email=Cookies.get("email");
  const [loading,setLoading]=useState(false);
  const [form, setForm] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

const changecreatePost = async () => {
  setLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("email", email);
    bodyFormData.append("oldPassword", form.oldPassword);
    bodyFormData.append("newPassword", form.newPassword);
    bodyFormData.append("confirmPassword", form.confirmPassword);
    try {
      const response = await changepasswordPost(bodyFormData);
        if (response?.status === 200) {
          setLoading(false);
        await swal({
          title: passwordchangeTitle,
          text: passwordchangeMsg,
          icon: "success",
          closeOnClickOutside:false,
          buttons: "Ok",
          dangerMode: false,
        }).then(() => {
          handle401Error(cookieNames)
          navigate("/");
        });
      } 
    } catch (error) {
      if (error.response) {
        setLoading(false);
        if(error?.response?.data?.message===changepassAuthcheck){
          swal({
            title: WrongTitle,
            text: oldpasswordvalidationMsg,
            icon: "error",
            buttons: "Ok",
            closeOnClickOutside:false,
            dangerMode: true,
          })
            setForm({
              email:"",
              oldPassword:"",
              newPassword: "",
              confirmPassword: "",
            });
        }
              // The request was made and the server responded with a status code
            } 
     
    }
  };


const { errors, validateForm, onBlurField } =
UseChangepasswordformvalidation(form);


const onUpdateField = (e) => {
  const field = e.target.name;
  const nextFormState = { ...form, [field]: e.target.value };
  setForm(nextFormState);
  if (errors[field].dirty)
    validateForm({
      form: nextFormState,
      errors,
      field,
    });
};


const onSubmitForm = (e) => {
  e.preventDefault();
  const { isValid } = validateForm({ form, errors, forceTouchErrors: true });
  if (!isValid) return;
  changecreatePost();
};

  const [showoldPassword, setShowoldPassword] = useState(false);
  const [shownewPassword, setShownewPassword] = useState(false);
  const [showconfrimPassword, setShowconfirmPassword] =useState(false);

  const togglePasswordVisibility = () => {
    setShowoldPassword(!showoldPassword);
  };
  const togglenewPasswordVisibility = () => {
    setShownewPassword(!shownewPassword);
  };
  const togglecPasswordVisibility = () => {
    setShowconfirmPassword(!showconfrimPassword);
  };

  return (
    <div>
      <Navbarr/>
      <div className="after-nav containers con_div">
      <Googleadshorizontal props="pt-2 pb-2 text-center"/>
        <Subheader />
        <br />
        <div className="row g-0">
          <Sidebar />
          <div className="col-md-8 col-lg-9 col-xl-10">
            <div className="card   edit_primain">
              <nav className="navbar  nav-editprofile ">
                <span className="navbar-brand mb-0 h1 edit_text">
                  Change Password
                </span>
              </nav>
              <div className="edit_profile_seconddiv ">
                <div className="edit_pro_inpfields ">
                  <div className="row g-0">
                    <div className="col-lg-6 col-xl-4 input-container">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label edit_pro_label"
                      >
                        Old Password
                      </label>
                      <input
                        type={showoldPassword ? "text" : "password"}
                        name="oldPassword"
                        className="form-control inputf-controlupload edit_pro_input errors.oldPassword.dirty 
                        errors.oldPassword.error 
                       formFieldError"
                        placeholder="Old password"
                        aria-label="username"
                        value={form.oldPassword}
                        onChange={onUpdateField}
                         onBlur={onBlurField}
                      />
                      <div onClick={togglePasswordVisibility}>
          {showoldPassword ? (
            <YourIcon className="icon_passwordd"/>
          ) : (
            <YourIcon2 className="icon_password" />
          )}
        </div>
      
         {errors.oldPassword.dirty && errors.oldPassword.error ? (
          <p className="c_leagueFieldErrorMessage">
            {errors.oldPassword.message}
          </p>
        ) : null}
                      <br />
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-lg-6 col-xl-4 input-container">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label edit_pro_label"
                      >
                        New Password
                      </label>
                      <input
                       type={shownewPassword ? "text" : "password"}
                       name="newPassword"
                        className="form-control inputf-controlupload edit_pro_input errors.newPassword.dirty 
                        errors.newPassword.error 
                       formFieldError"
                        placeholder="New password"
                        aria-label="email id"
                      value={form.newPassword}
                         onChange={onUpdateField}
                        onBlur={onBlurField}
                      />
                      <div onClick={togglenewPasswordVisibility}>
          {shownewPassword ? (
           <YourIcon className="icon_passwordd"/>
          ) : (
             <YourIcon2 className="icon_password" />
          )}
        </div>
        {errors.newPassword.dirty && errors.newPassword.error ? (
          <p className="c_leagueFieldErrorMessage">
            {errors.newPassword.message}
          </p>
        ) : null}
                      <br />
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-4 input-container">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Confirm Password
                    </label>
                    <input
                      type={showconfrimPassword ? "text" : "password"}
                      name="confirmPassword"
                      className="form-control  inputf-controlupload  edit_pro_input errors.confirmPassword.dirty 
                      errors.confirmPassword.error 
                     formFieldError"
                      placeholder="Confirm password "
                      aria-label="City"
                      value={form.confirmPassword}
                      onChange={onUpdateField}
                      onBlur={onBlurField}
                    />
                    <div onClick={togglecPasswordVisibility}>
          {showconfrimPassword ? (
          <YourIcon className="icon_passwordd"/>
          ) : (
            <YourIcon2 className="icon_password" />
          )}
        </div>  
        {errors.confirmPassword.dirty && errors.confirmPassword.error ? (
          <p className="c_leagueFieldErrorMessage">
            {errors.confirmPassword.message}
          </p>
        ) : null}
            <br />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-4 changepass_btn pb-4">
                <Submitbtn onSubmitForm={onSubmitForm} props={"SUBMIT"}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollButton />
      {loading?<Loader/>:null}
    </div>
  );
}

export default Changepassword;
