import React,{useEffect,useState} from "react";
import ScrollButton from "../../../components/scrollbutton/ScrollButton";
import Subheader from "../../../components/subheader/Subheader";
import { Footer } from "../../../components/footer/footer";
import { ReactComponent as YourIcon } from "../../../assets/mdi_calendar.svg";
import { ReactComponent as YourIcon1 } from "../../../assets/time.svg";
import firstitem from "../../../assets/dummy-movie.png";
import "./Myleaguedlive.css";
import { Link } from "react-router-dom";
import Pagination from "../../../components/pagination/Pagination";

import Navbarr from "../../../components/header/Navbarr";
import {cookieNames, imgpath} from "../../../Config/config";
import {  useDispatch } from 'react-redux';
import { setuplState } from '../../../feature/showSlice/uplstateSlice';
import {liveleaguesGet} from "../../../api/handleapi";
import { useNavigate } from "react-router-dom";
import { Errormsg } from "../../../components/tostifymsg/Errrormsg";
import Cookies from 'js-cookie';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { errorstatuscheck, reloginMsg } from "../../../components/language/Language";
import { handle401Error } from "../../../components/handle401Error";
import Googleadshorizontal from "../../../components/google-AdSense/Googleadshorizontal";

function Myleaguedlive() {
  const id=Cookies.get("id");
 
 const [postlive, setPostlive] =useState([]);
  const [pageN, setPageN] = useState(1);
  const itemsPerPage = 4; // Replace with your actual items per page
  const totalPages = Math.ceil(postlive?.length / itemsPerPage);
  const reversedData = postlive?.slice().reverse(); // Create a copy of the array and reverse it

  const handlePageClick = ({ selected }) => {
    setPageN(selected+1);
  };
  const currentPageData = reversedData?.slice((pageN - 1) * itemsPerPage, pageN * itemsPerPage);
    
  const navigate=useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
  
    const liveFun = async (id) => {
      try {
        const response = await liveleaguesGet(id); // You might want to pass some parameters to get
        setPostlive(response?.data?.data);
      } catch (error) {
        if(error?.message==errorstatuscheck){            
          await Errormsg(reloginMsg);
          await handle401Error(cookieNames)
          await navigate("/");
        }
        
      }
    };
    liveFun(id);
  }, [id]);


  const onSubmitForm = (LeagueId,entries,leaguename,visibility,templateTypeid,iimage,cdate,sdate) => {
    navigate(`/Draft/${LeagueId}`);
       
      if(LeagueId){
        dispatch(
          setuplState({
            LeagueId:LeagueId,
            LeagueName: leaguename,
            Entries: entries,
            Visibility:visibility,
            templateTypeId:templateTypeid,
            poster:iimage,
            createdDate:cdate,
            startDateTime:sdate,
          })
        );
      }
     };

  return (
    <div>
         <Navbarr/>
      <div className="after-nav-dsubheader containers con_div">
      <Googleadshorizontal props="pt-2 pb-2 text-center"/>
        <Subheader />
        <div className="Dasboard-cards">
          <div className="afterdashboard_nav"> 
          {currentPageData?.length > 0 ? 
       currentPageData?.map((data, index) => (
        <div key={index}>
          <div className="card d-Cardf">
            <div className="after_row_div">
              <div className="row">
                <div className="col-xl-1 col-lg-3 col-md-12 col-sm-12 imag-cardclass">
                <img src={imgpath + data?.image} alt="img" className="dleague-card-img"  
                 onError={(e) => {
                 e.target.onerror = null;
                 e.target.src = firstitem }}
                 />
                </div>
                <div className="col-xl-11 col-lg-10 col-md-12 col-sm-12 league-card-content">
                  <div className="live-section">
                    <p className="card-title">{data?.leagueName}</p>
                    <span className="btn btn-primary PP-btn ">
                    {data?.visibility}
                    </span>
                    
                    <div>
                    <button className="live-btn">LIVE</button>
                  </div>
                  </div>
                 
                  <div>
                    <p className="card-title">
              
                      {data?.templateName}
                      </p>
                    <p className="card-title"> No of Players:  {data?.totalPlayers}/{data?.entries}</p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="dleague-card-hr" />
            <nav className="dleague-card-nav">
           
              <div className="dleague-card-nav-span">
                <div className="datetimeleague">
                <div className="time_details">
           
                
                  <div className="time_with_date">
                  <span>Created: </span>
                  <div className="cal-time">
                  {" "}
                  <YourIcon />
             
                 
                  <span className="data m-1">
                 
                    { moment(new Date(data?.createdDate + 'Z')).format("dddd, MMMM D, YYYY")}
                     </span>
             
                </div>
                <div className="v">
                  <YourIcon1 />
                  <span className="time m-1">
                 
                    { moment(new Date(data?.createdDate + 'Z')).format("h:mm a")}
                    </span>
                </div>
                  </div>
                </div>

                <div className="time_details">
                  <div className="time_with_date">
                  <span>League End: </span>
                  <div className="cal-time">
                  {" "}
                  <YourIcon />
             
                 
                  <span className="data m-1">
                 
                    { moment(new Date(data?.endDateTime + 'Z')).format("dddd, MMMM D, YYYY")}
                     </span>
             
                </div>
                <div className="v">
                  <YourIcon1 />
                  <span className="time m-1">
                 
                    { moment(new Date(data?.createdDate + 'Z')).format("h:mm a")}
                    </span>
                </div>
                  </div>
                </div>
                </div>
             
                <div className="lives_buttons">
                <div>
                <button  className="btn btn-primary JL-btn"  onClick={()=>onSubmitForm(data?.id,data?.entries,data?.leagueName,data?.visibility,data?.templateTypeId,data?.image,data?.createdDate,data?.startDateTime)}>
                                    Draft 
                  </button>
                </div>
                <div>
                  <Link  to={`/viewdetails/${data?.id}`}className="btn btn-primary JL-btn" >
                    View Details
                  </Link>
                </div>
                </div>
              </div>
                 
            </nav>
            
          </div>
          </div>
         )) :<div >
           <div className="no_league_found" >No data found</div>
                  </div>
          }
          </div>
          {postlive?.length==0?"":<div>
            <Pagination pageCount={totalPages}  handlePageClick={handlePageClick} />
          </div>}
        </div>
      </div>
      <Footer />
      <ScrollButton />
    </div>
  );
}

export default Myleaguedlive;
