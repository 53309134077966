import React,{useState,useEffect}from 'react'
import swal from 'sweetalert';
import Tradebtn from "./Tradebtn";
import { useSelector,useDispatch } from 'react-redux';
import {playermoviesGet,moviedetailsGet, categoryByidget, swaprosterMovies} from "../../../../../api/handleapi";
import { setLoginpmovie} from "../../../../../feature/showSlice/loginpmovieSlice";
import { openModalmdetails } from '../../../../../feature/showSlice/showmodaltrademoviedetailsSlice';
import Cookies from 'js-cookie';
import { useCategory } from '../../../../../components/useCategory';
import { successfullyTitle, swapMsg,  swapsrosterAuthcheck, swapvalidationMsg } from '../../../../../components/language/Language';
import { categoryIdValues } from '../../../../../Config/config';


function Swapmovies({setShowModalswap,Leagueid,setTrademdetailsforid}) {
  const categoryoption=useCategory();
    const dispatch=useDispatch();
    const firstpid=Cookies.get("id");
    const [categoryname,setcategoryName]=useState([]);
    const [movieslist,setMoviesList] = useState([]);
    const [moviescategoryd,setMoviescategoryd] = useState([]);
    const [moviescategorydother,setMoviescategorydsother] = useState([]);
    const [benchmovies,setBenchmovies] = useState([]);
    const [othermovie,setOthermovie] = useState([]);
    const loginpmovie=useSelector((state) => state.loginpmovie);
    const Categoryoptions=useCategory();
    const [btrade,setBtrade]=useState({
        BenchMovieId:"",
        BenchMovieCategoryId:"",
      })
    
      const [othertrade,setOthertrade]=useState({
         MovieId:"",
        MovieCategoryId:"",
             })


             const categoryByidGetdetails = async (categoryid) => {
              try {
                const response = await categoryByidget(categoryid); // You might want to pass some parameters to get
              } catch (error) {
                return;
              }
            };

useEffect(() => {
  categoryByidGetdetails()
}, [])


             const moviedetailsFun = async (movieId) => {
              try {
                const response = await moviedetailsGet(movieId); // You might want to pass some parameters to get
                const categoryArraybasedonleague = response?.data?.data?.category?.split(",").map(value => parseInt(value));
                const filteredCategoryOptions = categoryoption?.filter(option => {
                  return categoryArraybasedonleague?.includes(option?.id);
                });
                setMoviescategoryd(categoryArraybasedonleague);
                setcategoryName(filteredCategoryOptions)            
              } catch (error) {
                return;
              }
            };


            const openModalMdetails = (movieId) => {
              dispatch(openModalmdetails(true)); 
              dispatch(setTrademdetailsforid(movieId));
          };
          
       
    useEffect(() => {
        const updatedMoviesArray = loginpmovie?.map((movie) => ({
          ...movie,
          checked: false,
      }));
      const filteredotherMovies = updatedMoviesArray?.filter(movie => {
        return (
          (categoryIdValues?.includes(movie?.categoryId) || (movie?.categoryId !== categoryIdValues[0] && movie?.finalScore === 0)) &&
          movie?.movieId != null
        );
      });
      setBenchmovies(filteredotherMovies);
      setOthermovie(filteredotherMovies)
      setMoviesList(updatedMoviesArray);
      },[]);

     const categorynamecheck=categoryname?.map(option => option?.category)

    const handleIconClick = (movieid) => {
      moviedetailsFun(movieid);
        let temp_movielist = [...benchmovies]
        let updatedData = temp_movielist?.map((each)=>{
          if(each?.movieId==movieid){
            setBtrade({ BenchMovieId:each?.movieId,BenchMovieCategoryId:each?.categoryId})
            return {...each,checked:!each.checked}
          }
          return {...each,checked:false}
      })
        setBenchmovies(updatedData);
      };
    

      const handleIconClickk = (movieId,cat_id) => {
        setMoviescategorydsother([cat_id]);
        let temp_movielist = [...othermovie]
        let updatedData = temp_movielist?.map((each)=>{
          if(each?.movieId==movieId){
            setOthertrade ({
              MovieId:each?.movieId,
              MovieCategoryId:each?.categoryId,
              });
            return {...each,checked:!each.checked}
          }
          return {...each,checked:false}
      })
        setOthermovie(updatedData);
      };
    
      const movieslistFun = async (Leagueid,firstpid) => {
            try {
              const response = await playermoviesGet(Leagueid,firstpid); 
             
              dispatch(setLoginpmovie(response?.data?.data?.logedInPlayerMovies));
            } catch (error) {
         return;
            }
          }

    const swapMovie = async() => {
        var bodyFormData = new FormData();
        bodyFormData.append("LeagueId",Leagueid );
        bodyFormData.append("PlayerId",firstpid);
            bodyFormData.append("FMovieId",btrade?.BenchMovieId );
            bodyFormData.append("FMovieCategoryId",btrade?.BenchMovieCategoryId );
              bodyFormData.append("SMovieId",othertrade?.MovieId );
              bodyFormData.append("SMovieCategoryId",othertrade?.MovieCategoryId);
        
         try {
          const response =  await swaprosterMovies(bodyFormData);
       
          if (response?.status === 200) {
            movieslistFun(Leagueid,firstpid)
            swal({
              title: successfullyTitle,
              text: swapMsg,
              icon: "success",
              buttons: "ok",
              closeOnClickOutside:false,
              dangerMode: false
            }).then((value) => {
              if(value){
                setShowModalswap(false);
              }})
          } 
        } 
        catch (error) {
                    if (error.response.data?.message==swapsrosterAuthcheck) {
            swal({
              title: "",
              text: `Select movies that are categorized as ${categorynamecheck} to swap`,
              icon: "error",
              buttons: "ok",
              closeOnClickOutside:false,
              dangerMode: false
            })
          }         
                  else if (error.response.data?.message===undefined) {
                    swal({
                        title: "",
                        text: swapvalidationMsg,
                        icon: "error",
                        buttons: "ok",
                        closeOnClickOutside:false,
                        dangerMode: false
                      })
                  }else {
                    return;
                  }
        }
      }


      
  return (
<div>
  <div className="row">
    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div className="heading_live_listt"><h6>
        My Roster Movies
        </h6></div>
   
            <div className="position_card">
  <div className="table_containerr_trade scrollbarcstm"> 
    <table className="playermovie_list">
  <thead>
    <tr className="trade_movieslist_headings">
      <td>Movie</td>
      <td>Category</td>
      <td>Score</td>
      <td>Actions</td>
    </tr>
  </thead>
  <tbody>
  {benchmovies?.length!=0?
  benchmovies?.map((data,index)=>(

    <tr className="Player_title" key={index} >
          <td>
             <div className='d-flex gap-2'>
            
             <span  className='moviesnamefordetails'  onClick={()=>openModalMdetails(data?.movieId)}>{data?.movieName?.length > 15 ? data?.movieName.substring(0, 15) + '...' : data?.movieName}</span>
            
             </div>
             </td>   
      <td>{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>
      <td>{data?.finalScore}</td>
      <td className="campareicons">
       
          <input type="checkbox" checked={data?.checked} onClick={() => handleIconClick(data?.movieId)}/></td>
    </tr>
    )):<div className='no_dropm_found'>No movies found</div>
    } 
       
  </tbody>
</table>
            </div>
          </div>

    </div>
    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div className="heading_live_listt">
        <h6>My Roster Movies</h6>
      </div>
       
            <div className="position_card">
  <div className="table_containerr_trade scrollbarcstm"> 
    <table className="playermovie_list">
  <thead>
    <tr className="trade_movieslist_headings">
      <td>Movie</td>
      <td>Category</td>
      <td>Score</td>
      <td>Actions</td>
    </tr>
  </thead>
  <tbody>
  {othermovie?.length!=0?
  othermovie?.map((data,index)=>(
    <tr className="Player_title" key={index} >
          <td>
             <div className='d-flex gap-2'>
          
          
            <span  className='moviesnamefordetails'onClick={()=>openModalMdetails(data?.movieId)}>{data?.movieName?.length > 15 ? data?.movieName.substring(0, 15) + '...' : data?.movieName}</span>
             </div>
             </td>   
      <td>{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>
      <td>{data?.finalScore}</td>
      <td className="campareicons">
     
      <input type="checkbox"  checked={data?.checked}  onChange={()=>handleIconClickk(data?.movieId,data?.categoryId,)} />
      </td>
    </tr>
    )):<div className='no_dropm_found'>No movies found</div>
    } 
       
  </tbody>
</table>
            </div>
          </div>
    </div>
  </div>
  <div className='Trade_modal_btn' onClick={swapMovie} >
    <Tradebtn Trade={"Swap"}/>
  </div>
</div>
  )
}

export default Swapmovies;