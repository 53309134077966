import { useState } from "react";
import {
  lnameValidation,
  entriesValidation,
  visibilityValidation,
  startdatetimeValidation,
  enddatetimeValidation,
  selectcategoryidsValidation,
  templatetypeidValidation,
  imagesfileValidation,
  descriptionValidation,
} from "./Validationcleague";
import { useSelector } from "react-redux";
const touchErrors = (errors) => {
  return Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {});
};
export const UseCrealeleagueformvalidation = (cleague) => {
  const updateleagueinfo = useSelector((state) => state.updateleagueinfo);
  const [errors, setErrors] = useState({
    LeagueName: {
      dirty: false,
      error: false,
      message: "",
    },
    Entries: {
      dirty: false,
      error: false,
      message: "",
    },

    Visibility: {
      dirty: false,
      error: false,
      message: "",
    },
    StartDateTime: {
      dirty: false,
      error: false,
      message: "",
    },
    EndDateTime: {
      dirty: false,
      error: false,
      message: "",
    },
    SelectedCategoryIds: {
      dirty: false,
      error: false,
      message: "",
    },
    Imagesfile: {
      dirty: false,
      error: false,
      message: "",
    },
    TemplateTypeId: {
      dirty: false,
      error: false,
      message: "",
    },
    Description: {
      dirty: false,
      error: false,
      message: "",
    },
  });

  const validateForm = ({
    cleague,
    field,
    errors,
    forceTouchErrors = false,
  }) => {
    let isValid = true;

    // Create a deep copy of the errors
    let nextErrors = JSON.parse(JSON.stringify(errors));

    // Force validate all the fields
    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const {
      LeagueName,
      Entries,
      Visibility,
      StartDateTime,
      EndDateTime,
      SelectedCategoryIds,
      Imagesfile,
      TemplateTypeId,
      Description,
    } = cleague;

    if (
      nextErrors.LeagueName.dirty &&
      (field ? field === "LeagueName" : true)
    ) {
      const lnameMessage = lnameValidation(LeagueName, cleague);
      nextErrors.LeagueName.error = !!lnameMessage;
      nextErrors.LeagueName.message = lnameMessage;
      if (!!lnameMessage) isValid = false;
    }
    if (nextErrors.Entries.dirty && (field ? field === "Entries" : true)) {
      const entriesMessage = entriesValidation(Entries, cleague);
      nextErrors.Entries.error = !!entriesMessage;
      nextErrors.Entries.message = entriesMessage;
      if (!!entriesMessage) isValid = false;
    }

    if (
      nextErrors.Visibility.dirty &&
      (field ? field === "Visibility" : true)
    ) {
      const visibilityMessage = visibilityValidation(Visibility, cleague);
      nextErrors.Visibility.error = !!visibilityMessage;
      nextErrors.Visibility.message = visibilityMessage;
      if (!!visibilityMessage) isValid = false;
    }

    if (
      nextErrors.Imagesfile.dirty &&
      (field ? field === "Imagesfile" : true) && (!updateleagueinfo?.leagueimage)
    ) {
      const imagesMessage = imagesfileValidation(Imagesfile, cleague);
      nextErrors.Imagesfile.error = !!imagesMessage;
      nextErrors.Imagesfile.message = imagesMessage;
      if (!!imagesMessage) isValid = false;
    }
    if (
      nextErrors.TemplateTypeId.dirty &&
      (field ? field === "TemplateTypeId" : true)
    ) {
      const templatetypeidMessage = templatetypeidValidation(
        TemplateTypeId,
        cleague
      );
      nextErrors.TemplateTypeId.error = !!templatetypeidMessage;
      nextErrors.TemplateTypeId.message = templatetypeidMessage;
      if (!!templatetypeidMessage) isValid = false;
    }
    if (
      nextErrors.StartDateTime.dirty &&
      (field ? field === "StartDateTime" : true)
    ) {
      const startdatetimeMessage = startdatetimeValidation(
        StartDateTime,
        cleague
      );
      nextErrors.StartDateTime.error = !!startdatetimeMessage;
      nextErrors.StartDateTime.message = startdatetimeMessage;
      if (!!startdatetimeMessage) isValid = false;
    }
    if (
      nextErrors.EndDateTime.dirty &&
      (field ? field === "EndDateTime" : true)
    ) {
      const enddatetimeMessage = enddatetimeValidation(EndDateTime, cleague);
      nextErrors.EndDateTime.error = !!enddatetimeMessage;
      nextErrors.EndDateTime.message = enddatetimeMessage;
      if (!!enddatetimeMessage) isValid = false;
    }
    if (
      nextErrors.SelectedCategoryIds.dirty &&
      (field ? field === "SelectedCategoryIds" : true)
    ) {
      const selectcategoryidsMessage = selectcategoryidsValidation(
        SelectedCategoryIds,
        cleague
      );
      nextErrors.SelectedCategoryIds.error = !!selectcategoryidsMessage;
      nextErrors.SelectedCategoryIds.message = selectcategoryidsMessage;
      if (!!selectcategoryidsMessage) isValid = false;
    }
    if (
      nextErrors.Description.dirty &&
      (field ? field === "Description" : true)
    ) {
      const descriptionMessage = descriptionValidation(Description, cleague);
      nextErrors.Description.error = !!descriptionMessage;
      nextErrors.Description.message = descriptionMessage;
      if (!!descriptionMessage) isValid = false;
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = (e) => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };

    validateForm({ cleague, field, errors: updatedErrors });
  };

  return {
    validateForm,
    onBlurField,
    errors,
  };
};
