import React, { useState,useEffect } from "react";
import Navbarr from "../../components/header/Navbarr";
import { Footer } from "../../components/footer/footer";
import ScrollButton from "../../components/scrollbutton/ScrollButton";
import img7 from "../../assets/dummy-news.png";
import "./Newslisting.css";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import Latestnewsbanner from "../../components/latestnewsbanner/Latestnewsbanner";
import { Link } from "react-router-dom";
import LoadMore from "../../components/loadmore/LoadMore";
import {movieposterpath} from "../../Config/config"
import {newsGet} from "../../api/handleapi"
import Submitbtn from "../../components/submit/Submitbtn";
import Googleadshorizontal from "../../components/google-AdSense/Googleadshorizontal";



function Journallisting() {

const [showload, setShowload] = useState(true);
const [visibleItems, setVisibleItems] = useState(6);
const [newdata,setNewdata]=useState();

const [loading, setLoading] = useState(true);


  useEffect(() => {
    const NewsFun = async () => {
      try {
        const response = await newsGet(); 
        setNewdata(response?.data?.data);
        setLoading(false);
      } catch (error) {
        return;
      }
    };
    NewsFun();
      }, []);


  const handleLoadMore = () => {
    const nextVisibleItems = Math.min(visibleItems + 3, newdata?.length);
    setVisibleItems(nextVisibleItems);
    if (nextVisibleItems == newdata?.length) {
      setShowload(false)}
  };

  return (
    <div>
      <div className="news_div">
      <Navbarr />
        <Latestnewsbanner text={"Latest News"}/>
        <div className="con_div mb-30">
          <div className="row g-0 listingnews">        
            <div className="News_listing_div col-lg-9 col-md-6 col-sm-12 ">
            <Googleadshorizontal props="mt-2 mb-2 text-center"/>
              <div className="row g-0 bblogsc">
              {loading ? (
                    <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary " role="status">
              <span className="visually-hidden">Loading...</span>
          </div>
          </div>
          ):
                (newdata?.length > 0 ? (
                newdata?.slice(0, visibleItems).map((item, index) => (
                <div className="blogC col-xl-4 card col-lg-6 col-md-12 col-sm-12" key={index}>
                <Link className="newlisting_img movieheading_ele" to={`/Newsdetails/${item?.id}`}>  
                <img className="card-img-top" src={movieposterpath+item?.image} alt="img" onError={(e) => {
            e.target.onerror = null;
            e.target.src = img7 }} />
                </Link>
                  <div className="btnaora">
                    <span className="btn  adbtn">
                      {item?.type}
                    </span>
                  </div>
                  <div className="new-listin-pra">
                  <Link  className="movieheading_ele"  to={`/Newsdetails/${item?.id}`}>
                    <h5 className="news_title text-white"> 
                      
                      
                    {item?.name.length > 60 ? item?.name.substring(0, 60) + '...' : item?.name} 
                    </h5>
                      </Link>               
                   
                   
                                   
                                   <div className="card-text para_news news-des-listing newsdesdata" dangerouslySetInnerHTML={{
  __html: item?.description.replace(/<br>/g, ' ')
}} />
                    <div className="read-more-btn ">
                    <Link  className="movieheading_ele"  to={`/Newsdetails/${item?.id}`}>
                    <Submitbtn props={"Read More"}/>
                    </Link>
                    </div>
                    
                  </div>
                  <div className="shadow">
                  </div>
                </div>
                ))):(
                  <div className="text-white  text-center"> <h5>No News found</h5></div>
                ))}
              </div>
             {newdata?.length> 6 ?
              <div>
              {showload?<LoadMore onLoadMore={handleLoadMore}/> :null}  
              </div>:null}
              <Googleadshorizontal props="mt-2 mb-2 text-center"/>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12" >
              <div className="twiiter_feed_div sidebar header" id="header" > 
                <TwitterTimelineEmbed
                  sourceType="profile"
                  screenName="Gaffer_llc"
                  theme="dark"
                  options={{
                    height: "1150px",
                  }}
                  noBorders="true"                 
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ScrollButton />
    </div>
  );
}

export default Journallisting;
