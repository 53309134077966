import React,{useState,useEffect} from "react";
import ScrollButton from "../../../components/scrollbutton/ScrollButton";
import Subheader from "../../../components/subheader/Subheader";
import { Footer } from "../../../components/footer/footer";
import { ReactComponent as YourIcon } from "../../../assets/mdi_calendar.svg";
import { ReactComponent as YourIcon1 } from "../../../assets/time.svg";
import Navbarr from "../../../components/header/Navbarr";
import {userleagueshistoryGet} from "../../../api/handleapi";
import { Errormsg } from "../../../components/tostifymsg/Errrormsg";
import "./Myleaguehistory.css";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/pagination/Pagination";
import Cookies from 'js-cookie';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { errorstatuscheck, reloginMsg } from "../../../components/language/Language";
import { cookieNames } from "../../../Config/config";
import { handle401Error } from "../../../components/handle401Error";
import Googleadshorizontal from "../../../components/google-AdSense/Googleadshorizontal";


function Myleaguehistory() {
  const id=Cookies.get("id")
 const [historyget, setHistoryget] = useState([]);

 const Navigate=useNavigate();

 
 const [pageN, setPageN] = useState(1);
 const itemsPerPage = 8; // Replace with your actual items per page


 const totalPages = Math.ceil(historyget?.length / itemsPerPage);
 const reversedData = historyget?.slice().reverse(); // Create a copy of the array and reverse it
 const currentPageData = reversedData?.slice((pageN- 1) * itemsPerPage, pageN * itemsPerPage);


 const historyFun = async (id) => {
  try {
    const response = await userleagueshistoryGet(id);
     // You might want to pass some parameters to leagues history get
    setHistoryget(response?.data?.data);

  } catch (error) {
    if(error?.message==errorstatuscheck){         
      await Errormsg(reloginMsg);
      await handle401Error(cookieNames)
      await Navigate("/");
    }
  }
};
  useEffect(() => {
  historyFun(id);
    }, [id]);


    const handlePageClick = ({ selected }) => {
      setPageN(selected+1);
    };

  return (
    <div>
      <Navbarr/>
      <div className="after-nav-dsubheader-history containers con_div">

      <Googleadshorizontal props="pt-2 pb-2 text-center"/>
        <Subheader />
        <br />
        <div className="Dasboard-cards history-card">
          <div className="history-card">
            <table className="table_history">
  <thead>
    <tr className="table_headings">
      <th scope="col">Rank</th>
      <th scope="col">League Name</th>
      <th scope="col">Status</th>
      <th scope="col">League Winner</th>
      <th scope="col">Score</th>
      <th scope="col">Start Date</th>
      <th scope="col">End Date</th>
      <th scope="col">No of players</th>
    </tr>
  </thead>
  <tbody>


     {currentPageData?.length > 0 ? 
         currentPageData?.map((data, index) => (
    <tr className="table_body_tr" key={index}>
      <th scope="row">{data?.loggedInUserPosition}</th>
      <td >{data?.leagueName}</td>
    
     {data?.loggedInUserPosition==1? <td><span className="win">Win</span> </td>:null}
          {data?.loggedInUserPosition!=1 && data?.loggedInUserPosition!=0?<td><span className="loss">Loss</span></td>:null}
         {data?.loggedInUserPosition==0? <td><span className="not_status">N/A</span></td>:null}
      <td>
        {data?.winnerName !== null && data?.winnerName !== "" ? data?.winnerName : "N/A"}

      </td>
      <td>
        {data?.players?.[0]?.totalScore}</td>

      <td>  
        <div className="time_withdate">
        <div className="data1 date_history">
                {" "}
                <YourIcon />  
                { moment(new Date(data?.leagueStartDate + 'Z')).format("dddd, MMMM D, YYYY")}
                   
              </div>
              <div className="time history_time ">
                <YourIcon1 />  
                { moment(new Date(data?.leagueStartDate + 'Z')).format("h:mm a")}
                
              </div>
              </div> 
              </td>
              <td>  
        <div className="time_withdate">
        <div className="data1 date_history">
                {" "}
                <YourIcon /> 
                {moment(new Date(data?.leagueEndDate + 'Z')).format("dddd, MMMM D, YYYY")}
              
              </div>
              <div className="time history_time">
                <YourIcon1 />  
                {moment(new Date(data?.leagueEndDate + 'Z')).format("h:mm a")}
              
              </div>
              </div> 
              </td>
              <td>{data?.numberOfPlayers}</td>

    </tr>)):<div className="no_league_found">No data found</div>
    }
  </tbody>
</table>
          </div>
        </div>
        
      </div>
      <div className="paginatin_div">

      
      {historyget?.length==0?"":<div className="d-flex justify-content-center ">
            <Pagination pageCount={totalPages} handlePageClick={handlePageClick} />
          </div>}
          </div>
      <Footer />

      <ScrollButton />
    </div>
  );
}

export default Myleaguehistory;
