import axios from "axios";
import Cookies from 'js-cookie';
import { Errormsg } from "../components/tostifymsg/Errrormsg";
import { APIerrorTitle } from "../components/language/Language";
import { handle401Error } from "../components/handle401Error";
import { cookieNames } from "../Config/config";



function setTokenHeader() {
  const token = Cookies.get("token"); // get the token
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
  }
}
export async function trendingmovieGet() {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LandingPage/TrendingMovies`
    );
    return response?.data;
  }
  catch (err) {
         Errormsg(APIerrorTitle)
      
  }
}

export async function newreleasemovieGet() {

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LandingPage/NewReleasedMovies`
    );
    return response?.data;
  }
  catch (err) {  
         Errormsg(APIerrorTitle)
      
  }
}

export async function topnewsGet() {

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LandingPage/TopArticlesAndNews`   
    );
    return response?.data;
  }
  catch (err) {
         Errormsg(APIerrorTitle)
      
  }
}

export async function dashboardleaguesGet(id) {
  setTokenHeader();
      const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/AllLeagues?userId=${id}`
          
    );
      return response;
   
}

export async function liveleaguesGet(id) {
  setTokenHeader();
      const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/LiveLeagues?playerId=${id}`
        
    
    );
      return response;
}
export async function upcomingleagueGet(id) {

  setTokenHeader();
       const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/UpcomingLeagues?playerId=${id}`
     
      );
       return response.data;
}

export async function editpGet(id) {
  setTokenHeader();
       const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/PlayerUsers/PlayerUserDetailsById?Id=${id}`
      );
       return response.data;
 
}

export async function newsGet() {

  try {
       const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LatestNewsApi/LatestArticlesAndNews`
        );
       return response;
  }
  catch (err) {
         Errormsg(APIerrorTitle)
      
  }
}
export async function newsdetailsGet(newsId) {
  try {
    
       const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LatestNewsApi/GetLatestNewsById?articleId=${newsId}`

        );
       return response;
  }
  catch (err) {
        
         Errormsg(APIerrorTitle)
      
  }
}

export async function inviteleagueGet(id) {
  setTokenHeader();
       const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/InvitesByPlayer?userId=${id}`
        );
       return response;
}

export async function livedetailsplayerscGet(id) {
  try {
    setTokenHeader();
       const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/PlayersScoreInLeague?leagueId=${id}`
   
        );
      
       return response;
  }
  catch (err) {
         Errormsg(APIerrorTitle)
      
  }
}

export async function joinLmoviepickget(id,playerId,msg) {
  try {
      setTokenHeader();
       const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/SignalR/MoviePick?leagueId=${id}&playerId=${playerId}`
      
    );
       return response;
  }
  catch (err) {       
         Errormsg(APIerrorTitle)
      
  }
}
export async function joinLmoviepickgett(id,playerId,msg) {
  try {
    setTokenHeader();
       const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/SignalR/MoviePick?leagueId=${id}&playerId=${playerId}&message=${msg}`
   
    );
       return response;
  }
  catch (err) {
         Errormsg(APIerrorTitle)
    
  }
}
export async function joinLmsgget(id) {
  try {
    setTokenHeader();
       const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/SignalR/GetMessagesByLeagueId?leagueId=${id}`
    );
      return response;
  }
  catch (err) {
   return;
  }
}


export async function joinLmovieppost(value) {
  setTokenHeader();
       const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/PickMovieByPlayer`,
        value
        ,{
        headers: {
          "Content-Type": "multipart/form-data",
        }}
      
      );
       return response;
}

export async function changepasswordPost(value) {
  setTokenHeader();
       const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/PlayerUsers/ChangePassword`,value
        
        ,{
        headers: {
          "Content-Type": "multipart/form-data",
        }}
      
      );
       return response;
}


export async function editprofilePost(id,value) {
  try {
    setTokenHeader();
       const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/PlayerUsers/${id}`,value
        
        ,{
        headers: {
          "Content-Type": "multipart/form-data",
        }}
      
      
      );
       return response;
  }
  catch (err) {
         Errormsg(APIerrorTitle)
  }
}

export async function joinleaguebtnPost(value) {
  try {
        setTokenHeader();
       const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/JoinLeague`,value
       
        ,{
        headers: {
          "Content-Type": "multipart/form-data",
        }}
      
      );
       return response;
  }
  catch (err) {
       Errormsg(APIerrorTitle)
      
  }
}

export async function WatchlistmoviesGet(id) {
  try {
    setTokenHeader();
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/MoviesApi/GetWatchListMovies?userId=${id}`
            
  );
    return response;
  }
  catch (err) {
         Errormsg(APIerrorTitle)
     
  }
}
export async function WatchlistmoviesdraftGet(id,leagueId) {
  try {
    setTokenHeader();
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/MoviesApi/GetWatchListMoviesForDraft?userId=${id}&leagueId=${leagueId}`
            
  );
    return response;
  }
  catch (err) {
       
         Errormsg(APIerrorTitle)
    
  }
}

export async function watchlistmovieremovePost(value) {
  setTokenHeader();
       const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/MoviesApi/RemoveMovieFromWatchList`,value
        ,{
        headers: {
          "Content-Type": "multipart/form-data",
        }}
      
      
      );
       return response;
}
export async function createleaguePost(value) {
  setTokenHeader();
       const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/CreateLeague`,value
        ,{
        headers: {
          "Content-Type": "multipart/form-data",
        }}
      
      
      );
       return response;
}

export async function invitesGet() {

  try {
    setTokenHeader();
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/PlayerListForInvite`
   
  );
    return response;
  }
  catch (err) {
         Errormsg(APIerrorTitle)
    
  }
}

export async function invitesendPost(value) {
  setTokenHeader();
       const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/InviteForJoinLeague`,value
        ,{
        headers: {
          "Content-Type": "multipart/form-data",
        }}
      
      );
       return response;
}

export async function movieaddWatchlistost(value) {
  setTokenHeader();
       const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/MoviesApi/AddMovieToWatchList`,value        
        ,{
        headers: {
          "Content-Type": "multipart/form-data",
        }}
      
      
      );
       return response;
  
}
export async function MoviecomparePost(value) {
  setTokenHeader();
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/CompareMovies`,value,{
   headers: {
     "Content-Type": "multipart/form-data",
   }}
  
  
  );
  return response;

}
export async function newscommentPost(value) {

  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LatestNewsApi/CommentByUser`,value,{
   headers: {
     "Content-Type": "multipart/form-data",
   }});
  return response;


}
export async function moviedetailsGet(movieId) {

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LandingPage/GetMovieById?Id=${movieId}`
    );
    return response;
  }
  catch (err) {
         Errormsg(APIerrorTitle)
      
  }
}
export async function loginPost(value) {
       const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/Auth/Login`,
        value,{
        headers: {
          "Content-Type": "multipart/form-data",
        }});
       return response;
}
export async function registerPost(value) {
       const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/Auth/Register`,
        value,{
        headers: {
          "Content-Type": "application/json",
        }});
       return response;
}

export async function movielistGet() {

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LandingPage/GetListOfMovies`
    
    );
    return response.data;
  }
  catch (err) {

         Errormsg(APIerrorTitle)
      
  }
}

export async function leaguedetailsGet(id) {
  setTokenHeader();
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/LeagueById?Id=${id}`
  );
    return response;

}

export async function newscommentGet(id) {

  try {
    setTokenHeader();
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LatestNewsApi/GetCommentsByNewsId?newsId=${id}`

  );
    return response;
   
  }
  catch (err) {
  
         Errormsg(APIerrorTitle)
     
  }
}

export async function myleaguesGet(id) {

  setTokenHeader();
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/MyLeagues?playerId=${id}`
  );
    return response;
   
}
export async function playermoviesGet(id,playerid) {

  try {
    setTokenHeader();
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/SelectedMoviesByPlayerId?leagueId=${id}&playerId=${playerid}`
  );
    return response;
   
  }
  catch (err) {
   return;
  }
}
export async function draftleaguetimeGet(id,value) {

  try {
    setTokenHeader();
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/RemainingDraftTime?leagueId=${id}&picked=${value}`
  );
    return response;
   
  }
  catch (err) {      
         Errormsg(APIerrorTitle)
      
  }
}
export async function trademoviePost(value) {
  setTokenHeader();
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/TradeMovie`,value,{
   headers: {
     "Content-Type": "multipart/form-data",
   }});
  return response;

}
export async function accepttradeRequest(value) {
  setTokenHeader();
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/AcceptTradeRequest`,value,{
   headers: {
     "Content-Type": "multipart/form-data",
   }});
  return response;

}
export async function rejecttradeRequest(value) {
  setTokenHeader();
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/RejectTradeRequest`,value,{
   headers: {
     "Content-Type": "multipart/form-data",
   }});
  return response;

}
export async function counteroffertradeRequest(value) {
  setTokenHeader();
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/CounterOfferMovie`,value,{
   headers: {
     "Content-Type": "multipart/form-data",

   }}
  
  );
  return response;

}

export async function notificationGet(id) {

  try {
    setTokenHeader();
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/GetTradeRequestMessages?logedInUserId=${id}`
  );

    return response;
   
  }
  catch (err) {
         Errormsg(APIerrorTitle)
     
  }
}
export async function trademoviesGet(id,msg) {
const encodedMsg = encodeURIComponent(msg);
  try {
    setTokenHeader();
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/GetMoviesForTrade?logedInUserId=${id}&tradeMessage=${encodedMsg}`
  
  );
    return response;
   
  }
  catch (err) {
   return;
  }
}

export async function postMessage(value) {

  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/ContactUs/SendMessage`,value,{
   headers: {
     "Content-Type": "multipart/form-data",
   }});
  return response;

}

export async function upcomingmoviesGet() {

  setTokenHeader();
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/MoviesApi/UpcomingMovies`
    );
    return response;

}

export async function templatedetailsGet(temId) {
  setTokenHeader();
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/GetTemplateDetails?TemplateTypeId=${temId}`
  
  );
    return response;
 
}

export async function weeklyscoresGett(leagueId) {

  try {
    setTokenHeader();
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/PlayerScoreApi/PlayerScoresForHistory?leagueId=${leagueId}`
  
  );
    return response;
   
  }
  catch (err) {
       Errormsg(APIerrorTitle)
     
  }
}
export async function weeklyscoresGet(leagueId,userId) {

  try {
    setTokenHeader();
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/PlayerScoreApi/PlayerScoresForHistory?leagueId=${leagueId}&playerId=${userId}`
  
  );
    return response;
   
  }
  catch (err) {
 
       Errormsg(APIerrorTitle)
     
  }
}


export async function upcomingpollmoviesGet(leagueId) {

  try {
    setTokenHeader();
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/GetUpcomingMovies?leagueId=${leagueId}` 
  );
    return response;
   
  }
  catch (err) {
   return;
  }
}


export async function swapwithbenchmoviePost(value) {
  setTokenHeader();
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/SwapMovieWithBench`,value,{
   headers: {
     "Content-Type": "multipart/form-data",
   }});
  return response;

}
export async function dropmoviewithpoolPost(value) {
  setTokenHeader();
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/SwapMovieWithMoviesPool`,value,{
   headers: {
     "Content-Type": "multipart/form-data",
   }});
  return response;
}

export async function userleagueshistoryGet(userid) {
  try {
    setTokenHeader();                                                   
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeagueHistory/GetLeagueHistoryByPlayerId?logedInUserId=${userid}`
  );
    return response;
   
  }
  catch (err) {       
         Errormsg(APIerrorTitle)
      
  }
}


export async function moviefilterPost(value) {
  setTokenHeader();
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/MoviesApi/GetMoviesByFilter`,value,{
   headers: {
     "Content-Type": "multipart/form-data",
   }});
  return response;
}


export async function popularPostget() {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LatestNewsApi/GetPopularPost`);
    return response;
  }
  catch (err) {    
       Errormsg(APIerrorTitle)
     
  }
}

export async function subscriptionPost(value) {
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/Subscription/Subscribe`,value,{
   headers: {
     "Content-Type": "multipart/form-data",
   }});
  return response;
}


export async function forgotpasswordEmailpost(email) {
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/Auth/ForgotPassword?userEmail=${email}`,
);
  return response;
}

export async function varifyotpPost(email,otp) {
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/Auth/VarifyOTP?UserEmail=${email}&OTP=${otp}`, 
  );
  return response;
}


export async function resetpasswordPost(value) {
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/Auth/ResetPassword`,
   value,{
   headers: {
     "Content-Type":  "multipart/form-data",
   }});
  return response;
}

export async function newNewsandarticleget(msg) {

  const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LatestNewsApi/GetArticleByName?ArticleString=${msg}`
);
  return response;

}
export async function refeshTokenapi(value) {
  setTokenHeader();
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/Auth/RefreshToken` ,
    value,{
      headers: {
        "Content-Type":  "multipart/form-data",
      }});
  return response;

}
export async function confirmemailGet() {
  setTokenHeader();
  const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/Auth/ConfirmEmail?token=${Cookies.get("token")}&userEmail=${Cookies.get("email")}`
);
  return response;
}


export async function allcategoryDetailsget() {
  setTokenHeader();
  const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/CategoryApi/AllCategoryDetails`
);
  return response;
}



export async function categoryByidget(id) {
  setTokenHeader();
  const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/CategoryApi/CategoryById?CategoryId=${id}`
);
  return response;
}


export async function alltemplatedetailsGet() {

  try {
    setTokenHeader();
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/GetAllTemplate`
  
  );
    return response;
  }
  catch (err) {
         Errormsg(APIerrorTitle)
    
      
  }
}

export async function PlayerdetailsGet(playerid) {
  setTokenHeader();    
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/PlayerUsers/PlayerUserDetailsById?Id=${playerid}`
    );
    return response;
}


export async function completeTraderequest(value) {
  setTokenHeader();
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/CompleteTradeRequest`,value,{
   headers: {
     "Content-Type": "multipart/form-data",
   }});
  return response;
}


export async function dropRostermovie(leagueId,userId,movieId,categoryId) {
  setTokenHeader();    
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/DropRosterMovie?leagueId=${leagueId}&logedInUserId=${userId}&movieId=${movieId}&categoryId=${categoryId}
`
    );
    return response;
}
export async function pickformmoviepoolPost(value) {
  setTokenHeader();
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/PickFromMoviesPool`,value,{
   headers: {
     "Content-Type": "multipart/form-data",
   }});
  return response;
}
export async function swaprosterMovies(value) {
  setTokenHeader();
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/SwapRosterMovie`,value,{
   headers: {
     "Content-Type": "multipart/form-data",
   }});
  return response;

}

export async function manageacceptMovies(value) {
  setTokenHeader();
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/ManageAcceptedMovie`,value,{
   headers: {
     "Content-Type": "multipart/form-data",
   }});
  return response;

}



export async function updateleaguePost(leagueid,value) {
  setTokenHeader();
       const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/UpdateLeague?id=${leagueid}`,value
        ,{
        headers: {
          "Content-Type": "multipart/form-data",
        }}
      );
       return response;
}

export async function leagueinvitenotificationGet(playerid) {
  setTokenHeader();    
    const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/LeagueInvites/AllLeagueInvites?userId=${playerid}`
    );
    return response;
}




export async function readleagueInvite(Inviteid) {
  setTokenHeader(); 
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeagueInvites/ReadLeagueInvite?InviteId=${Inviteid}`, 
  );
  return response;
}

export async function readtradeMsg(userid,msgid,msg) {
  setTokenHeader(); 
  const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/LeaguesApi/IsReadTradeMessage?logedInUserId=${userid}&tradeId=${msgid}&tradeMessage=${msg}`, 
  );
  return response;
}



