import React, { useState ,useEffect} from 'react'
import "./Subscriptionpayment.css";
import ScrollButton from "../../components/scrollbutton/ScrollButton";
import Navbarr from "../../components/header/Navbarr";
import { Footer } from "../../components/footer/footer";
import swal from 'sweetalert';
import {  useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import {subscriptionPost,editpGet} from "../../api/handleapi";
import { setLoginuserinfo } from '../../feature/showSlice/loginuserinfoSlice';
import { setPaymentdetail } from '../../feature/showSlice/paymentdetailSlice';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement,Elements,useStripe,useElements} from '@stripe/react-stripe-js';
import { Errormsg } from "../../components/tostifymsg/Errrormsg";
import { useNavigate } from "react-router-dom";
import Subheader from '../../components/subheader/Subheader';
import Sidebar from '../../components/sidebar/Sidebar';
import Cookies from 'js-cookie';
import Loader from '../../components/loader/Loader';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { apiErrorMsg, APIerrorTitle, errorstatuscheck, reloginMsg, subscriptionpaymentMsg, successfullyTitle } from '../../components/language/Language';
import { cookieNames, stripepaymentPublickey } from '../../Config/config';
import { handle401Error } from '../../components/handle401Error';
import Googleadshorizontal from '../../components/google-AdSense/Googleadshorizontal';
const stripePromise  =  loadStripe(stripepaymentPublickey);

const CheckoutForm = ({setLoading}) => {
  const [btndisable, setBtndisable] = useState(false);
  const [togglebtn,setTogglebtn]=useState(false);
  const checklocal=Cookies.get("gotosubscription")
  const Navigate=useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const loginuserinfo = useSelector((state) => state.loginuserinfo);
  const paymentdetail = useSelector((state) => state.paymentdetail);
  const dispatch=useDispatch();
  const id=Cookies.get("id");
     const Token=Cookies.get('token');
     const subscriptionenddate=Cookies.get("Paymentdetail");


     const editpFun = async (id) => {
      try {
      const response = await editpGet(id); // You might want to pass some parameters to get
      dispatch(setLoginuserinfo({userprofileimage:response?.data?.image,name:response?.data?.firstName+" "+response?.data?.lastName,email:response?.data?.email,userid:id}))
      Cookies.set("userprofileimage",response?.data?.image)
    } catch (error) {
      if(error?.message===errorstatuscheck){         
        await Errormsg(reloginMsg);
        await handle401Error(cookieNames)
        await Navigate("/");
      }
      
    }
  };
  useEffect(() => {
    if(id!==null||undefined){
      editpFun(id);
    }
    else{
      return;
    }
  }, [id]);

  const postPayment =async (token) => {
    var bodyFormData = new FormData();
    bodyFormData.append("PlayerName",loginuserinfo?.name);
    bodyFormData.append("PlayerEmailId",loginuserinfo?.email);
    bodyFormData.append("Plan","Yearly");
    bodyFormData.append("StripeToken",token);
    try {
      const response = await subscriptionPost(bodyFormData); 
      if (response?.status === 200) {
        setLoading(false)
        setTogglebtn(!togglebtn);
        Cookies.set("Paymentdetail",response?.data?.data?.subscriptionEndDate)
        dispatch(setPaymentdetail(response?.data?.data))
        if(Token!=undefined ||null)
        swal({
                title: successfullyTitle,
                text: subscriptionpaymentMsg,
                icon: "",
                buttons:"OK",
                closeOnClickOutside:false,
                dangerMode: false,
        }).then(() => {
          setBtndisable(false);
        });
        else{
          setBtndisable(false);     
        }
      }
    } catch (error) {
      if (error.response) {
        setLoading(false)
        swal({
          title: APIerrorTitle,
          text: apiErrorMsg,
          icon: "error",
          buttons: "OK",
          closeOnClickOutside:false,
          dangerMode: true,
        }).then(() => {
          setBtndisable(false);
        });
        // The request was made and the server responded with a status code
      }  else {
        // Something else happened while setting up the request
        return;
      }
      
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
      if (paymentMethod) {
        setBtndisable(true);
        setLoading(true);
              const cardElement = elements.getElement(CardElement);
              const {token}  = await stripe.createToken(cardElement);
              if(token?.id!=null||undefined){
               postPayment(token?.id)
              }
              else{
                setLoading(false)
                swal({
                  title: APIerrorTitle,
                  text: APIerrorTitle,
                  icon: "error",
                  buttons: "OK",
                  closeOnClickOutside:false,
                  dangerMode: true,
                }).then(() => {
                  setBtndisable(false);
                });
              }
      }
      else if(error?.message){
 swal({
                title: "",
                text:error?.message,
                icon: "",
                buttons:"OK",
                closeOnClickOutside:false,
                dangerMode: false,
              }).then(() => {
                setBtndisable(false);
              });
      }
  };



 
  return (   
      <div className='pb-5 pt-5 payment-sec'>
       {(togglebtn==true) || (checklocal!=null ||undefined)?
       <div id="Checkout" className="inline">
         <h1>Payment</h1>
           <div className="card-row card-row-content">
          <span className="visa"></span>
          <span className="mastercard"></span>
          <span className="amex"></span>
          <span className="discover"></span>
      </div>
      <form onSubmit={handleSubmit} className='payment_form'>
       <div className="login-box" id="step2"  >
                <div className="form-group pt-2 pb-2">
              <label for="PaymentAmount" className='label_payment'>Subscription Amount</label>
              <div className="amount-placeholder">
                  <span>$</span>
                  <span>10.00 Annually</span>
              </div>
          </div>
                    <div >
                        <CardElement
                            className="StripeElement"
                              options={{
                                  style: {
                                  base: {
                                      fontSize: '18px',
                                      color: '#fff',
                                      fontSmoothing: "antialiased",
                                      ":-webkit-autofill": {
                                        color: "#fce883",
                                      },
                                      '::placeholder': {
                                      color: '#fff',
                                      },
                                  },
                                  invalid: {
                                      color: '#9e2146',
                                  },
                                    },
                                  
                              }}
                            
                        />
                      </div>
                      <div className='text-center pt-5'>
                      <button name="submintbtn2" className="btn btn-primary submit-button mt-2" disabled={btndisable}>
              <span className="submit-button-lock"></span>
              <span className="align-middle ">Pay $10.00</span>
          </button>
                      </div>
                  </div>
                  </form>
      </div>:null}
        {(paymentdetail?.subscriptionEndDate == null ||undefined) && (subscriptionenddate == null ||undefined) ? 
      
      <div>
      {(togglebtn==false) && (checklocal==null ||undefined)?
      <div id="Checkout" className="inline">
      <h1>Subscribe</h1>
      <div className="card-row text-white">
     
   
      <p>Want to support the creators and help keep the lights on? Become a subscriber for $10/year, and enjoy the following perks:</p>
      <ul>
      <li><b>Enjoy an ad-free experience with no interruptions and no distractions.</b></li>
      <li><b>Unlock the ability to comment on current news stories in the Gaffer<sup className="Trademark">TM</sup> database and join the Gaffer<sup className="Trademark">TM</sup> community.</b></li>
      <li><b>Receive emails featuring weekly newsletters, point recaps, Gaffer<sup className="Trademark">TM</sup> updates, insider information to give you a leg up in your leagues.</b></li>
     </ul>
<p> Click the ‘Subscribe’ button to enhance your Gaffer<sup className="Trademark">TM</sup> experience!</p>
     
      </div>
      <div className='text-center pt-5 pb-5'>
                      <button name="submintbtn2" className="btn btn-primary submit-button mt-2" onClick={()=>setTogglebtn(!togglebtn)}>
              <span className="align-middle ">Subscribe</span>
          </button>
                      </div>
                      </div>:null}
      </div>
     :<div id="Checkout" className="inline">
      <h1>Subscription Confirmed</h1>
      <div className="card-row text-white">
        <p>Congratulations! Your subscription  has been successfully confirmed!</p>
      <p>Your subscription is valid till { moment(new Date(paymentdetail?.subscriptionEndDate||subscriptionenddate + 'Z')).format("MMMM D, YYYY")}
         
         </p>
      <p>Thank you for choosing our service! If you have any questions or need assistance, please don't hesitate to contact us.</p>
      </div>
      </div> }
      </div>
  )};


function Subscriptionpayment() {  
  const [loading,setLoading]=useState(false);

  return (
    <div className='main_payinvoice'>
     <Navbarr/>
     <div className="after-nav containers con_div">
     <Googleadshorizontal props="pt-2 pb-2 text-center"/>
     <Subheader />
     <br />
     <div className="row g-0">
     <Sidebar />
     <div className="col-md-8 col-lg-9 col-xl-10">
     <Elements stripe={stripePromise}>
     <CheckoutForm setLoading={setLoading}/>
    </Elements>
</div>
</div>
     </div>
<Footer />
<ScrollButton />
{loading?<Loader/>:null}
</div>
  )
}

export default Subscriptionpayment