import React, { useRef, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { MdClose } from "react-icons/md";
import Invites from "./Invites";
import "./Modalinvites.css";
import { useSelector, useDispatch } from 'react-redux';
import { closeInvite } from "../../../feature/showSlice/showmodalinviteSlice";

export const Modalinvites= ({ cleague }) => {

  const modalRef = useRef();
  const dispatch = useDispatch();
  const showInvite = useSelector((state) => state.showInvite);
  const animation = useSpring({
    config: {
      duration: 350,
    },
    opacity: showInvite ? 1 : 0,
    transform: showInvite ? `translateY(0%)` : `translateY(-100%)`,
  });

  useEffect(() => {
    const disableScroll = (e) => {
      e.preventDefault(); // Prevent the default scroll behavior
      window.scroll(0, 0); // Keep the window at the top position
    };

    if (showInvite) {
      document.body.style.overflow = "hidden"; // Disable scrolling on the body
      window.addEventListener("scroll", disableScroll); // Prevent scrolling on the window
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling on the body
      window.removeEventListener("scroll", disableScroll); // Enable scrolling on the window
    }

    return () => {
      document.body.style.overflow = "auto"; // Make sure to enable scrolling on the body when the component is unmounted
      window.removeEventListener("scroll", disableScroll); // Enable scrolling on the window when the component is unmounted
    };
  }, [showInvite]);

  return (
    <div>
      {showInvite ? (
        <div ref={modalRef} className="Background_invites">
          <animated.div style={animation}>
            <div  showInvite={showInvite}className="trading_wrapper_invite ModalWrapper_invites">
              <div className="d-flex w-100">
              <h3>Invite Users</h3>
              <div
              className="CloseModalButton_invites"
                aria-label="Close modal"
                onClick={() => dispatch(closeInvite())}
              >
  
                <MdClose className="close_btn"/>
                </div>
              </div>
              <div className="ModalContent_invites">
                <Invites  cleague={cleague} />
              </div>
            </div>
          </animated.div>
        </div>
      ) : null}
    </div>
  );
};
