import moment from 'moment';
import React, { useEffect, useState } from 'react';

function CountDownTimer({ createdatetime, startdatetime}) {
  const [time, setTime] = useState("");

  useEffect(() => {
    const mstart = moment.utc(createdatetime);
    const mend = moment.utc(startdatetime);
  
    const updateRemainingTime = () => {
      const now = moment.utc();
      const remaining = moment.duration(mend.diff(now));
  
      if (remaining.asMilliseconds() > 0) {
        const months = remaining.months();

        if(months > 0)  {
          setTime(
            `(${remaining.months()} months : ${remaining.days()} day : ${remaining.hours()} Hrs : ${remaining.minutes()} Mins : ${remaining.seconds()} Sec)`
          );
        } else {
          setTime(
            `(${remaining.days()} day : ${remaining.hours()} Hrs : ${remaining.minutes()} Mins : ${remaining.seconds()} Sec)`
          );
        }
      } else {
        setTime(`(0 day : 0 Hrs : 0 Mins : 0 Sec)`);
      }
    };
  
    updateRemainingTime();
  
    const timer = setInterval(updateRemainingTime, 1000);
  
    return () => clearInterval(timer);
  }, [createdatetime, startdatetime]);  

  return (
    <div id="timer">
      <span>{time}</span>
    </div>
  );
}

export default CountDownTimer;