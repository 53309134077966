import React from 'react'
import {  closeModal } from '../../feature/showSlice/showModalSlice';
import "./Subscription.css";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';


function Subscription({setShowModalTwo,setShowsubscriptionpayment}) {
    const dispatch=useDispatch();
    const navigate=useNavigate();

    const goto=async()=>{
        await dispatch(closeModal(false));
        await navigate("/Subscriptionpayment");
    }
    const gotoskip=()=>{
        navigate("/Dashboard");
        setShowModalTwo(false);
        setShowsubscriptionpayment(false);
    }




  return (
<div className='main_subscription'>
<div className='mt-1 mb-2 d-flex justify-content-center' >
<svg width="83" height="92" viewBox="0 0 83 92" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M40.535 59.916L40.9623 74.9919L28.2167 55.8975" stroke="black"/>
<path d="M28.7578 91.2832L37.9154 80.0444" stroke="black"/>
<path d="M5.86365 70.3281L15.0212 59.0894" stroke="black"/>
<path d="M48.0295 91.2832L59.9771 76.8477" stroke="black"/>
<path d="M5.86365 91.2834L21.057 72.6372" stroke="black"/>
<path d="M41.2546 76.3239L55.3201 64.292" stroke="black"/>
<path d="M81.2553 0.962891L1.81177 47.2343L28.1896 56.2142L81.2553 0.962891ZM81.2553 0.962891L40.7998 59.7545L81.9169 72.8102L81.2553 0.962891Z" stroke="black"/>
</svg>
</div>
<h3 className='d-flex justify-content-center'>
<b>Subscribe</b>
</h3>
<div className='benefits_points mt-2'> 
    <h6>Want to support the creators and help keep the lights on? Become a subscriber for $10/year, and enjoy the following perks:</h6> 
<ul>
  <li><h6> <b>Enjoy an ad-free experience with no interruptions and no distractions.</b></h6>	</li>
  <li><h6><b>Unlock the ability to comment on current news stories in the Gaffer<sup className="Trademark">TM</sup> database and join the Gaffer<sup className="Trademark">TM</sup> community.</b></h6>	</li>
  <li><h6><b>Receive emails featuring weekly newsletters, point recaps, Gaffer<sup className="Trademark">TM</sup> updates, insider information to give you a leg up in your leagues.</b></h6>	</li>

</ul> 
<h6>Click the ‘Subscribe’ button to enhance your Gaffer<sup className="Trademark">TM</sup> experience!</h6> 
</div>
<div className='subscription_amounts'>
<div className="subscription_amount d-flex justify-content-center">
<p className='m-0'>
    10
</p>
</div>
<div className='doller'>
<p className='m-0'>
    $
</p>
</div>
<div className='d-flex justify-content-center'><p className='mt-0'>Annually</p></div>

</div>
<button className='subscribe_btn mb-1' onClick={()=>goto()}>
    Subscribe
</button>
<button className='subscribe_btn' onClick={()=>gotoskip()}>
Skip for now
</button>
</div>              
   )
}

export default Subscription