import { createSlice } from '@reduxjs/toolkit';

export const showmodalinviteSlice = createSlice({
  name: 'showInvite',
  initialState: false,
  reducers: {
    openInvite: (state) => true,
    closeInvite: (state) => false,
  },
});

export const { openInvite, closeInvite } = showmodalinviteSlice.actions;

export default showmodalinviteSlice.reducer;