import React,{useState} from "react";
import "./Contactuspage.css";
import { ReactComponent as YourIcon3 } from "../../assets/Vector (3).svg";
import { ReactComponent as YourIcon } from "../../assets/twitter_new.svg";
import { ReactComponent as YourIcon1 } from "../../assets/Vector (2).svg";
import ScrollButton from "../../components/scrollbutton/ScrollButton";
import Navbarr from "../../components/header/Navbarr";
import { Footer } from "../../components/footer/footer";
import  {Contactusformvalidation}  from "./Contactusformvalidation";
import { postMessage } from "../../api/handleapi";
import swal from "sweetalert";
import Cookies from 'js-cookie';
import Loader from "../../components/loader/Loader";
import { ReactComponent as YourIcontiktok } from "../../assets/tiktokblack1.svg";
import { apiErrorMsg, APIerrorTitle, contactemailAuthcheck, MessageSentMsg, successfullyTitle } from "../../components/language/Language";

import Googleadshorizontal from "../../components/google-AdSense/Googleadshorizontal";



function Contactuspage() {

  
const [loading,setLoading]=useState(false);
  const email=Cookies.get("email");
  const name=Cookies.get("name");
  const [btndisable,setBtndisable]=useState(false);
  const [contactus, setContactus] = useState({
    message: "",
    sendername: "",
    senderemail: "",
  });
  
  const { errors, validateForm, onBlurField } =
  Contactusformvalidation(contactus);

  const onUpdateField = (e) => {
    const field = e.target.name;
    const nextFormState = { ...contactus, [field]: e.target.value };
    setContactus(nextFormState);
    if (errors[field]?.dirty)
      validateForm({
        contactus: nextFormState,
        errors,
        field,
      });
    };
    
    const postMsg = async() => {
      setBtndisable(true) ;
      setLoading(true)
      var bodyFormData = new FormData();
      bodyFormData.append("UserEmail", contactus?.senderemail);
      bodyFormData.append("UserName", contactus?.sendername);
      bodyFormData.append("Message", contactus?.message);
      var bodyFormDataa= new FormData();
      bodyFormDataa.append("UserEmail", email);
      bodyFormDataa.append("UserName", name);
      bodyFormDataa.append("Message", contactus?.message);

      try {
        const response =  await postMessage(email!=null||undefined?bodyFormDataa:bodyFormData);
        if (response?.status === 200) {
          setLoading(false)
          swal({
            title: successfullyTitle,
            text: MessageSentMsg,
            icon: "success",
            buttons: "OK",
            closeOnClickOutside:false,
            dangerMode: false,
          })  .then(() => {
            setContactus({
              message: "",
              sendername: "",
              senderemail: "",
            });
            setBtndisable(false);
          });
        } 
      } 
      catch (error) {
        if (error.response.data.message===contactemailAuthcheck) {
          setLoading(false)
          swal({
            title: APIerrorTitle,
            text: apiErrorMsg,
            icon: "",
            buttons: "OK",
            closeOnClickOutside:false,
            dangerMode: true,
          }).then(() => {
            setBtndisable(false);
          });
        }
         else if (error.response.data.message==undefined) {
          setLoading(false)
          swal({
            title: APIerrorTitle,
            text: apiErrorMsg,
            icon: "",
            buttons: "OK",
            closeOnClickOutside:false,
            dangerMode: true,
          }).then(() => {
            setBtndisable(false);
          });
          // The request was made and the server responded with a status code
          
        } 
      }
    };

  const onSubmitForm = (e) => {
    e.preventDefault();
    const { isValid } = validateForm({ contactus, errors, forceTouchErrors: true });
     
      if(isValid){
        postMsg();
      }
      
  };


  return (
<div>
      <Navbarr />
      <div className="parent_contactus ">
        <div className="row g-0">
          <div className="col-md-4 textmasasge">
            <h3> Say hello to</h3>
            <h3>your little friends!</h3>
            <div className="para-contactus">
              <p className="pp-text">
              Are you not seeing a movie that you think should be here? Have a question or a suggestion?
Don’t hesitate to reach out to our team, and we will be in touch with you as soon as possible.
              </p>
            </div>
            <div className="icons_contactus">
              <a href="https://www.facebook.com/profile.php?id=61562846707767" target="_blank">
              <YourIcon3 />
              </a>
           <a href="https://x.com/Gaffer_llc" target="_blank"><YourIcon /></a>
           <a href=" https://www.instagram.com/gaffer_llc/" target="_blank"><YourIcon1 /></a>
           <a href="https://www.tiktok.com/@roughcut_pod?_t=8omznqoW4yc&_r=1" target="_blank">  
           <YourIcontiktok/></a>
              
            
            </div>
          </div>
          <div className="col-xl-8 col-lg-8 col-md-8  input_fcontactus">
            <div className="col-md-12">
              <label for="exampleFormControlTextarea1" className="form-label">
                Message
              </label>
              <textarea
                 type="text"
                className="form-control ccontact_subinput"
                id="message"
                rows="4"
                name="message"
                value={contactus?.message}
                onChange={onUpdateField}
                onBlur={onBlurField}
                maxLength={2200}
              >               
              </textarea>
              {errors?.message?.dirty && errors?.message?.error ? (
                  <p className="c_leagueFieldErrorMessage">
                    {errors?.message?.message}
                  </p>
                ) : null}
            </div>
           { email==null?<div className="subinput_field row">
              <div className="col-md-6">
                <label for="exampleFormControlInput1" className="form-label">
                  Your  name
                </label>
                <input
                     type="text"
                  className="form-control contact_subinput"
                  id="name"
                  name="sendername"
                  value={contactus?.sendername}
                  onChange={onUpdateField}
                  onBlur={onBlurField}
                />
                 {errors?.sendername?.dirty && errors?.sendername?.error ? (
                  <p className="c_leagueFieldErrorMessage">
                    {errors?.sendername?.message}
                  </p>
                ) : null}
              </div>
              <div className="col-md-6 contactus_email">
                <label for="exampleFormControlInput1" className="form-label">
              Your  email
                </label>
                <input
                    type="text"
                  className="form-control contact_subinput"
                  id="email"
                  name="senderemail"
                  value={contactus?.senderemail}
                  onChange={onUpdateField}
                  onBlur={onBlurField}
                />
                 {errors?.senderemail.dirty && errors?.senderemail?.error ? (
                  <p className="c_leagueFieldErrorMessage">
                    {errors?.senderemail?.message}
                  </p>
                ) : null}
              </div>
            </div>:""}
            <div className="d-md-flex  contactus-btn">
              <div className="">
              <button type="button" disabled={btndisable}  className="btn contactus-btnn"  onClick={onSubmitForm}>
              Send Message
              </button>
            
              </div>
            </div>
            <div className="mt-5 mb-2">
            <Googleadshorizontal props="mt-2 mb-2"/>
            </div>
           
          </div>
         
        </div>
        
      </div>
      <Footer />
      <ScrollButton />
      {loading?<Loader/>:null}
    </div>
  
  );
}

export default Contactuspage;
