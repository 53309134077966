// Registered Successful 
export const  registerSuccessTitle = "Registration successful"

export const  registerSuccessMsg = "Check your spam folder if you do not receive the verification email."

export const  registernotverified = "User details found but email not confirmed. Email verification has been resent."

// Email already registered
export const  emailRegisteredErrorTitle = "Are you already registered?"
export const  emailRegisteredErrorMsg = "User already registered with the same email. You can login with this email address."

// when try login without verification
export const  emailnotverified = "User not verified"
export const  emailIncorrecttitle= "Incorrect email or password"
export const  emailnotverifyMsg="Write a correct email and password or check verification email"
// old Passoword validation message
export const oldPasswordvalidation="This password is not the current password"

// Add/Removed to Wachlist button after get this message
export const addedtoWatchlist="Added to watchlist"
export const removedtoWatchlist="Removed from watchlist"

//Successfully Title/ 
export const successfullyTitle= "Success";
export const successmanagemovieMsg= "Adjust successful"
export const TradecompleteMsg= "Complete trade process"
export const managemovievalidationMsg="Select at lest one movie for adjust trade"
//Edit profile
export const editprofilesuccessfullyMsg="Profile edited successfully"

//API Error
export const apiErrorMsg="Check back later"
export const APIerrorTitle="Network issue!"

//subscription payment message
export const subscriptionpaymentMsg="Payment successful"

//Comment  message after Successfully
export const commnetsuccessfullyMsg="Comment posted"

//success title
export const SuccessTitle="Success"

// if league already created on create leageu page
export const leaguealreadyMsg="This league name already exists"
export const tradepickcompleteMsg="Firstly Pickup the Empty movie slot for complete trade"

//create league message after league created
export const leaguecreatedMSG="League created successfully"
export const leagueupdatedMSG="League Updated successfully"
export const tradereadconditonMSG="This trade request has been cancelled"
// Wrong title
export const WrongTitle="Incorrect"

//when not selecting any user on invite popup
export const inviteUsersMsg="Please select at least one user"

// users invited successfully message
export const invitessentMsg ="Invites sent successfully"

// joined league message 
export const successfullyJoinedMsg="Joined successfully"

// scores message if movies get the scores when user pick the movies on draft page
export const  moviesscoregetMsgfordraft="Movie cannot be picked after it has earned points."

// pick Successful message for draft movies
export const pickSuccessful="Pick successful"

// scores message if movies get the scores when user pick the movies on pickups popup
export const moviesscoregetMsgforpickups="Movie can not be swapped or traded after it has earned points unless it is on your bench"

//In draft if category not match as per the users category slots 
export const categorynotmatchforDraft="Category does not match based on your selected category slots"

// after token expired
export const reloginMsg="Login again"

//when user not fill the info for edit profile
export const editprofilevalidation="Please fill in all of the required details"
export const contactusMsg="Please enter the details"
//when user change the password successfully
export const passwordchangeTitle="Password changed successfully"

//when user change the password successfully after relogin
export const passwordchangeMsg="Log back into the application with new password"

// when user not take the subscription for comment services used on news details page
export const  subscriptionreqTitle="Subscription required"
export const subscriptionreqMsg="You must be a subscriber to leave comments"

//when user will accept the trade request
export const  tradereqAcceptMg="Trade Request Accepted"

//when user already accepted the trade request.
export const alreadyacceptMsg="Trade already accepted"

//when user reject the trade request
export const traderequestrejectMsg="Trade Request Rejected"

//when user sent the counteroffer
export const counterofferMsg="Counter offer sent"


export const traderequestsent="Trade request sent"

//when user compare the movies for other user on view details page
 export const nomoviesfound="No movies found"


//when user not select the movies for Counteroffer
export const  CounteroffervalidationMsg="Select at least one movie to propose a counter offer"

export const  CountermoviesvalidationMsg="Select maximum three movies to counter offer"

//when user not select the movies for trades
export const tradevalidationMsg="Select at least one movie to trade"
export const trademoviesvalidationMsg="Select maximum three movies to trade"

//when user not select the movies for swaps
export const swapvalidationMsg="Select at least one movie to swap"


// contact us form message
export const MessageSentMsg="Message sent"

//After successfully swap the movies
export const swapMsg="Swap successful"

//when user not select the movies for pickups
export const  pickupsMsg="Select at least one movie for pickup"


//After pickup successful movie on pickups 
export const  pickupMsg="Pickup successful"

//if user Already joined league
export const alreadyjoinMsg="You have already joined this league"

//when user added the movie into watchlist before login
export const loginfirslyMsg="Please login first"


//After email sent for otp
export const otpsenttoemailMsg="Check your email for a one time password (OTP)"

//user not fill the otp for forgot password
export const Enterotpmsg="Enter the one time password (OTP)"


// user otp verify success
export const otpverifyTitle="OTP Verifieds"
export const  otpverifyMsg="Enter the new password"

//when user fill the wrong otp
export const otpcorrectionMsg="Incorrect OTP, Please enter correct OTP"


// change password message when user forgot password
export const changepasswordMsg="Your password has been changed successfully"

//if user fill the previous/old password for forgot
export const correctpasswordMsg= "Enter the correct new password"


// try again message 
export const tryagainMsg="Try Again"
 export const waitforturnMsg="Wait For Your Turn"
 export const managetradeconditionMsg="The movie on your first movie category should match one of the categories of the second movie you are attempting to swap"
 export const managetradeMsg="Select your movie based on category should match with trade movie for adjust"
  export const managepickupconditionMsg="Pool movie category should be same as selected movie category in order to swap"
    export const completetradevalidationMsg="Firstly, pick up the empty movie slots or adjust the trade movies to complete the roster for the trade process"
////////////////////////////Validation Messages///////////////////////

// first name validation messages
export const fNamevalidationMsg="Fill in your first name"
export const fNamelengthvalidationMsg="Incorrect First name length"
export const fNameIncorrectvalidationMsg="Incorrect first name"


//  last name validation messages
export const lNamevalidationMsg="Fill in your last name"
export const lNamelengthvalidationMsg="Incorrect Last name length"
export const lnameincorrectvalidationMsg="Incorrect last name"

//  gender name validation messages
export const gendervalidationMsg="Select gender"


//  DOB validation messages
export const dobvalidationMsg="Fill in your date of birth"
export const dobformatvalidationMsg="Incorrect date of birth format"

//  Email validation messages
export const emailvalidationMsg="Email is required"
export const emailformatvalidationMsg="Incorrect email format"


// Password validation messages
export const PasswordvalidationMsg="Password is required"
export const PasswordfomatvalidationMsg="Password must have a minimum 8 characters with at least one digit, one lowercase letter, one uppercase letter, and one special character"

// confirm passwrod validation messages
export const confirmpasswordvalidationMsg="Confirm password is required"
export const confirmpformatvalidationMsg="Confirm password must have a minimum of 8 characters"
export const confirmpconditionvalidationMsg="Passwords do not match"

// old password validation message
export const oldpasswordvalidationMsg="Please enter the old password"

//new password validation message
export const newpasswrodvalidationMsg="Please enter the the new password" 

//create league validation messages
export const entriesvalidation ="Invalid number of entries"
export const entriesvalidtionMsg="Please add the correct number of players"

export const  leagueNamevalidationMsg="Please enter a league name"
export const imageuploadvalidation ="Upload a league image"
export const imageuploadformatvalidationMsg="Only JPG and PNG files are acceptable"
export const startDateValidation= "Please enter a draft start date and time"
export const endDateValidation ="Please enter a league end date and time"
export const templateValidtion= "Please select a draft template"
export const selectcategoryvalidation="Select Category"

export const selectcategoryvalidationMsg="Select only 15 Catergory"
export const descriptionValition="Please provide a description of the league"
export const messageValition="The message must be filled out"

export const visibilityvalidationMsg="Please select the league visibility"
export const namevalidationMsg="Name is required"
export const userimagevalidationMsg="Upload the image"
export const loginAuthcheck="User not authorized."

export const loginAuthsuccesscheck="User logged in successfully"
export const commentpostvalidationMsg="Fill the comment"
export const regAuthcheck="This email is already registered"

export const regAuthsuccesscheck="User registered successfully. Email verification mail sent. Please verify."

export const regAuthsuccess="Your email has not been confirmed. Please check your email for the verification confirmation"

export const changepassAuthcheck="Invalid user or password"
export const moviePickAuthcheck="Movie cannot be picked if it has a score"
export const pickupAuthcheck="Movie cannot be swapped, dropped or traded after it earns points if it is not on the bench"

export const swapsAuthcheck="The movie on your bench should match one of the categories of the movie you are attempting to swap"
export const swapsrosterAuthcheck="You can trade only with same category movie as category of selected movie."
export const forgotpassAuthcheck="Invalid email and/or password"

export const forgotpasssucessAuthcheck="Your password has been successfully reset"

export const otpAuthcheck="This one time password is incorrect. Please enter correct OTP."
export const otpsuccessAuthcheck="Verification successful"

export const  forgotemailsuccessAuthcheck="Password reset instructions have been sent to your email"

export const  contactemailAuthcheck="Unable to send mail, please check input details and try again."


export const errorstatuscheck400="Request failed with status code 400" 

export const  swapsconAuthcheck="Please select a movie from your roster that shares a category with the bench movie you are trying to swap"

export const  emailconfirmtokenAuthcheck="EmailConfirmed Successfully."

export const tokenAuthcheck="Token expired, please re-register your self."

export const errorstatuscheck="Request failed with status code 401"
export const leaguestatuscheck="This league name already exists. Please try another name."

export const newsnotificationregexcheck="New article/news has been released with title: (.*)"


export const leagueinvitationEmailsubject="Gaffer-Fantasy Sports App"

export const awardsummaytext="Awards are handed out once the league has concluded"
export const forgotemailnotfound="User not found. Please register first."

