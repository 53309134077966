import React, { useState,useEffect } from 'react'
import  "./Trading.css";
import {accepttradeRequest,rejecttradeRequest,counteroffertradeRequest, notificationGet} from "../../../../../api/handleapi"
import { ReactComponent as YourIcon6 } from "../../../../../assets/Vectorwhite.svg";
import swal from 'sweetalert';
import { ReactComponent as YourIcon4 } from "../../../../../assets/close.svg";
import { ReactComponent as YourIcon5 } from "../../../../../assets/check.svg";
import { useDispatch } from "react-redux";
import {playermoviesGet} from "../../../../../api/handleapi"
import {setTrademoviedetailsforid} from '../../../../../feature/showSlice/trademoviedetailsforidSlice';
import Cookies from 'js-cookie';
import { openModalmdetails } from '../../../../../feature/showSlice/showmodaltrademoviedetailsSlice';
import { useCategory } from '../../../../../components/useCategory';
import { alreadyacceptMsg, apiErrorMsg, APIerrorTitle, CountermoviesvalidationMsg, counterofferMsg, CounteroffervalidationMsg, successfullyTitle, tradereqAcceptMg, traderequestrejectMsg } from '../../../../../components/language/Language';


function Trading1({ setNotificationget, playername,setShowModalTrading,trademovies,togglestate,setTogglestate,setNotificationlength, setShowTradeaccpet}) {


const suserid=trademovies?.requestFrom?.firstUserId
const userid=trademovies?.tradeRequestFor?.secondUserId
const Leagueid=trademovies?.tradeRequestFor?.leagueId

const [ftrade,setFtrade]=useState({
  FirstUserId :"",
  FMovieId :[],
  FCategoryId :[],
  FMovieName :"",
})

const [strade,setStrade]=useState({
  SecondUserId  :"",
  SMovieId  :[],
  SCategoryId  :[],
  SMovieName  :"",
})

const [requestFrom,setRequestForm]=useState([]);
const [tradeRequestFor,setTradeRequestFor]=useState([]);



const notificationGetcall = async (userid) => {
  try {
    const response = await notificationGet(userid); // You might want to pass some parameters to notificationget
    const notifications = response?.data?.data==undefined||null?[]:response?.data?.data;
    setNotificationget(() => {
  
      const combinedNotifications = [...notifications];
    
      const uniqueNotifications = [...new Set(combinedNotifications?.map(JSON.stringify))]?.map(JSON.parse);
    
     
      const  uniqueNotificationsreadlength = uniqueNotifications?.filter((notification) => !notification?.isViewed)?.length;
      setNotificationlength(uniqueNotificationsreadlength);
      return uniqueNotifications;
    });
  } catch (error) {
   return;
  }
};


const Categoryoptions=useCategory();
const [counterofferloginpmovie,setCounterofferloginpmovie]=useState();
const [counteroffersecondpmovie,setCounteroffersecondpmovie]=useState();

const [countermovieslist,setcounterMoviesList] = useState([]);
const [countermovieslistt,setcounterMoviesListt] = useState([]);
  const dispatch=useDispatch();


    const movieslistFunforloginp = async (Leagueid,userid) => {
      try {
        const response = await playermoviesGet(Leagueid,userid); 
        Cookies.set("League_idforcounter",Leagueid);
        setCounterofferloginpmovie(response?.data?.data?.logedInPlayerMovies)
         } catch (error) {
        return;
      }
    }



      const movielistFunforotherp = async(Leagueid,suserid) => {
        try {
          const response = await playermoviesGet(Leagueid,suserid); 
          Cookies.set("secondpid",response?.data?.data?.playerId)
          Cookies.set("League_idforcounter",Leagueid);
           Cookies.set("secondpname",response?.data?.data?.playerName)
          setCounteroffersecondpmovie(response?.data?.data?.logedInPlayerMovies)
             }
           catch (error) {
            return;
        }
      };
  
      useEffect(() => {
        const updatedMoviesArray = counterofferloginpmovie?.map((movie) => ({
          ...movie,
          checked: false,
      }));
      const updatedMoviesforfinals = updatedMoviesArray?.filter(movie => movie?.finalScore === 0 );
      
        setcounterMoviesList(updatedMoviesforfinals);
        const updatedMoviesArrayy = counteroffersecondpmovie?.map((movie) => ({
          ...movie,
          checked: false,
      }));
        const secondpupdatedMoviesforfinals = updatedMoviesArrayy?.filter(movie => movie?.finalScore === 0 );
        setcounterMoviesListt(secondpupdatedMoviesforfinals);
        },[togglestate]);
      
  const onsubmitCounter = async() => {
    await movieslistFunforloginp(Leagueid,userid);
    await  movielistFunforotherp(Leagueid,suserid);
    await setTogglestate(false);
  };

  const acceptTrade = async() => {
    var bodyFormData = new FormData();
    bodyFormData.append("LeagueId", trademovies?.requestFrom?.leagueId);
    bodyFormData.append("FirstUserId", trademovies?.tradeRequestFor?.secondUserId);
    if ( tradeRequestFor&& tradeRequestFor?.length > 0) {
      for (let i = 0; i < tradeRequestFor?.length; i++) {
        bodyFormData.append(`FMovieId[${i}]`, tradeRequestFor[i]?.movieId);
      }
    } else {
      bodyFormData.append("FMovieId",tradeRequestFor?.movieId);
    }
    if (tradeRequestFor && tradeRequestFor?.length > 0) {
      for (let i = 0; i < tradeRequestFor?.length; i++) {
        bodyFormData.append(`FCategoryId[${i}]`, tradeRequestFor[i]?.categoryId);
      }
    } else {
      bodyFormData.append("FCategoryId",tradeRequestFor?.categoryId);
    }
    bodyFormData.append("SecondUserId", trademovies?.requestFrom?.firstUserId);


    if (requestFrom && requestFrom?.length > 0) {
      for (let i = 0; i < requestFrom?.length; i++) {
        bodyFormData.append(`SMovieId[${i}]`, requestFrom[i]?.movieId);
      }
    } else {
      bodyFormData.append("SMovieId", requestFrom?.movieId);
    }
    if (requestFrom && requestFrom?.length > 0) {
      for (let i = 0; i < requestFrom?.length; i++) {
        bodyFormData.append(`SCategoryId[${i}]`, requestFrom[i]?.categoryId);
      }
    } else {
      bodyFormData.append("SCategoryId", requestFrom?.categoryId);
    }
     try {
      const response =  await accepttradeRequest(bodyFormData);
      if (response?.status === 200) {
        swal({
          title: successfullyTitle,
          text: tradereqAcceptMg,
          icon: "success",
          buttons: "Ok",
          closeOnClickOutside:false,
          dangerMode: false,
        }).then((value) => {
          if(value){
            Cookies.set("tradeaccept","accept")
            setShowTradeaccpet(true);
            movieslistFunforloginp(Leagueid,userid);
            
            notificationGetcall(Cookies.get("id"))
            setShowModalTrading(false);
          }})
      } 
    } 
    catch (error) {
      if (error.response.data?.message!=undefined) {
              
                swal({
                  title: "",
                  text: error.response.data.message,
                  icon: "",
                  buttons: "ok",
                  closeOnClickOutside:false,
                  dangerMode: false
                }).then((value) => {
                  if(value){
                    setShowModalTrading(false);
                  }})
              }         
              else if (error.response.data?.message===undefined) {
                swal({
                    title: "",
                    text: alreadyacceptMsg,
                    icon: "error",
                    buttons: "ok",
                    closeOnClickOutside:false,
                    dangerMode: false
                  }).then((value) => {
                    if(value){
                      setShowModalTrading(false);
                    }})
              }else {
               return;
              }
 
    }
  }

  const rejectTrade = async() => {
    var bodyFormData = new FormData();
    bodyFormData.append("LeagueId", trademovies?.requestFrom?.leagueId);
    bodyFormData.append("FirstUserId", trademovies?.tradeRequestFor?.secondUserId);
    if ( tradeRequestFor&& tradeRequestFor?.length > 0) {
      for (let i = 0; i < tradeRequestFor?.length; i++) {
        bodyFormData.append(`FMovieId[${i}]`, tradeRequestFor[i]?.movieId);
      }
    } else {
      bodyFormData.append("FMovieId",tradeRequestFor?.movieId);
    }
    if (tradeRequestFor && tradeRequestFor?.length > 0) {
      for (let i = 0; i < tradeRequestFor?.length; i++) {
        bodyFormData.append(`FCategoryId[${i}]`, tradeRequestFor[i]?.categoryId);
      }
    } else {
      bodyFormData.append("FCategoryId",tradeRequestFor?.categoryId);
    }
    bodyFormData.append("SecondUserId", trademovies?.requestFrom?.firstUserId);
    if (requestFrom && requestFrom?.length > 0) {
      for (let i = 0; i < requestFrom?.length; i++) {
        bodyFormData.append(`SMovieId[${i}]`, requestFrom[i]?.movieId);
      }
    } else {
      bodyFormData.append("SMovieId", requestFrom?.movieId);
    }

    if (requestFrom && requestFrom?.length > 0) {
      for (let i = 0; i < requestFrom?.length; i++) {
        bodyFormData.append(`SCategoryId[${i}]`, requestFrom[i]?.categoryId);
      }
    } else {
      bodyFormData.append("SCategoryId", requestFrom?.categoryId);
    }
     try {
      const response =  await rejecttradeRequest(bodyFormData);
      if (response?.status === 200) {
        swal({
          title: "",
          text: traderequestrejectMsg,
          icon: "success",
          buttons: "Ok",
          closeOnClickOutside:false,
          dangerMode: false,
        }).then((value) => {
          if(value){
            setShowModalTrading(false);
          
            notificationGetcall(Cookies.get("id"))
                    }})
      } 
    } 
    catch (error) {
      if (error.response.data?.message!=undefined) {
        swal({
          title: "",
          text: error.response.data.message,
          icon: "",
          buttons: "ok",
          closeOnClickOutside:false,
          dangerMode: false
        }).then((value) => {
          if(value){
            setShowModalTrading(false);
          }})
              }         
              else {
                return;
              }
    }
  }
  const openModalMdetails = (movieId) => {
    dispatch(openModalmdetails(true)); 
    dispatch(setTrademoviedetailsforid(movieId));
 
};
  const firstpid=Cookies.get("id");
  const secondpid=Cookies.get("secondpid");
  const Leagueidforcounter=Cookies.get("League_idforcounter");


  const counterTrade = async() => {
    if(ftrade?.FMovieId?.length<=0 || strade?.SMovieId?.length<=0){
      swal({
        title: "",
        text: CounteroffervalidationMsg,
        icon: "error",
        buttons: "ok",
        closeOnClickOutside:false,
        dangerMode: false
      })
    }else if(ftrade?.FMovieId?.length>3 || strade?.SMovieId?.length>3){
      swal({
        title: "",
        text: CountermoviesvalidationMsg,
        icon: "error",
        buttons: "ok",
        closeOnClickOutside:false,
        dangerMode: false
      }).then(() => {
       setFtrade({
        FirstUserId :"",
          FMovieId :[],
          FCategoryId :[],
          FMovieName :"",
        })
       setStrade({
          SecondUserId  :"",
          SMovieId  :[],
          SCategoryId  :[],
          SMovieName  :"",
        })
        const updatedMoviesArray = counterofferloginpmovie?.map((movie) => ({
          ...movie,
          checked: false,
      }));
      const updatedMoviesforfinals = updatedMoviesArray?.filter(movie => movie?.finalScore === 0 );
      
        setcounterMoviesList(updatedMoviesforfinals);
        const updatedMoviesArrayy = counteroffersecondpmovie?.map((movie) => ({
          ...movie,
          checked: false,
      }));
        const secondpupdatedMoviesforfinals = updatedMoviesArrayy?.filter(movie => movie?.finalScore === 0 );
        setcounterMoviesListt(secondpupdatedMoviesforfinals);
        })
    }else{
    var bodyFormData = new FormData();
    bodyFormData.append("LeagueId", Leagueidforcounter);
    bodyFormData.append("FirstUserId", ftrade?.FirstUserId);
     if ( tradeRequestFor&& tradeRequestFor?.length > 0) {
      for (let i = 0; i < tradeRequestFor?.length; i++) {
        bodyFormData.append(`FMovieId[${i}]`, tradeRequestFor[i]?.movieId);
      }
    } else {
      bodyFormData.append("FMovieId",tradeRequestFor?.movieId);
    }
    if (tradeRequestFor && tradeRequestFor?.length > 0) {
      for (let i = 0; i < tradeRequestFor?.length; i++) {
        bodyFormData.append(`FCategoryId[${i}]`, tradeRequestFor[i]?.categoryId);
      }
    } else {
      bodyFormData.append("FCategoryId",tradeRequestFor?.categoryId);
    }

    bodyFormData.append("SecondUserId", strade?.SecondUserId);
    if (strade?.SMovieId && strade?.SMovieId?.length > 0) {
      for (let i = 0; i < strade?.SMovieId?.length; i++) {
        bodyFormData.append(`SMovieId[${i}]`, strade?.SMovieId[i]);
      }
    } else {
      bodyFormData.append("SMovieId", strade?.SMovieId);
    }
    if (strade?.SCategoryId && strade?.SCategoryId?.length > 0) {
      for (let i = 0; i < strade?.SCategoryId?.length; i++) {
        bodyFormData.append(`SCategoryId[${i}]`, strade?.SCategoryId[i]);
      }
    } else {
      bodyFormData.append("SCategoryId", strade?.SCategoryId);
    }
    if (ftrade?.FMovieId && ftrade?.FMovieId?.length > 0) {
      for (let i = 0; i < ftrade?.FMovieId?.length; i++) {
        bodyFormData.append(`FSMovieId[${i}]`, ftrade?.FMovieId[i]);
      }
    } else {
      bodyFormData.append("FSMovieId", ftrade?.FMovieId);
    }
    if (ftrade?.FCategoryId && ftrade?.FCategoryId?.length > 0) {
      for (let i = 0; i < ftrade?.FCategoryId?.length; i++) {
        bodyFormData.append(`FSCategoryId[${i}]`, ftrade?.FCategoryId[i]);
      }
    } else {
      bodyFormData.append("FSCategoryId", ftrade?.FCategoryId);
    }
     try {
      const response =  await counteroffertradeRequest(bodyFormData);
      if (response?.status === 200) {
        swal({
          title: successfullyTitle,
          text: counterofferMsg,
          icon: "success",
          buttons: "Ok",
          closeOnClickOutside:false,
          dangerMode: false,
        }).then((value) => {
          if(value){
            setShowModalTrading(false);
            Cookies.remove("League_idforcounter");
            Cookies.remove("secondpname");
           
            notificationGetcall(Cookies.get("id"))
          }})
      } 
    } 
    catch (error) {
      if (error.response.data?.message!=undefined) {
                swal({
                  title: "",
                  text: error.response.data.message,
                  icon: "error",
                  buttons: "ok",
                  closeOnClickOutside:false,
                  dangerMode: false
                })
              }         
             else {
                swal({
                  title:APIerrorTitle,
                  text: apiErrorMsg,
                  icon: "error",
                  buttons: "ok",
                  closeOnClickOutside:false,
                  dangerMode: false
                })
              }
    }
  }
  }
  const handleIconClick = (movieid) => {
    let tempmovielist = [...countermovieslist]

    let updatedData = tempmovielist?.map((each)=>{
      if(each.movieId==movieid){

        setFtrade({ FirstUserId :firstpid,FMovieId:[...ftrade?.FMovieId,each?.movieId],FCategoryId:[...ftrade?.FCategoryId,each?.categoryId],FMovieName:each?.movieName})
        return {...each,checked:!each?.checked}
      }
   
      return {...each,checked:each?.checked}
  })
    setcounterMoviesList(updatedData);
  };

  const handleIconClickk = (movieId) => {


    let tempmovielist = [...countermovieslistt]

    let updatedData = tempmovielist?.map((each)=>{
      if(each?.movieId==movieId){
        setStrade ({
                  SecondUserId:secondpid,
                  SMovieId  :[...strade?.SMovieId,each?.movieId],
                  SCategoryId  :[...strade?.SCategoryId,each?.categoryId],
                  SMovieName  :each?.movieName,
                });
        return {...each,checked:!each?.checked}
      }
      return {...each,checked:each?.checked}
  })

    setcounterMoviesListt(updatedData);
  };
  


      useEffect(() => {
        setRequestForm(trademovies?.requestFrom?.fMovieDetails);
        setTradeRequestFor(trademovies?.tradeRequestFor?.sMovieDetails);
          }, [trademovies?.tradeRequestFor,trademovies?.requestFrom]);

  return (
    (togglestate?
    <div>
  <div className="row">
    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div className="heading_live_listt"><h6>My Movies List</h6></div>
              <div className="position_card">
  <div className="table_containerr_trade scrollbarcstm"> 
    <table className="playermovie_list">
  <thead>
    <tr className="trade_movieslist_headings">
      <td >Movie</td>
      <td >Category</td>
      <td >Score</td>
      <td >Actions</td>
    </tr>
  </thead>
  <tbody >
{tradeRequestFor?.map((data, index) => (
  <tr className="Player_title" key={index}>
    
          <td>
          
        
            <div className='d-flex gap-2'>
            <span className='moviesnamefordetails' onClick={()=>openModalMdetails(data?.movieId)}>
              {data?.movieName?.length > 15 ? data?.movieName?.substring(0, 15) + '...' : data?.movieName}
              </span>
            </div>
        
          </td>
          <td>
         {data?.categoryName}
          </td>
          <td>
          {data.finalScore == null || undefined ? 0 : data.finalScore}
          </td>
          <td className="campareicons">
          <input type="checkbox" checked />
          </td>
  </tr>
))}
  
  </tbody>
</table>
            </div>
          </div>

    </div>
    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div className="heading_live_listt">
        <h6> 
        {playername?.charAt(0).toUpperCase()+ playername?.slice(1)}'s Movies List</h6>
      </div>
          
        <div className="position_card">
  <div className="table_containerr_trade scrollbarcstm"> 
    <table className="playermovie_list">
  <thead  >
    <tr className="trade_movieslist_headings">
      <td >Movie</td>
      <td >Category</td>
      <td >Score</td>
      <td >Actions</td>
    </tr>
  </thead>
  <tbody >
  {requestFrom?.map((data,index)=>(
  <tr className="Player_title"  key={index}>
          <td>
             <div className='d-flex gap-2' >
             <span className='moviesnamefordetails' onClick={()=>openModalMdetails(data?.movieId)}>
             {data?.movieName?.length > 15 ?data?.movieName?.substring(0, 15) + '...' :data?.movieName}
             </span>
           
             </div>
             </td>   
      <td>
        {data?.categoryName}
        
        </td>
      <td>{trademovies?.requestFrom?.finalScore==undefined||null?0:trademovies?.requestFrom?.finalScore}</td>
      <td className="campareicons">
     
      <input type="checkbox"  checked/>
      </td>
    </tr> ))}
       
  </tbody>
</table>
            </div>
          </div>
    </div>
  </div>
  <div className='trading1_btns'>
  <div className='Trade_modal_btn' onClick={rejectTrade}>
  <button className="ttrade-btns-reject"><YourIcon4 className="trade_icon" /> Reject</button>
  </div>
  <div className='Trade_modal_btn' onClick={acceptTrade}>
  <button className="ttrade-btns-accept"><YourIcon5  className="trade_icon"/> Accept</button>
  </div>
  <div className='Trade_modal_btn' onClick={onsubmitCounter}>
  <button className="ttrade-btns"><YourIcon6  className="trade_icon"/> Counter Offer</button>
  </div>
</div>
</div>
 :
 
 <div>
 <div className="row">
   <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
     <div className="heading_live_listt"><h6>My Movies List</h6></div>
           <div className="position_card">
 <div className="table_containerr_trade scrollbarcstm"> 
   <table className="playermovie_list">
 <thead  >
   <tr className="trade_movieslist_headings">
     <td >Movie</td>
     <td >Category</td>
     <td >Score</td>
     <td >Actions</td>
   </tr>
 </thead>
 <tbody >

 {
 countermovieslist?.length!=0?
 countermovieslist?.map((data,index)=>(
   <tr className="Player_title" key={index} >
         <td>
            <div className='d-flex gap-2'>
           
            <span className='moviesnamefordetails' onClick={()=>openModalMdetails(data?.movieId)}>{data?.movieName?.length > 15 ? data?.movieName.substring(0, 15) + '...' : data?.movieName}</span>
            </div>
            </td>   
     <td>{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>
     <td>{data?.finalScore}</td>
   
     <td className="campareicons checkcounter">
     <input type="checkbox" className='form-check-input' checked={data?.checked}  onClick={() => handleIconClick(data?.movieId)}/>
       </td>
   </tr>
   )):<div className='no_dropm_found'>No movies found</div>
   } 
      
 </tbody>
</table>
           </div>
         </div>
   </div>
   <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
     <div className="heading_live_listt">
       <h6>
       {Cookies.get("secondpname")?.charAt(0).toUpperCase()+ Cookies.get("secondpname")?.slice(1)}'s Movies List</h6>
     </div>
           <div className="position_card">
 <div className="table_containerr_trade scrollbarcstm"> 
   <table className="playermovie_list">
 <thead  >
   <tr className="trade_movieslist_headings">
     <td >Movie</td>
     <td >Category</td>
     <td >Score</td>
     <td >Actions</td>
   </tr>
 </thead>
 <tbody >
   {countermovieslistt?.length!=0?
 countermovieslistt?.map((data,index)=>(
   <tr className="Player_title" key={index} >
         <td >
         <div className='d-flex gap-2'>
        
            <span className='moviesnamefordetails'onClick={()=>openModalMdetails(data?.movieId)}>{data?.movieName?.length > 15 ? data?.movieName.substring(0, 15) + '...' : data?.movieName}</span>
            </div>
         </td>
     <td >{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>
     <td >{data?.finalScore}</td>
     <td className="campareicons checkcounter">
     
      <input type="checkbox" className='form-check-input'  checked={data?.checked}  onChange={()=>handleIconClickk(data?.movieId)} />
      </td>
   </tr>
   )):<div className='no_dropm_found'>No movies found</div>
   } 
 </tbody>
</table>
           </div>
         </div>
   </div>
 </div>
 <div className='Trade_modal_btn' onClick={counterTrade}>
  <button className="ttrade-btns"><YourIcon6  className="trade_icon"/> Counter Offer</button>
  </div>
</div>)
  )
}

export default Trading1