import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import logo from "../../assets/Gaffer Logo Transparent2-03-ai (2).png";
import imdblogo from "../../assets/IMDB_Logo_2016.png";
import { ReactComponent as YourIcon3 } from "../../assets/Vector (3).svg";
import { ReactComponent as YourIcon } from "../../assets/twitter_new.svg";
import { ReactComponent as YourIcon1 } from "../../assets/Vector (2).svg";
import { ReactComponent as YourIcontiktok } from "../../assets/TIKtok.svg";
import { useLocation } from 'react-router-dom';
export const Footer = () => {
  const d = new Date();
  const cYear=d.getFullYear();
  const location = useLocation();
  const isActiveformovies = location.pathname === '/Movieslist' || location.pathname.startsWith('/Moviedetails/');
  const isActivefornews = location.pathname === '/Newslisting' || location.pathname.startsWith('/Newsdetails/');
  return (
    <div className="main_footer">
      <div className="containers con_div">
        <div className="footer_container">
          <div className="row footer_row">
            <div className="col-lg-2 footer_column col-md-6 mt-lg-0 mt-4">
              <p className="footer_heading">
                <img src={logo} alt="img" /> Gaffer<sup className="Trademark">TM</sup>
              </p>
              <p className="footer_para">Email us : gafferllc@gmail.com</p>
             
            </div >
            <div className="col-lg-2 footer_column col-md-6 mt-lg-0 mt-4">
              <p className="footer_heading" >Quick Links</p >
              <Link  to="/" className={`footer_a ${location.pathname  === '/' ? 'is_activefooterlink' : ''}`}  exact onClick={() => window.scrollTo(0, 0)}>Home</Link>
              <Link  to="/Howitworks"  className={`footer_a ${location.pathname  === '/Howitworks' ? 'is_activefooterlink' : ''}`} exact onClick={() => window.scrollTo(0, 0)}>How it works</Link>      
              <Link to="/Aboutus" className={`footer_a ${location.pathname  === '/Aboutus' ? 'is_activefooterlink' : ''}`}exact onClick={() => window.scrollTo(0, 0)}>About Us </Link>
            </div >
            <div className="col-lg-2 footer_column col-md-6 mt-lg-0 mt-4" >
              <p className="footer_heading">Quick Links</p >
              <Link to="/Movieslist"  className={`footer_a ${isActiveformovies? 'is_activefooterlink' : ''}`} onClick={() => window.scrollTo(0, 0)}>Movies</Link>
              <Link to="/Contactuspage"  className={`footer_a ${location.pathname  === '/Contactuspage' ? 'is_activefooterlink' : ''}`}exact onClick={() => window.scrollTo(0, 0)}>Contact Us</Link>

            </div >
            <div className="col-lg-2 footer_column col-md-6 mt-lg-0 mt-4">
              <p className="footer_heading">Quick Links</p>
              <Link  to="/Newslisting"  className={`footer_a ${isActivefornews? 'is_activefooterlink' : ''}`} onClick={() => window.scrollTo(0, 0)}>News</Link>
              <Link  to="/Privacypolicy" className={`footer_a ${location.pathname  === '/Privacypolicy' ? 'is_activefooterlink' : ''}`}exact onClick={() => window.scrollTo(0, 0)}>Privacy Policy </Link>
            </div >
            <div className="col-lg-2 footer_column col-md-6 mt-lg-0 mt-4">
              <p className="footer_heading">Powered by {" "}<a target="_blank" href="https://www.imdb.com"><img src={imdblogo} alt="img" className="imdblogo" /></a>  </p >
             
               <p className="footer_heading">Follow Us</p >
              <div>
                <a className ="footer_iconlink" href="https://www.facebook.com/profile.php?id=61562846707767" target="_blank">
                  <YourIcon3 />
                </a>{" "}
                <a className ="footer_iconlink" href="https://x.com/Gaffer_llc" target="_blank">
                  <YourIcon />
                </a>{" "}
                {""}
                <a className ="footer_iconlink" href="https://www.instagram.com/gaffer_llc/" target="_blank">
                  <YourIcon1 />
                </a>   {""}
                {""}
                <a className ="footer_iconlink" href="https://www.tiktok.com/@roughcut_pod?_t=8omznqoW4yc&_r=1" target="_blank">
                  <YourIcontiktok  />
                </a>
                {""}
              </div>
            </div >
          </div>
          <hr className="footer-hr" />
          <div className="footer_lowerdiv">
            <span className="footer_lowerspan">
              <div>Copyright © {cYear}, Gaffer<sup className="Trademark">TM</sup> . All Right Reserved</div>
            </span>
          </div >
        </div>
      </div>
    </div>
  );
};
