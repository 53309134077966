import React,{useEffect, useState} from 'react'
import  "./Trading.css";
import Tradebtn from "./Tradebtn";
import swal from 'sweetalert';
import { useSelector } from "react-redux";

import {trademoviePost} from "../../../../../api/handleapi";
import {  useDispatch } from 'react-redux';
import {  closeModal } from '../../../../../feature/showSlice/showmodaltradeSlice';
import Cookies from 'js-cookie';
import { openModalmdetails } from '../../../../../feature/showSlice/showmodaltrademoviedetailsSlice';
import {setTrademoviedetailsforid} from '../../../../../feature/showSlice/trademoviedetailsforidSlice';
import { useCategory } from '../../../../../components/useCategory';
import { apiErrorMsg, APIerrorTitle,successfullyTitle, trademoviesvalidationMsg, traderequestsent, tradevalidationMsg } from '../../../../../components/language/Language';



function Trading({Leagueid}) {
  const dispatch = useDispatch();
  const firstpid=Cookies.get("id");
  const secondpid=Cookies.get("secondpid");
  const [movieslist,setMoviesList] = useState([]);
  const [movieslistt,setMoviesListt] = useState([]);
  const [recall,setRecall]=useState(false);
  const [ftrade,setFtrade]=useState({
    FirstUserId :"",
    FMovieId :[],
    FCategoryId :[],
    FMovieName :"",
  })

  const openModalMdetails = (movieId) => {
      dispatch(openModalmdetails(true)); 
      dispatch(setTrademoviedetailsforid(movieId));
   
};
const Categoryoptions=useCategory();
  const [strade,setStrade]=useState({
    SecondUserId  :"",
    SMovieId  :[],
    SCategoryId  :[],
    SMovieName  :"",
  })

  const secondpmovie=useSelector((state) => state.secondpmovie);
  const loginpmovie=useSelector((state) => state.loginpmovie);

  useEffect(() => {
  const updatedMoviesArray = loginpmovie?.map((movie) => ({
    ...movie,
    checked: false,
}));
  const updatedMoviesforfinals = updatedMoviesArray?.filter(movie => movie.finalScore === 0 );
  setMoviesList(updatedMoviesforfinals);
  const updatedMoviesArrayy = secondpmovie?.map((movie) => ({
    ...movie,
    checked: false,
}));
  const secondpupdatedMoviesforfinals = updatedMoviesArrayy?.filter(movie => movie.finalScore === 0);
  setMoviesListt(secondpupdatedMoviesforfinals);
  },[recall]);

  const handleIconClick = (movieId) => {
    let tempMovielist = [...movieslist];
    let updatedData = tempMovielist?.map((each) => {
      if (each?.movieId == movieId) {
           
          setFtrade({ ...ftrade,  FirstUserId :firstpid,FMovieId: [...ftrade?.FMovieId, movieId],FCategoryId: [...ftrade?.FCategoryId, each?.categoryId],FMovieName:each?.movieName });
        
        return { ...each, checked: !each.checked };
      }
      return{ ...each, checked: each.checked };
    });
    setMoviesList(updatedData);
  };

  const handleIconClickk = (movieId,catid,mname) => {
    let temp_movielist = [...movieslistt]
    let updatedData = temp_movielist?.map((each)=>{
      if(each?.movieId==movieId){
              setStrade({ ...strade,SecondUserId:secondpid,SMovieId: [...strade?.SMovieId, movieId],SCategoryId: [...strade?.SCategoryId, each?.categoryId], SMovieName  :each?.movieName });
        return {...each,checked:!each.checked}
      }
      return {...each,checked:each.checked}
  })
    setMoviesListt(updatedData);
  };
 
  const tradeMovie = async() => {
      if(ftrade?.FMovieId?.length<=0 || strade?.SMovieId?.length<=0){
      swal({
        title: "",
        text: tradevalidationMsg,
        icon: "error",
        buttons: "ok",
        closeOnClickOutside:false,
        dangerMode: false
      })
    }else if(ftrade?.FMovieId?.length>3 || strade?.SMovieId?.length>3){
      swal({
        title: "",
        text: trademoviesvalidationMsg,
        icon: "error",
        buttons: "ok",
        closeOnClickOutside:false,
        dangerMode: false
      }).then(() => {
        setRecall(true)
       setFtrade({
        FirstUserId :"",
          FMovieId :[],
          FCategoryId :[],
          FMovieName :"",
        })
       setStrade({
          SecondUserId  :"",
          SMovieId  :[],
          SCategoryId  :[],
          SMovieName  :"",
        })
        
        })
    }else{
      var bodyFormData = new FormData();
      bodyFormData.append("LeagueId",Leagueid );
      bodyFormData.append("FirstUserId", ftrade?.FirstUserId);
      if (ftrade?.FMovieId && ftrade?.FMovieId?.length > 0) {
        for (let i = 0; i < ftrade?.FMovieId?.length; i++) {
          bodyFormData.append(`FMovieId[${i}]`, ftrade?.FMovieId[i]);
        }
      }else {
        bodyFormData.append("FMovieId", ftrade?.FMovieId);
      }
      if (ftrade?.FCategoryId && ftrade?.FCategoryId?.length > 0) {
        for (let i = 0; i < ftrade?.FCategoryId?.length; i++) {
          bodyFormData.append(`FCategoryId[${i}]`, ftrade?.FCategoryId[i]);
        }
      } else {
        bodyFormData.append("FCategoryId", ftrade?.FCategoryId);
      }
      bodyFormData.append("SecondUserId", strade?.SecondUserId);
      if (strade?.SMovieId && strade?.SMovieId?.length > 0) {
        for (let i = 0; i < strade?.SMovieId?.length; i++) {
          bodyFormData.append(`SMovieId[${i}]`, strade?.SMovieId[i]);
        }
      } else {
        bodyFormData.append("SMovieId", strade?.SMovieId);
      }
      if (strade?.SCategoryId && strade?.SCategoryId?.length > 0) {
        for (let i = 0; i < strade?.SCategoryId?.length; i++) {
          bodyFormData.append(`SCategoryId[${i}]`, strade?.SCategoryId[i]);
        }
      } else {
        bodyFormData.append("SCategoryId", strade?.SCategoryId);
      }
       try {
        const response =  await trademoviePost(bodyFormData);
        if (response?.status === 200) {
          swal({
            title: successfullyTitle,
            text: traderequestsent,
            icon: "success",
            closeOnClickOutside:false,
            buttons: "ok",
            dangerMode: false
          }).then((value) => {
            if(value){
              dispatch(closeModal(false));
            }})
        } 
      } 
      catch (error) {
        if (error.response.data?.message!=undefined) {
                  swal({
                    title: "",
                    text: error.response.data.message,
                    icon: "error",
                    buttons: "ok",
                    closeOnClickOutside:false,
                    dangerMode: false
                  })
                }         
                else if (error.response.data?.message===undefined) {
                  swal({
                      title: APIerrorTitle,
                      text: apiErrorMsg,
                      icon: "error",
                      buttons: "ok",
                      closeOnClickOutside:false,
                      dangerMode: false
                    })
                }else {
                  return;
                }
      }
    }
 
  }
  
  

  return (
    <div>
  <div className="row">
    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div className="heading_live_listt"><h6>My Movies List</h6></div>
            <div className="position_card">
  <div className="table_containerr_trade scrollbarcstm"> 
    <table className="playermovie_list">
  <thead  >
    <tr className="trade_movieslist_headings">
      <td >Movie</td>
      <td >Category</td>
      <td >Score</td>
      <td >Actions</td>
    </tr>
  </thead>
  <tbody >

  {
  movieslist?.length!=0?
  movieslist?.map((data,index)=>(
    <tr className="Player_title" key={index} >
          <td>
             <div className='d-flex gap-2'>
             <span className='moviesnamefordetails' onClick={()=>openModalMdetails(data?.movieId)}>{data?.movieName?.length > 15 ? data?.movieName.substring(0, 15) + '...' : data?.movieName}</span>
             </div>
             </td>   
      <td>{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>
      <td>{data?.finalScore}</td>
      <td className="campareicons">
      
              <input type="checkbox" checked={data?.checked} onClick={() => handleIconClick(data?.movieId)}/>
        </td>
    </tr>
    )):<div className='no_dropm_found'>No movies found</div>
    } 
  </tbody>
</table>
            </div>
          </div>
    </div>
    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div className="heading_live_listt">
        <h6> 
          
            {Cookies.get("secondpusername")?.charAt(0).toUpperCase()+ Cookies.get("secondpusername")?.slice(1)}'s Movies List</h6>
      </div>
            <div className="position_card">
  <div className="table_containerr_trade scrollbarcstm"> 
    <table className="playermovie_list">
  <thead>
    <tr className="trade_movieslist_headings">
      <td>Movie</td>
      <td>Category</td>
      <td>Score</td>
      <td>Action</td>
    </tr>
  </thead>
  <tbody >
    {movieslistt?.length!=0?
  movieslistt?.map((data,index)=>(
    <tr className="Player_title" key={index} >
          <td >
          <div className='d-flex gap-2'>
             
            
             <span className='moviesnamefordetails'onClick={()=>openModalMdetails(data?.movieId)}>{data?.movieName?.length > 15 ? data?.movieName.substring(0, 15) + '...' : data?.movieName}</span>
             </div>
          </td>
      <td >{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>
      <td >{data?.finalScore}</td>
    <td className="campareicons">
       <input type="checkbox"  checked={data?.checked}   onChange={()=>handleIconClickk(data?.movieId,data?.categoryId,data?.movieName)} />
      
      </td> 

     
    </tr>
    )):<div className='no_dropm_found'>No movies found</div>
    } 
  </tbody>
</table>
            </div>
          </div>
    </div>
  </div>
  <div className='Trade_modal_btn' onClick={tradeMovie}>
    <Tradebtn Trade={"Trade"}/>
  </div>
</div>

  )
}

export default Trading