import React,{useState,useEffect}from "react";
import Navbarr from "../../components/header/Navbarr";
import { Footer } from "../../components/footer/footer";
import ScrollButton from "../../components/scrollbutton/ScrollButton";
import firstitem from "../../assets/dummy-movie.png";
import "./Movieslist.css";
import LoadMore from "../../components/loadmore/LoadMore";
import { Succesmsg } from "../../components/tostifymsg/Succesmsg";
import { Errormsg } from "../../components/tostifymsg/Errrormsg";
import { movieaddWatchlistost } from "../../api/handleapi";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch ,useSelector} from 'react-redux';
import { openModal } from '../../feature/showSlice/showModalSlice';
import {movielistGet,moviefilterPost} from "../../api/handleapi"
import Searchmovies from "../../components/searchmovies/Searchmovies";
import { Link } from "react-router-dom";
import { setWatchlistcount } from '../../feature/showSlice/watchlistcountSlice';
import Cookies from 'js-cookie';
import { useCategory } from "../../components/useCategory";
import { movieposterpath, reservedCategoryids } from "../../Config/config";
import { addedtoWatchlist, loginfirslyMsg } from "../../components/language/Language";
import Googleads from "../../components/google-AdSense/Googleads";
import Googleadshorizontal from "../../components/google-AdSense/Googleadshorizontal";



function Movieslist() {
const [categoryvisibleItems, setcategoryVisibleItems] = useState(6);
const watchlistcount = useSelector((state) => state.watchlistcount);
const [checkedValue, setCheckedValue] = useState(null);
const [yearcheckedValue, setyearCheckedValue] = useState(null);
const [checkedValueproductions, setCheckedValueproductions] = useState(null);
const [visibleItems, setVisibleItems] = useState(12);
const [allItems, setAllItems] = useState([]);
const [showload, setShowload] = useState(true);
const [loadmore, setLoadmore] = useState(true);
const [sideNavCollapsed, setSideNavCollapsed] = useState(false);
const [hiddenButtons, setHiddenButtons] = useState({});

const dateGet = new Date();
const cYear=dateGet.getFullYear();
const dispatch = useDispatch();
const handleOpen = () => {
  dispatch(openModal());
};




const Categoryoptions=useCategory();
const [loading, setLoading] = useState(true);
 const [mfilter, setMfilter] = useState({
    ReleaseYear:"",
    SelectedCategoryIds:[],
    ReleaseType:"",
    ProdStatus:"",
  })

  const createPost = async(movieId) => {
    var bodyFormData = new FormData();
    bodyFormData.append("movieId", movieId);
    bodyFormData.append("userId",Cookies.get("id"));
     try {
      const response =  await movieaddWatchlistost(bodyFormData);
      if (response?.status === 200) { 
        setHiddenButtons(prevState => ({ ...prevState, [movieId]: true }));    
         Succesmsg(addedtoWatchlist);
         dispatch(setWatchlistcount(watchlistcount+1));
      } 
    } 
    catch (error) {
      if (error.response) {
                if (error.response.data.message) {
                  // Show an alert with the message
                  Errormsg(error?.response?.data?.message);
              }             
                  await handleOpen();
                  await Cookies.set("navivalue", "/Movieslist");           
                  await Errormsg(loginfirslyMsg);
                // The request was made and the server responded with a status code
               
              }         
             
    }
  };



  const moviesFilter = async (updatedFilter) => {
    var bodyFormData = new FormData();
    if (updatedFilter?.ReleaseYear && updatedFilter?.ReleaseYear !== "") {
      bodyFormData.append("ReleaseYear", updatedFilter?.ReleaseYear);
    }
    if (updatedFilter?.SelectedCategoryIds && updatedFilter?.SelectedCategoryIds?.length > 0) {
      for (let i = 0; i < updatedFilter?.SelectedCategoryIds?.length; i++) {
        bodyFormData.append(`SelectedCategoryIds[${i}]`, updatedFilter?.SelectedCategoryIds[i]);
      }
    }
    if (updatedFilter?.ReleaseType && updatedFilter?.ReleaseType !== "") {
      bodyFormData.append("ReleaseType", updatedFilter?.ReleaseType);
    }
    if (updatedFilter?.ProdStatus && updatedFilter?.ProdStatus !== "") {
      bodyFormData.append("ProdStatus", updatedFilter?.ProdStatus);
    }
    try {
      const response = await moviefilterPost(bodyFormData);
        if (response?.status === 200) {
        setAllItems(response?.data?.data);
      } 
    } catch (error) {
      if (error.response) {
        setMfilter( {
                ReleaseYear:"",
                SelectedCategoryIds:[],
                ReleaseType:"",
                ProdStatus:"",
               });
             
              // The request was made and the server responded with a status code
            } 
    }
  };


  const movielistFun = async () => {
    try {
      const response = await movielistGet(); // You might want to pass some parameters to Dashboardget
      setAllItems(response?.data);
      setLoading(false);
    } catch (error) {
      return;
    }
  };

useEffect(() => {
  movielistFun();
    }, []);




const handleLoadMore = () => {
  const nextVisibleItems = Math.min(visibleItems + 6, allItems?.length);
  setVisibleItems(nextVisibleItems);
  if (nextVisibleItems == allItems?.length) {
    setLoadmore(false)
  }
 
};

const allmovies=allItems?.length
const handleshowMore = () => {
  const nextVisibleItems = Math.min(categoryvisibleItems + 5, Categoryoptions?.length);
  setcategoryVisibleItems(nextVisibleItems);
   
    if (nextVisibleItems == Categoryoptions?.length) {
      setShowload(false)
    }
};

const [inputText, setInputText] = useState("");

let inputHandler = (e) => {
  var trimmedText = e.target.value.trim();
  // Convert trimmed input text to lowercase
  var lowerCase = trimmedText.toLowerCase();
  setInputText(lowerCase);
};

const filteredData = allItems?.filter((el) => {
  const nameMatch = el.name.toLowerCase().includes(inputText);
  if (inputText !== '') {
    // Filter by movie name only
    return nameMatch;

  } else {
    // If input is empty, return all movies
    return true;
  }
});


const sliderOpen=()=>{
  setSideNavCollapsed(!sideNavCollapsed);
}
const sortByAlpha = (order) => {
  const sortedData = [...allItems].sort((a, b) => {
    const aName = a.name.replace(/^[\s0-9]+/g, '');
    const bName = b.name.replace(/^[\s0-9]+/g, '');
    if (order === "asc") {
      return aName.localeCompare(bName, "en", { sensitivity: "case" });
    } else {
      return bName.localeCompare(aName, "en", { sensitivity: "case" });
    }
  });
  setAllItems(sortedData);
}

const sortByDate=(order)=> {
  const sortedData = [...allItems].sort((a, b) => {
    
    if (order === "old") {
      return !a.releaseYear || !b.releaseYear ? 1 : a.releaseYear - b.releaseYear;
    } else {
      return b.releaseYear - a.releaseYear;
    }
  });
  setAllItems(sortedData);
}



useEffect(() => {
  if (sideNavCollapsed) {
    document.body.classList.add('overflow_h');
  } else {
    document.body.classList.remove('overflow_h');
  }
}, [sideNavCollapsed]);



  return (
    <div >
      <Navbarr/>
      <div id={sideNavCollapsed ? "overlay" : ''}>
      </div>
      <ToastContainer />
      <div className="movielist ">
        <div className="con_div ">
          <div  className= 'navbar items_navbar' >
            <div className="textbody">
              <h5 className="card-title mheading">
                Find movies you are interested in for your team
              </h5>
              <p className="card-text ">
                Filter by release type, category, release year, or production status
              </p>
            </div>
            <div className="google-adsmovielist">
              
              <Googleadshorizontal props="mt-2 mb-2 text-center"/>
            </div>
            <div className="search_box"> 
            <Searchmovies inputHandler={inputHandler}/>
            </div>
            <button className="filter-list-show filter-btn"  onClick={() => sliderOpen()}>
            <i className="fa-solid fa-filter"></i> 
            Filter
            </button> 
          </div>
          <div className="filterse">
          <hr className="after_nav_hr" />
            <div className=" custom-container">
              <div className="row">
                <div className={sideNavCollapsed ? 'firstchildclass col-xl-2 col-lg-2 col-md-6 col-sm-12 filter-show' : 'firstchildclass col-xl-2 col-lg-2 col-md-6 col-sm-12'}>
                  <div className="d-flex justify-content-between">  
                    
                    <h6>Filters</h6>
                    <div className="text-end apply-btn-div"> 
                    <button  className="apply_btn" onClick={() => sliderOpen()}>Apply</button>
                    </div>
                  
                  </div>
                  <hr/>
                <div>
                <h6>RELEASE TYPE</h6>
                  <div className="form-check">
                    
                    <label className="form-check-label" >
                      Coming Soon
                      <input
                      className="form-check-input fcim"
                      type="checkbox"
                      value={0}
                      id="0"
                      checked={checkedValue == 0}
                       onChange={(e) => {
                        const updatedFilter = { ...mfilter, ReleaseType:  e.target.checked===true?e.target.value:"" };
                        setMfilter(updatedFilter);
                        moviesFilter(updatedFilter);
                        if (e.target.checked) {
                          setCheckedValue(0);
                        } else {
                          setCheckedValue(null);
                        }
                      }}
                    />
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-labeel" >
                      In Theaters
                      <input
                      className="form-check-input fcim"
                      type="checkbox"
                      value={1}
                      id="1"
                      checked={checkedValue == 1}
                      onChange={(e) => {
                        const updatedFilter = { ...mfilter, ReleaseType:  e.target.checked===true?e.target.value:"" };
                        setMfilter(updatedFilter);
                        moviesFilter(updatedFilter);
                        if (e.target.checked) {
                          setCheckedValue(1);
                        } else {
                          setCheckedValue(null);
                        }
                      }}
                    />
                    </label>
                  </div>
                  <hr />
                </div>
                 <div>
                 <h6>CATEGORY</h6>  
                 {Categoryoptions?.length >0 ?                               
  (Categoryoptions?.sort((a, b) => a.category.localeCompare(b.category)).slice(0, categoryvisibleItems).filter((item) => !reservedCategoryids?.includes(item.id.toString())).map((item, index) => (
                  
                  <div className="form-check" key={index}>
                    <label className="form-check-label" >
                      {item?.category}
                      <input
                      className="form-check-input fcimm"
                      type="checkbox"
                      value={item?.id}
                      id={index}
                      onChange={(e) => {
                        const updatedFilter = { ...mfilter };
                        const index = updatedFilter.SelectedCategoryIds.indexOf(item?.id);
                        if (index !== -1) {
                          // Remove the selected category ID if it's already in the array
                          updatedFilter.SelectedCategoryIds.splice(index, 1);
                        } else {
                          // Add the selected category ID if it's not already in the array
                          updatedFilter.SelectedCategoryIds.push(item?.id);
                        }
                        setMfilter(updatedFilter);
                    
                        if (updatedFilter?.SelectedCategoryIds?.length == 0) {
                       
                          // Show all movies list when no categories are selected
                          moviesFilter({ ...updatedFilter, SelectedCategoryIds: [] });
                          movielistFun();
                        } else {
                          // Call the moviesFilter function with the updated filter
                          moviesFilter(updatedFilter);
                        }
                      }}
                    />
                    </label>
                  </div>
                ))):"Not found"}
                                    {Categoryoptions?.length >0  ?             
                  (showload  ?<span className="showmorecategory d-flex" onClick={handleshowMore}> + Show More </span>:null):null}
                  <hr />
                 </div>
                     <div>
                     <h6>RELEASE YEAR</h6>
                  <div className="form-check">
                    <label className="form-check-label" >
                      {cYear}
                      <input
                      className="form-check-input fcim"
                      type="checkbox"
                      value={2024}
                      id="2024"
                      
                      checked={yearcheckedValue === 2024}
                      onChange={(e) => {
                        const updatedFilter = { ...mfilter, ReleaseYear:  e.target.checked===true?e.target.value:"" };
                        setMfilter(updatedFilter);
                        moviesFilter(updatedFilter);
                        if (e.target.checked) {
                          setyearCheckedValue(2024);
                        } else {
                          setyearCheckedValue(null);
                        }
                      }}
                    />
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-label" >
                    {cYear+1}
                      <input
                      className="form-check-input fcim"
                      type="checkbox"
                      value={2025}
                      id="2025"
                      checked={yearcheckedValue === 2025}
                      onChange={(e) => {
                        const updatedFilter = { ...mfilter, ReleaseYear:  e.target.checked===true?e.target.value:"" };
                        setMfilter(updatedFilter);
                        moviesFilter(updatedFilter);
                        if (e.target.checked) {
                          setyearCheckedValue(2025);
                        } else {
                          setyearCheckedValue(null);
                        }
                      }}
                    />
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-label" >
                    Unconfirmed
                      <input
                      className="form-check-input fcim"
                      type="checkbox"
                      value={0}
                      id="0"
                      checked={yearcheckedValue === 0}
                      onChange={(e) => {
                        const updatedFilter = { ...mfilter, ReleaseYear:  e.target.checked===true?e.target.value:"" };
                        setMfilter(updatedFilter);
                        moviesFilter(updatedFilter);
                        if (e.target.checked) {
                          setyearCheckedValue(0);
                        } else {
                          setyearCheckedValue(null);
                        }
                      }}
                    />
                    </label>
                  </div>
                  <hr />
                  </div>
                  <div>
                <h6>PRODUCTION STATUS</h6>
                  <div className="form-check">
                    
                    <label className="form-check-label" >
                    In Production
                      <input
                      className="form-check-input fcim"
                      type="checkbox"
                      value={"In Production"}
                      id="In Production"
                      checked={checkedValueproductions ==="In Production" }
                      onChange={(e) => {
                        const updatedFilter = { ...mfilter, ProdStatus:  e.target.checked===true?e.target.value:"" };
                        setMfilter(updatedFilter);
                        moviesFilter(updatedFilter);
                        if (e.target.checked) {
                          setCheckedValueproductions("In Production");
                        } else {
                          setCheckedValueproductions(null);
                        }
                      }}
                    />
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-labeel" >
                    Pre-production
                      <input
                      className="form-check-input fcim"
                      type="checkbox"
                      value={"Pre-production"}
                      id="Pre-production"
                      checked={checkedValueproductions === "Pre-production"}
                      onChange={(e) => {
                        const updatedFilter = { ...mfilter, ProdStatus:  e.target.checked===true?e.target.value:"" };
                        setMfilter(updatedFilter);
                        moviesFilter(updatedFilter);
                        if (e.target.checked) {
                          setCheckedValueproductions("Pre-production");
                        } else {
                          setCheckedValueproductions(null);
                        }
                      }}
                    />
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-labeel" >
                    Post-production
                      <input
                      className="form-check-input fcim"
                      type="checkbox"
                      value={"Post-production"}
                      id="Post-production"
                      checked={checkedValueproductions ==="Post-production"}
                      onChange={(e) => {
                        const updatedFilter = { ...mfilter, ProdStatus:  e.target.checked===true?e.target.value:"" };
                        setMfilter(updatedFilter);
                        moviesFilter(updatedFilter);
                        if (e.target.checked) {
                          setCheckedValueproductions("Post-production");
                        } else {
                          setCheckedValueproductions(null);
                        }
                      }}
                    />
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-labeel" >
                    Released
                      <input
                      className="form-check-input fcim"
                      type="checkbox"
                      value={"Released"}
                      id="Released"
                      checked={checkedValueproductions === "Released"}
                       onChange={(e) => {
                        const updatedFilter = { ...mfilter, ProdStatus:  e.target.checked===true?e.target.value:"" };
                        setMfilter(updatedFilter);
                        moviesFilter(updatedFilter);
                        if (e.target.checked) {
                          setCheckedValueproductions("Released");
                        } else {
                          setCheckedValueproductions(null);
                        }
                      }}

                    />
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-labeel" >
                    Completed
                      <input
                      className="form-check-input fcim"
                      type="checkbox"
                      value={"Completed"}
                      id="Completed"
                      checked={checkedValueproductions === "Completed"}
                      onChange={(e) => {
                        const updatedFilter = { ...mfilter, ProdStatus:  e.target.checked===true?e.target.value:"" };
                        setMfilter(updatedFilter);
                        moviesFilter(updatedFilter);
                        if (e.target.checked) {
                          setCheckedValueproductions("Completed");
                        } else {
                          setCheckedValueproductions(null);
                        }
                      }}
                    />
                    </label>
                  </div>
                  <hr />
                </div>
                
                                     
                          
                           <Googleads props="parent_dummyads pt-2 pb-2 text-center"/>
                </div>
              
                <div  className='col-xl-10 col-lg-10 col-md-12 col-sm-12  movielisting-div'>
                    <div className="showre dropdown">
                    <p>Showing all {filteredData?.length} results</p>
                    <div>
                      <button
                        className="dropdown-toggle  sortbybtn"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Sort by
                      </button>
                      <div
                        className="dropdown-menu sortby-dropmenu "
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a className="dropdown-item" href="##" onClick={()=>sortByAlpha("asc")}>
                         Ascending A to Z
                        </a>
                        <a className="dropdown-item" href="##" onClick={()=>sortByAlpha("desc")}>
                         Descending Z to A
                        </a>
                        <a className="dropdown-item" href="##" onClick={()=>sortByDate("old")}>
                        Old to New
                        </a>
                        <a className="dropdown-item" href="##" onClick={()=>sortByDate("new")}>
                        New to Old
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                  {loading ? (
                    <div className="movielistingloading">
            <div className="spinner-border text-primary " role="status">
              <span className="visually-hidden">Loading...</span>
          </div>
          </div>
          ):
                  (filteredData?.length > 0 ? (
                    filteredData?.slice(0, visibleItems).filter((item) =>     
                   item).map((item, index) => (
                  <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6" key={index}>
                    <div  className="movielistcard ">  
                  <div className="carddm moviecard">
                        <div className="cus_iamge cus_iamgecstm">
                   
<Link className="movieheading_ele" to={`/Moviedetails/${item?.id}`}>
  {item?.poster?.includes("https://m.media-amazon.com/images") ? 
    <img src={item?.poster} alt="img" className="w-100 cardimg"  onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem }} /> : 
    <img src={movieposterpath + item?.poster} alt="img" className="w-100 cardimg"  onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem }} />
  }
</Link>
                          </div>                      
                        {!hiddenButtons[item?.id] && <button className="btn mcard-btn card-cstm-btn"  onClick={() => createPost(item?.id)}>Add to Watchlist</button>}
                        <div className="card-body Mcbody movies_cardbody moviebody">
                        <Link  className="movieheading_ele"  to={`/Moviedetails/${item?.id}`}>
  <h5>{item?.name}</h5>
</Link>
              
                       
                          <span className="text-offw"> Release year : {item?.releaseYear!=null||undefined?item?.releaseYear:"N/A"}</span>
                          <div className="mdetails ">
                         
                            <ul className="movie_list_ul">
                           
                              {item?.category && item?.category.split(',').map((categoryId, index) => (
            <span key={index} className="text-offw">
              {Categoryoptions?.find(cat => cat?.id == categoryId)?.category}
              </span>
            ))}
                            </ul>
                          </div>
                        </div>
                        </div>
                      </div>
                      </div>
))
) : (
  <div className="no_league_found">
    <p>No Movies found</p>
  </div>
))

}
                  </div>
{(allmovies > 12 && filteredData?.length > 12) ? (
  <div>
    {loadmore ? (
      <LoadMore onLoadMore={handleLoadMore} />
    ) : null}
  </div>
) : null}
                </div>
              </div>
            </div>
           </div>      
         </div>
      </div>
      <Footer />
      <ScrollButton />
    </div>
  );
}

export default Movieslist;
