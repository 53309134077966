import React,{useState,useEffect}from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import firstitem from "../../assets/dummy-movie.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import "./Trandingmovie.css";
import { Link, NavLink } from "react-router-dom";
import {movieposterpath, settings} from "../../Config/config"

import {  useDispatch ,useSelector} from 'react-redux';
import { openModal } from '../../feature/showSlice/showModalSlice';
import {trendingmovieGet,movieaddWatchlistost} from "../../api/handleapi";
import { setWatchlistcount } from '../../feature/showSlice/watchlistcountSlice';
import Cookies from 'js-cookie';
import { useCategory } from "../../components/useCategory";
import { addedtoWatchlist, loginfirslyMsg } from "../../components/language/Language";

import Googleadshorizontal from "../../components/google-AdSense/Googleadshorizontal";



function Trandingmovie({Succesmsg,Errormsg}) {
  const watchlistcount = useSelector((state) => state.watchlistcount);
  const [hiddenButtons, setHiddenButtons] = useState({});
  const[trendingm,setTrendingm]=useState([])
  const [loading, setLoading] = useState(true);

const dispatch = useDispatch();
const handleOpen = () => {
  dispatch(openModal());
};
const Categoryoptions=useCategory();
const createPost = async(movieId) => {
  var bodyFormData = new FormData();
  bodyFormData.append("movieId", movieId);
  bodyFormData.append("userId",Cookies.get("id") );
 
  try {
    const response =  await movieaddWatchlistost(bodyFormData);
    if (response?.status === 200) {
      setHiddenButtons(prevState => ({ ...prevState, [movieId]: true }));  
      Succesmsg(addedtoWatchlist)
      dispatch(setWatchlistcount(watchlistcount+1));
    } 
  } catch (error) {
    if (error.response) {
      if (error.response.data.message) {
        // Show an alert with the message
        Errormsg(error.response.data.message);
    }
        handleOpen()
        Cookies.set("navivalue", "/");
        Errormsg(loginfirslyMsg);
      // The request was made and the server responded with a status code

    } 
  }
};

useEffect(() => {
  const TrandingmovieFun = async () => {
    try {
      const response = await trendingmovieGet(); 
      setTrendingm(response?.data);
      setLoading(false);
    } catch (error) {
      return;
    }
  };
  TrandingmovieFun();
    }, []);

  return (
    <div>
 
      <div className="trending_main section_main ">
        <div className="containers con_div">
    
        <Googleadshorizontal props="mt-2 mb-2 text-center"/>
       

          <div className="heading">
            <h6>Trending Movies</h6>
             <NavLink  to="/Movieslist" className="viewheading" onClick={() => window.scrollTo(0, 0)}>
              View all{" "}
              <FontAwesomeIcon className="greatericon" icon={faGreaterThan} />
            </NavLink>
          </div>
          <div className="secondchild">
          {loading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
          </div>
          ):trendingm?.length>0?
            <Slider {...settings}>
            {trendingm?.map((item, index) => (
          <div className="first_card trending-movie1">
              <div  key={index} className="cardd moviecard">
              
<Link className="movieheading_ele" to={`/Moviedetails/${item?.id}`}>
  {item?.poster?.includes("https://m.media-amazon.com/images") ? 
    <img src={item?.poster} alt="img" className="imgw-100" onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem;
    }} /> : 
    <img src={movieposterpath + item?.poster} alt="img" className="imgw-100" onError={(e) => {
      e.target.onerror = null;
      e.target.src = firstitem;
    }} />
  }
</Link>
              
                {!hiddenButtons[item?.id] && <button className="btn card-btn card-cstm-btn"  onClick={() => createPost(item.id)}>Add to Watchlist</button>}
                <div className="card-body cbodyheading moviebody">
                <Link  className="movieheading_ele"  to={`/Moviedetails/${item?.id}`}>
  <h5>{item?.name}</h5>
</Link>
                
                  <span className="text-offw"> Release year : {item?.releaseYear}</span>
                  <div className="mdetails">

                    <ul className="Tren_m_ul">                                       
            {item.category && item.category.split(',').map((categoryId, index) => (
  <l key={index} className="text-offw">
    {Categoryoptions?.find(cat => cat?.id == categoryId)?.category}
  </l>
))}
                    </ul>
                  </div>
                </div>
              </div>
              </div>
                  ))}
            </Slider>: <p className="text-white m-0">No Movies found</p>}
          </div>
       
          <Googleadshorizontal props="pt-2 pb-2 text-center"/>
        </div>
      </div>
    </div>
  );
}

export default Trandingmovie;
