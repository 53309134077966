import React,{useState,useEffect} from 'react'
import { useCategory } from '../../../../../components/useCategory';
import {  playermoviesGet,  upcomingpollmoviesGet ,completeTraderequest,  notificationGet, pickformmoviepoolPost, manageacceptMovies} from '../../../../../api/handleapi';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { openModalmdetails } from '../../../../../feature/showSlice/showmodaltrademoviedetailsSlice';
import { setTrademoviedetailsforid } from '../../../../../feature/showSlice/trademoviedetailsforidSlice';
import Searchmovies from '../../../../../components/searchmovies/Searchmovies';
import swal from 'sweetalert';
import { apiErrorMsg, APIerrorTitle, completetradevalidationMsg, managemovievalidationMsg, managepickupconditionMsg, managetradeconditionMsg, managetradeMsg, pickupMsg, pickupsMsg, successfullyTitle, successmanagemovieMsg,  TradecompleteMsg, WrongTitle } from '../../../../../components/language/Language';
import { categoryIdValues, reservedCategoryids } from '../../../../../Config/config';



function Tradeaccept({togglepickup,setTogglepickup,toggleadjust,trademovies,showtradeaccpet,setShowTradeaccpet,setNotificationlength,setNotificationget}) {
  const [hiddenButtons, setHiddenButtons] = useState({});
    const Categoryoptions=useCategory();
    const [ftrade,setFtrade]=useState({
        FirstUserId :"",
        FMovieId :"",
        rosterid:"",
        FCategoryId :"",
        FMovieName :"",
      })
      const [managetrade,setmanageTrade]=useState({
        FirstUserId :"",
        FMovieId :"",
        FCategoryId :"",
        FMovieName :"",
      })
      const [pollmovies,setPollmovies]=useState([]);
      const [categorynamepickup,setcategoryNamepickup]=useState([]);
    
      

      const [pickupmoviecollect,setPickupmoviecollect]=useState(
        {
            pickmoviemovieid:"",
            pickmoviemoviename:"",
            pickmoviemoviecategory:"",
        }
      );
      

      const [fpick,setFpick]=useState({
        MovieId :"",
        MovieCategoryId:"",
      })
      const [pollpick,setPollpick]=useState({
        PoolMovieId :"",
        PoolMovieCategoryId   :"",
      })  
    
    const [inputText, setInputText] = useState("");

     const Leagueid=trademovies?.tradeRequestFor?.leagueId
    const [countermovieslist,setcounterMoviesList] = useState([]);
    const [counterofferloginpmovie,setCounterofferloginpmovie]=useState();
       const userid=Cookies.get("id");
       const dispatch=useDispatch();
       const [selectedCategory, setSelectedCategory] = useState('');

         const movieslistFunforloginp = async (Leagueid,userid) => {
        try {
          const response = await playermoviesGet(Leagueid,userid); 
          setCounterofferloginpmovie(response?.data?.data?.logedInPlayerMovies)
           } catch (error) {
            return;
        }
      }


      useEffect(() => {
        movieslistFunforloginp(Leagueid,userid)
      }, [Leagueid])

      
        const openModalMdetails = (movieId) => {
        dispatch(openModalmdetails(true)); 
        dispatch(setTrademoviedetailsforid(movieId));
    };
    const handleIconClick = (movieid,id) => {

        let tempmovieList = [...countermovieslist]
        let updatedData = tempmovieList?.map((each)=>{
          if(each?.id==id){
            setFtrade({ FirstUserId :userid,FMovieId:each?.movieId,rosterid:each?.id,FCategoryId:each?.categoryId,FMovieName:each?.movieName})
            return {...each,checked:!each.checked}
          }
       
          return {...each,checked:false}
      })
        setcounterMoviesList(updatedData);
      };


      useEffect(() => {
        const updatedMoviesArray = counterofferloginpmovie?.map((movie) => ({
          ...movie,
          checked: false,
      }));
      const updatedMoviesforfinals = updatedMoviesArray?.filter(movie => movie?.finalScore == 0 || movie?.finalScore == null
      );
     
        setcounterMoviesList(updatedMoviesforfinals);     
        },[counterofferloginpmovie,showtradeaccpet]);

        const pollmoviesGet = async (Leagueid) => {
            try {
          const response = await upcomingpollmoviesGet(Leagueid);
           setPollmovies(response?.data?.data)
          } catch (error) {
             return;
          }
          };
      
            useEffect(() => {
             pollmoviesGet(Leagueid);
            }, [Leagueid]);
            
        useEffect(() => {
            const updatedMoviesArrayy = pollmovies?.map((movie) => ({
              ...movie,
              checked: false,
          }));
          setPollmovies(updatedMoviesArrayy);
          },[]);
      
        const handleIconClickkforpool = (movieId,category) => {
            let tempmovielist = [...pollmovies]
            let movieCategoryId = fpick?.MovieCategoryId;
         
            let updatedData = tempmovielist?.map((each)=>{
              if(each?.id==movieId){
             
                if (category?.includes(movieCategoryId)) {
       
                  setPollpick ({
                    PoolMovieId:each?.id,
                    PoolMovieCategoryId:movieCategoryId
                   }); 
                   return {...each,checked:!each.checked}
                }
                else{
                  setPollpick ({
                  PoolMovieId:each?.id,
                  PoolMovieCategoryId:category
                 }); 
                return {...each,checked:!each.checked}
                }
              }
              return {...each,checked:false}
          })
            setPollmovies(updatedData);
          };
      
        let inputHandler = (e) => {
            //convert input text to lower case
            var lowerCase = e.target.value.toLowerCase();
            if (true) {
            setInputText(lowerCase);
            }
          };

          const filteredData = pollmovies?.filter((el) => {
           
            const nameMatch = el?.name?.toLowerCase()?.includes(inputText);
            const categoryMatch = el.category?.includes(selectedCategory);
            if (inputText !== '' && selectedCategory !== 'All') {
              // Scenario 1: Filter by movie name and category
              return nameMatch && categoryMatch;
            } else if (inputText !== '' && (!selectedCategory || selectedCategory === 'All')) {
              // Scenario 2: Filter by movie name only
              return nameMatch;
            } else if (inputText === '' && selectedCategory !== 'All') {
              // Scenario 3: Filter by category only
              return categoryMatch;
            } else {
              // If both inputText and selectedCategory are empty, return all movies
              return true;
            }
          })

      function categoryNameFind(id) {
        const categoryOption = Categoryoptions?.find((option) => option?.id == id);
        if (categoryOption) {
            setcategoryNamepickup(categoryOption?.category);
        }
      }
      
const pickupCall=(mid,mname,cateid)=>{
    setPickupmoviecollect({
        pickmoviemovieid:mid,
        pickmoviemoviename:mname,
        pickmoviemoviecategory:cateid,
    })
    categoryNameFind(cateid)
    setFpick({ MovieId:mid,MovieCategoryId:cateid})
    setTogglepickup(true);
}


  const pickupMovie = async() => {
    if(!pollpick?.PoolMovieId){
      swal({
        title: "",
        text:pickupsMsg ,
        icon: "error",
        closeOnClickOutside:false,
        buttons: "ok",
        dangerMode: false
      })
    }
    else{
    var bodyFormData = new FormData();
    bodyFormData.append("LeagueId",Leagueid );
    bodyFormData.append("PlayerId", userid );
    bodyFormData.append("MovieCategoryId",fpick?.MovieCategoryId);
    bodyFormData.append("PoolMovieId", pollpick?.PoolMovieId);
    let movieCategoryId = fpick?.MovieCategoryId;
    if (categoryIdValues?.includes(movieCategoryId)) {
      movieCategoryId = fpick?.MovieCategoryId;
    } else {
      movieCategoryId = pollpick?.PoolMovieCategoryId;
    }
    bodyFormData.append("PoolMovieCategoryId", movieCategoryId);     
    try {
      const response =  await pickformmoviepoolPost(bodyFormData);
      if (response?.status === 200) {
        movieslistFunforloginp(Leagueid,userid)
        pollmoviesGet(Leagueid)
        swal({
          title: successfullyTitle,
          text: pickupMsg,
          icon: "success",
          closeOnClickOutside:false,
          buttons: "ok",
          dangerMode: false
        }).then((value) => {
          if(value){
            setTogglepickup(false);        
          }})
      } 
    } 
    catch (error) {
      if (error.response.data?.message===managepickupconditionMsg
) {             
        swal({
                  title: "",
                  text:`Select movies that are categorized as ${categorynamepickup} to pickup`,
                  icon: "error",
                  buttons: "ok",
                  closeOnClickOutside:false,
                  dangerMode: false
                })                  
              }         
              else if (error.response.data?.message===undefined) {
                swal({
                    title: "",
                    text: `Select movies that are categorized as ${categorynamepickup} to pickup`,
                    icon: "error",
                    buttons: "ok",
                    closeOnClickOutside:false,
                    dangerMode: false
                  })
              }else {
                swal({
                  title: "",
                  text: WrongTitle,
                  icon: "error",
                  buttons: "ok",
                  closeOnClickOutside:false,
                  dangerMode: false
                })
              }
    }
  }
  }

  const [storemovieid,setStoremovieid]=useState([])
  const manageacceptMoviess = async() => {
    if(!ftrade?.FCategoryId && !managetrade?.FCategoryId){
      swal({
        title: "",
        text:managemovievalidationMsg ,
        icon: "error",
        closeOnClickOutside:false,
        buttons: "ok",
        dangerMode: false
      })
    }
    else{
    var bodyFormData = new FormData();
    bodyFormData.append("LeagueId",Leagueid );
    bodyFormData.append("logedInUserId", userid );
    bodyFormData.append("RosterId", ftrade?.rosterid);
    bodyFormData.append("FMovieCategoryId",ftrade?.FCategoryId);
    bodyFormData.append("SMovieId",managetrade?.FMovieId);
    bodyFormData.append("SMovieCategoryId",managetrade?.FCategoryId);
    try {
      const response =  await manageacceptMovies(bodyFormData);
      if (response?.status === 200) {
        movieslistFunforloginp(Leagueid,userid)
        swal({
          title: successfullyTitle,
          text: successmanagemovieMsg,
          icon: "success",
          closeOnClickOutside:false,
          buttons: "ok",
          dangerMode: false
        }).then((value) => {
          if(value){
            setHiddenButtons(prevState => ({ ...prevState, [managetrade?.FMovieId]: true }));  
            setStoremovieid(prevState => ([ ...prevState, managetrade?.FMovieId ]));    
            const storedMovieIds = JSON.parse(localStorage.getItem('storedMovieIds')) || [];
            if (!storedMovieIds?.includes(managetrade?.FMovieId)) {
              storedMovieIds?.push(managetrade?.FMovieId);
              localStorage.setItem('storedMovieIds', JSON.stringify(storedMovieIds));
            }
               return;           
          }})
          setFtrade({
            FirstUserId :"",
            FMovieId :"",
            rosterid:"",
            FCategoryId :"",
            FMovieName :"",
          })
      } 
    } 
    catch (error) {       
              if (error.response.data?.message===managetradeconditionMsg ) {
                swal({
                    title: "",
                    text: managetradeMsg,
                    icon: "error",
                    buttons: "ok",
                    closeOnClickOutside:false,
                    dangerMode: false
                  })
                } else if (error.response.data?.message===undefined) {             
                  swal({
                            title: "",
                            text:error.response.data?.message,
                            icon: "error",
                            buttons: "ok",
                            closeOnClickOutside:false,
                            dangerMode: false
                          })                  
                        }  
                else {
                  swal({
                    title: "",
                    text: error.response.data?.message,
                    icon: "error",
                    buttons: "ok",
                    closeOnClickOutside:false,
                    dangerMode: false
                  })
              }
    }
  }
  }




  const notificationGetcall = async (userid) => {
    try {
      const response = await notificationGet(userid); // You might want to pass some parameters to notificationget
      const notifications = response?.data?.data==undefined||null?[]:response?.data?.data;
      setNotificationget(() => {
        const combinedNotifications = [...notifications];
        const uniqueNotifications = [...new Set(combinedNotifications?.map(JSON.stringify))]?.map(JSON.parse);
        const  uniqueNotificationsreadlength = uniqueNotifications?.filter((notification) => !notification?.isViewed)?.length;
        setNotificationlength(uniqueNotificationsreadlength);
        return uniqueNotifications;
      });
    } catch (error) {
     return;
    }
  };

  const [requestFrommovieidcomplete,setRequestFormmovieidcomplete]=useState();
const [requestFromcategoryidcomplete,setRequestFormcategoryidcomplete]=useState();



  useEffect(() => {
    const categoryrequestFromfor=  trademovies?.requestFrom?.fCategoryDetails
    const movieidrequestFromfor=  trademovies?.requestFrom?.fMovieDetails
 
   setRequestFormmovieidcomplete(movieidrequestFromfor);
   setRequestFormcategoryidcomplete(categoryrequestFromfor);
      }, [trademovies?.tradeRequestFor,trademovies?.requestFrom]);



  const completeTrade = async() => {

    if(completedisbale){
      swal({
        title: "",
        text: completetradevalidationMsg,
        icon: "error",
        closeOnClickOutside:false,
        buttons: "ok",
        dangerMode: false
      })
    }
    else{
      var bodyFormData = new FormData();
      bodyFormData.append("LeagueId",Leagueid );
      bodyFormData.append("FirstUserId", userid);
      if (requestFrommovieidcomplete && requestFrommovieidcomplete?.length > 0) {
        for (let i = 0; i < requestFrommovieidcomplete?.length; i++) {
          bodyFormData.append(`FMovieId[${i}]`, requestFrommovieidcomplete[i]?.movieId);
        }
      } else {
        bodyFormData.append("FMovieId", requestFrommovieidcomplete?.movieId);
      }
      if (requestFromcategoryidcomplete && requestFromcategoryidcomplete?.length > 0) {
        for (let i = 0; i < requestFromcategoryidcomplete?.length; i++) {
          bodyFormData.append(`FCategoryId[${i}]`, requestFromcategoryidcomplete[i]?.categoryId);
        }
      } else {
        bodyFormData.append("FCategoryId", requestFromcategoryidcomplete?.categoryId);
      }
      bodyFormData.append("SecondUserId",trademovies?.requestFrom?.firstUserId);      
      try {
        const response =  await completeTraderequest(bodyFormData);
        if (response?.status === 200) {
          movieslistFunforloginp(Leagueid,userid)
          swal({
            title: successfullyTitle,
            text: TradecompleteMsg,
            icon: "success",
            closeOnClickOutside:false,
            buttons: "ok",
            dangerMode: false
          }).then((value) => {
            if(value){
              setStoremovieid([]);
              Cookies.remove("trademsg")
              Cookies.remove("tradeaccept")
              localStorage.removeItem("storedMovieIds")
           
              notificationGetcall(Cookies.get("id"))
              setShowTradeaccpet(false)
  
            }})
        } 
      } 
      catch (error) {
        if (error.response.data?.message) { 
          swal({
                    title: "",
                    text:error.response.data?.message,
                    icon: "error",
                    buttons: "ok",
                    closeOnClickOutside:false,
                    dangerMode: false
                  }).then((value) => {
                    Cookies.remove("trademsg")
                    Cookies.remove("tradeaccept")
                    localStorage.removeItem("storedMovieIds")
                    notificationGetcall(Cookies.get("id"))
                    setShowTradeaccpet(false)
            })               
                }         
                else if (error.response.data?.message===undefined) {
                  swal({
                      title: APIerrorTitle,
                      text: apiErrorMsg,
                      icon: "error",
                      buttons: "ok",
                      closeOnClickOutside:false,
                      dangerMode: false
                    }).then((value) => {
                      Cookies.remove("trademsg")
                      Cookies.remove("tradeaccept")
                      localStorage.removeItem("storedMovieIds")
                      notificationGetcall(Cookies.get("id"))
                      setShowTradeaccpet(false)
              })         
                }
      }
    }
   
  }


  const [requestFrom,setRequestForm]=useState([]);


  useEffect(() => {
       setRequestForm(trademovies?.requestFrom?.fMovieDetails);
      }, [trademovies]);

       const [completedisbale,setCompletedisbale]=useState(true);

       const [adjustdisbale,setadjustdisbale]=useState(false);



      const handleIconClicktrade = (movieid,catid) => {
        let tempmovieList = [...requestFrom]
        let updatedData = tempmovieList?.map((each)=>{
          if(each.movieId==movieid){
            setmanageTrade({FMovieId:movieid,FCategoryId:catid,})
            return {...each,checked:!each.checked}
          }
       
          return {...each,checked:false}
      })
      setRequestForm(updatedData);
      };

      const storedMovieIds = localStorage.getItem('storedMovieIds') ? JSON.parse(localStorage.getItem('storedMovieIds')) : [];
      useEffect(() => {
        const hasNonNullMovieId = counterofferloginpmovie?.every(movie => movie?.movieId != null);
        if (hasNonNullMovieId && storedMovieIds?.length===requestFrom?.length) {
          setCompletedisbale(false);
        } else {
          setCompletedisbale(true);
        }
        if(storedMovieIds?.length==requestFrom?.length){
          setadjustdisbale(true)
        }
        else{
          setadjustdisbale(false)
        }
       }, [counterofferloginpmovie,storedMovieIds])
      

  return (
    <div>
    <div className="row">
      {!togglepickup && !toggleadjust?
      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div className="heading_live_listt"><h6>My Movies List</h6></div>
        <div className="position_card">
    <div className="table_containerr_trade scrollbarcstm"> 
      <table className="playermovie_list">
    <thead  >
      <tr className="trade_movieslist_headings">
        <td >Movie</td>
        <td >Category</td>
        <td >Score</td>
        <td >Actions</td>
        <td></td>
      </tr>
    </thead>
    <tbody >
    {
 countermovieslist?.length!=0?
 countermovieslist?.map((data,index)=>(
   <tr className="Player_title" key={index} >
  
         <td>
            <div className='d-flex gap-2'>
            <span className={data?.movieName !== null?'moviesnamefordetails':'moviesnamefornoname'} onClick={()=> data?.movieName !== null ? openModalMdetails(data?.movieId) : null}>{data?.movieName?.length > 15 ? data?.movieName.substring(0, 15) + '...' : data?.movieName ||"Empty"}</span>

            </div>
            </td>   
     <td>{Categoryoptions?.find(cat => cat?.id == data?.categoryId)?.category}</td>
     <td>{data?.finalScore||0}</td>
   
     <td className="campareicons checkcounter">
     <input type="checkbox" className='form-check-input' checked={data?.checked}  onClick={() => handleIconClick(data?.movieId,data?.id)}/>
       </td>
       <td> {data?.finalScore ==null?<button className='pickupbtn_forAccommodate' onClick={()=>pickupCall(data?.movieId,data?.movieName,data?.categoryId)}> Pickup</button>:null}</td>
   </tr>
   )):<div className='no_dropm_found'>No movies found</div>
   } 
      
     
         
    </tbody>
  </table>
              </div>
            </div>
  
      </div>
:togglepickup && !toggleadjust?
      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 Pickup_singleMovie">
        <div className="heading_live_listt"><h6>Pickup Movie</h6></div>
                <div className="position_card">
    <div className="table_containerr_trade scrollbarcstm"> 
      <table className="playermovie_list">
    <thead  >
      <tr className="trade_movieslist_headings">
        <td >Movie</td>
        <td >Category</td>
        <td >Score</td>
        <td >Actions</td>
        <td></td>
      </tr>
    </thead>
    <tbody >
   <tr className="Player_title">
         <td>
            <div className='d-flex gap-2'>
            <span className='moviesnamefornoname'>{pickupmoviecollect?.pickmoviemoviename?.length > 15 ? pickupmoviecollect?.pickmoviemoviename?.substring(0, 15) + '...' : pickupmoviecollect?.pickmoviemoviename||"Empty"}</span>

            </div>
            </td>   
     <td>{Categoryoptions?.find(cat => cat?.id == pickupmoviecollect?.pickmoviemoviecategory)?.category}</td>
     <td>0</td>
   
     <td className="campareicons checkcounter">
     <input type="checkbox" className='form-check-input' checked/>
       </td>
   </tr>

     
         
    </tbody>
  </table>
              </div>
            </div>
  
      </div>:null}
     { !togglepickup && !toggleadjust?<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div className="heading_live_listt">
          <h6> 
          Trade Movie
          </h6>
        </div>
            
          <div className="position_card">
    <div className="table_containerr_trade scrollbarcstm"> 
      <table className="playermovie_list">
    <thead  >
      <tr className="trade_movieslist_headings">
        <td >Movie</td>
        <td >Category</td>
        <td >Score</td>
        <td >Actions</td>
      </tr>
    </thead>
    <tbody >
 

{requestFrom?.map((data, index) => (
 
  !hiddenButtons[data?.movieId] && (!localStorage.getItem('storedMovieIds') || !storedMovieIds?.includes(data?.movieId)) && (
    <tr className="Player_title">
      <td>
        <div className='d-flex gap-2'>
          <span className='moviesnamefordetails' onClick={() => openModalMdetails(data?.movieId)}> 
            {data?.movieName?.length > 15 ? data?.movieName?.substring(0, 15) + '...' : data?.movieName}
          </span>
        </div>
      </td>
      <td>{data?.categoryName}</td>
      <td>{data?.finalScore == undefined || null ? 0 : data?.finalScore}</td>
      <td className="campareicons checkcounter">
        <input type="checkbox" className='form-check-input' checked={data?.checked} onClick={() => handleIconClicktrade(data?.movieId, data?.categoryId)} />
      </td>
    </tr>
  )
))}

    </tbody>
  </table>
              </div>
            </div>
      </div>:togglepickup && !toggleadjust?
      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 Available_pollmovie">
      <div className="heading_live_listt">
        <h6> Available Poll Movies </h6>
      </div>
            <div className="position_card">
  <div className="table_containerr_trade pool_tables scrollbarcstm"> 
    <table className="playermovie_list ">
  <thead  >
    <tr className="trade_movieslist_headings">
      <td >
        <Searchmovies inputHandler={inputHandler}/>
             </td>
      <td >  
         <select
                  className="form-select pickups-input-groupp"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  value={selectedCategory}
                >
                  <option selected value="All">Select Category</option>
                                      {Categoryoptions?.sort((a, b) => a?.category?.localeCompare(b?.category))?.filter((item) => !reservedCategoryids?.includes(item?.id.toString()))?.map((matchingData, item) => (
                  <option key={item} value={matchingData?.id}>
                 {matchingData?.category}
                </option>
                  ))}
                  
                </select>
                
                  </td>
      <td >Actions</td>
    </tr>
  </thead>
  <tbody>
    {filteredData?.length !=0?
 
  filteredData?.map((data,index)=>(

    <tr className="Player_title" key={index} >
          <td>
             <div className='d-flex gap-2'>
             <span  className='moviesnamefordetails'onClick={()=>openModalMdetails(data?.id)}>{data?.name?.length > 15 ? data?.name.substring(0, 15) + '...' : data?.name }</span>          
             </div>
             </td>   
      <td><div className='listcategory'>
                {data?.category && data?.category.split(',').map((categoryId, index) => (
                          <span key={index} className="listcategory">
                            {Categoryoptions?.find(cat => cat?.id == categoryId)?.category}
                            </span>
                          ))}
                
                </div></td>
      <td className="campareicons">
      <input type="checkbox" className='form-check-input' checked={data?.checked} onChange={()=> handleIconClickkforpool(data?.id,data?.category)} />
      </td>
    </tr>
    )):<div className='no_dropm_found'>No movies found</div>
    } 
  </tbody>
</table>
            </div>
          </div>
    </div>:null}


    </div>
 
    {!togglepickup && !toggleadjust?<div className='trading1_btns'>
      <div className='Trade_modal_btn'>
  <button  className={adjustdisbale?"ttrade-btns btn-disabled":"ttrade-btns"} disabled={adjustdisbale} onClick={()=>manageacceptMoviess()}>Adjust</button>
  </div>
 
    <div className='Trade_modal_btn'>
  <button  className={completedisbale?"ttrade-btns btn-disabled":"ttrade-btns"} onClick={completeTrade}>Complete</button>

  </div>
  </div>:togglepickup && !toggleadjust?
  <div className='d-flex justify-content-between'>
     <div className='Trade_modal_btn' >
  <button className="ttrade-btns" onClick={()=>setTogglepickup(false)}>Back</button>
  </div>
  <div className='Trade_modal_btn' >
  <button className="ttrade-btns" onClick={()=>pickupMovie()}>Pickup</button>
  </div>
  </div>: 
  ""
  }
  </div>

  )
}

export default Tradeaccept