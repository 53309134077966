
import { configureStore } from '@reduxjs/toolkit';
import showModalReducer from '../../feature/showSlice/showModalSlice';
import signalrDataReducer from '../../feature/showSlice/signalrDataSlice';
import showCategoryReducer from '../../feature/showSlice/showMcategorySlice';
import moviepickDataReducer from '../../feature/showSlice/postPickSlice';
import uplstateReducer from '../../feature/showSlice/uplstateSlice';
import msgdataReducer from '../../feature/showSlice/msgSlice';
import leaguelivedReducer from '../../feature/showSlice/leaguelivedSlice';
import draftorderrReducer from '../../feature/showSlice/draftorderrSlice';
import secondpmovieReducer from '../../feature/showSlice/secondpmovieSlice';
import loginpmovieReducer from '../../feature/showSlice/loginpmovieSlice';
import showmodaltradeReducer from '../../feature/showSlice/showmodaltradeSlice';
import registeruserinfoReducer from '../../feature/showSlice/registeruserinfoSlice';
import loginuserinfoReducer from '../../feature/showSlice/loginuserinfoSlice';
import paymentdetailReducer from '../../feature/showSlice/paymentdetailSlice';
import watchlistcountReducer from '../../feature/showSlice/watchlistcountSlice';
import showmodaltrademoviedetailsReducer from '../../feature/showSlice/showmodaltrademoviedetailsSlice';
import trademoviedetailsforidReducer from '../../feature/showSlice/trademoviedetailsforidSlice';
import togglestateReducer from '../../feature/showSlice/togglestateSlice';
import showInviteReducer from '../../feature/showSlice/showmodalinviteSlice';
import updateleagueinfoReducer from '../../feature/showSlice/updateleagueinfoSlice';

export const store = configureStore({
  reducer: {
    showModal: showModalReducer,
    signalrData:signalrDataReducer,
    showCategory:showCategoryReducer,
    moviepickData:moviepickDataReducer,
    uplstate:uplstateReducer,
    msgdata:msgdataReducer,
    leaguelived:leaguelivedReducer,
    draftorderr:draftorderrReducer,
    secondpmovie:secondpmovieReducer,
    loginpmovie:loginpmovieReducer,
    showmodaltrade:showmodaltradeReducer,
    registeruserinfo:registeruserinfoReducer,
    loginuserinfo:loginuserinfoReducer,
    paymentdetail:paymentdetailReducer,
    watchlistcount:watchlistcountReducer,
    showmodaltrademoviedetails:showmodaltrademoviedetailsReducer,
    trademoviedetailsforid:trademoviedetailsforidReducer,
    togglestate:togglestateReducer,
    showInvite:showInviteReducer,
    updateleagueinfo:updateleagueinfoReducer,
    
  },
});