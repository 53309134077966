import React from "react";
import clsx from "clsx";
import { useState } from "react";
import styles from "./LoginForm.module.css";
import { useLoginFormValidator } from "./UseLoginFormValidation";
import logo from "../../assets/Gaffer Transparent Logo1.png";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { ReactComponent as YourIcon2 } from "../../assets/eye-solid.svg";
import { ReactComponent as YourIcon } from "../../assets/eye-slash-solid.svg";
import {  useDispatch } from 'react-redux';
import {  closeModal } from '../../feature/showSlice/showModalSlice';
import { loginPost} from "../../api/handleapi";
import {setLoginuserinfo} from "../../feature/showSlice/loginuserinfoSlice";
import { setPaymentdetail } from '../../feature/showSlice/paymentdetailSlice';
import Cookies from 'js-cookie';
import Loader from "../../components/loader/Loader";
import { emailIncorrecttitle, emailnotverified, emailnotverifyMsg, loginAuthcheck, loginAuthsuccesscheck } from "../../components/language/Language";


const LoginForm = ({openforgot,setOpenforgot,setShowModalTwo,setShowsubscriptionpayment}) => {
  const navigate = useNavigate();
  const [loading,setLoading]=useState(false)
  const [showPassword, setShowPassword] = useState(false);

  const openModalTwo = () => { 
    setShowModalTwo(() => true);
  };

  const currentDate = new Date();

  const navivalue=Cookies.get("navivalue")
  const [form, setForm] = useState({ email: "", password: "" });
  const dispatch = useDispatch();

  const createPost = async() => {
     setLoading(true);
     var bodyFormData = new FormData();
    bodyFormData.append("email", form?.email);
    bodyFormData.append("password", form?.password);  
    try { 
      const response =  await loginPost(bodyFormData);
        if(response!=null||undefined)
        {
        dispatch(setLoginuserinfo({userid:response?.data?.data?.id,
       email:response?.data?.data?.email,
       name:response?.data?.data?.name,
       userprofileimage:response?.data?.data?.profileImage,
       }))
        }
        // check the message property conditon for response then update the state
         if( response.status===200 && response?.data?.message === loginAuthcheck)
          {
            setShowModalTwo(()=>false)
            setShowsubscriptionpayment(()=>false)
            setLoading(false)
            await swal({
              title: emailnotverified,
              text: "",
              icon: "",
              closeOnClickOutside:false,
              dangerMode: true,
            }).then(() => {
              setForm({
                email: "",
                password: "",
              });
            });
            
          }
          // check the both status,  message property conditon for response then store the data
       if (response.status===200 && response?.data?.message === loginAuthsuccesscheck) { 
        setLoading(false);
        await Cookies.set('token',response?.data?.data?.token)
        await Cookies.set('rrefreshToken',response?.data?.data?.refreshTokens)
        await Cookies.set('email',  response?.data?.data?.email)
        await Cookies.set('name',  response?.data?.data?.name)
        await Cookies.set('id',  response?.data?.data?.id)
        await Cookies.set('username',  response?.data?.data?.name)
        await Cookies.set('userprofileimage',  response?.data?.data?.profileImage)
                                     if(response?.data?.data?.subscriptionDetails== null ||undefined){
                                      setShowModalTwo(true)
                                      setShowsubscriptionpayment(true)
                                    } 
                                    else if (response?.data?.data?.subscriptionDetails!= null ||undefined) {
                                      if (
                                         currentDate.toISOString() <= response?.data?.data?.subscriptionDetails?.subscriptionEndDate?false:true
                                      ) {
                                         setShowModalTwo(true)
                                         setShowsubscriptionpayment(true)
                                      }  else {     
                                        await dispatch(setPaymentdetail(response?.data?.data?.subscriptionDetails)) 
                                         Cookies.set("Paymentdetail",response?.data?.data?.subscriptionDetails?.subscriptionEndDate)
                                                  await dispatch(closeModal(false));
                                                   await navigate(navivalue|| "/Dashboard");
                                      }
                                    }
      }
    
    } catch (error) {
      if (error.response) {
        setLoading(false)
        await swal({
          title: emailIncorrecttitle,
          text: emailnotverifyMsg,
          icon: "error",
          closeOnClickOutside:false,
          dangerMode: true,
        }).then(() => {
          setForm({
            email: "",
            password: "",
          });
        
        });
      } 
    }

  }; 
  const { errors, validateForm, onBlurField } = useLoginFormValidator(form);
  const onUpdateField = (e) => {
    const field = e.target.name;
    const nextFormState = { ...form, [field]: e.target.value };
    setForm(nextFormState);
    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };
  const onSubmitForm = (e) => {
    e.preventDefault();

    const { isValid } = validateForm({ form, errors, forceTouchErrors: true });
    if (!isValid) {
      return;
    }
   createPost();
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form className={styles.form} onSubmit={onSubmitForm}>
      <img className={styles.loginlogo} src={logo} alt="img" />
      <div className={styles.formGroup}>
        <h5 className={styles.wel_head} >
          <b>Welcome Back!</b>
        </h5>
        <label className={styles.formLabel}></label>
        <input
          className={clsx(
            styles.formField,
            errors.email.dirty && errors.email.error && styles.formFieldError
          )}
          type="text"
          autocomplete="off"
          aria-label="Email field"
          name="email"
          placeholder="Email"
          value={form?.email}
          onChange={onUpdateField}
          onBlur={onBlurField}
        />
        {errors.email.dirty && errors.email.error ? (
          <p className={styles.formFieldErrorMessage}>{errors.email.message}</p>
        ) : null}
      </div>
      <div className="mb-2">
        <div className={styles.formGroup}>
          <label className={styles.formLabel}></label>
          <input
            className={clsx(
              styles.formField,
              errors.password.dirty &&
                errors.password.error &&
                styles.formFieldError
            )}
            type={showPassword ? "text" : "password"}
            autocomplete="off"
            aria-label="Password field"
            name="password"
            id="myInput"
            placeholder="Password"
            value={form?.password}
            onChange={onUpdateField}
            onBlur={onBlurField}
          />{" "}
          <div onClick={togglePasswordVisibility}>
            {showPassword ? (
               <YourIcon className={styles.icon_password_login} />
            ) : (
               <YourIcon2 className={styles.iicon_password_login}/>
            )}
          </div>
          {errors.password.dirty && errors.password.error ? (
            <p className={styles.formFieldErrorMessage}>
              {errors.password.message}
            </p>
          ) : null}
        </div>{" "}
      </div>

      <div className="form-check text-end mb-2">
       
        <a className={styles.password_link} onClick={()=>setOpenforgot(!openforgot)}>
          Forgot Password?
        </a>
      </div>
      <div className={styles.formActions}>
        <button
          className={styles.formSubmitBtn}
          type="submit"
          onClick={onSubmitForm}
        >
          Sign In{" "}
        </button>
      </div>
      <h6 className={styles.loginf_h6}>
        Not registered yet?{" "}
        <a className={styles.a_elem}  onClick={openModalTwo}>
          Register
        </a>
      
      </h6>
      <p className={styles.para_term_policy}>
        {" "}
        By registering, you agree to Gaffer<sup className="Trademark">TM</sup>{" "}
        <Link to="/Privacypolicy" onClick={()=>dispatch(closeModal(false))}>
          <a className={styles.a_ele} href="/Privacypolicy">
            Privacy Policy
          </a>
        </Link>
      </p>
      {loading?<Loader/>:null}
    </form>

  
);
};

export default LoginForm;
