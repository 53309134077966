import React,{useState,useEffect} from "react";
import swal from "sweetalert";
import Navbarr from "../../../components/header/Navbarr";
import { Footer } from "../../../components/footer/footer";
import ScrollButton from "../../../components/scrollbutton/ScrollButton";
import img7 from "../../../assets/dummy-news.png";
import "./Newsdetails.css";
import Latestnewsbanner from "../../../components/latestnewsbanner/Latestnewsbanner";
import { movieposterpath } from "../../../Config/config"
import  {UseCommentformvalidation}  from "./UseCommentformvalidation";
import profileimg1 from "../../../assets/large profileicon.svg";
import { imgpath } from "../../../Config/config";
import {newsdetailsGet,newscommentPost,newscommentGet,popularPostget} from '../../../api/handleapi'
import { Link } from "react-router-dom";
import Submitbtn from "../../../components/submit/Submitbtn";
import { useSelector } from "react-redux";
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { commnetsuccessfullyMsg, subscriptionreqMsg, subscriptionreqTitle, successfullyTitle, WrongTitle } from "../../../components/language/Language";
import Googleads from "../../../components/google-AdSense/Googleads";


function Newsdetails() {
 
  const { id } = useParams();
  const [newsId, setnewsId] = useState(id)

  const paymentdetail = useSelector((state) => state.paymentdetail);
  const Id=Cookies.get("id");
  const name=Cookies.get("name");
  const email=Cookies.get("email")
 const [ppost,setPpost]=useState([]);
  const [newdetails,setNewdetails]=useState();
  const [newcomment,setNewcomment]=useState();
 const [commentt, setCommentt] = useState({
  articleId: newsId,
  comment: "",
  senderName: "",
  senderEmail: "",
});

const newsdetailsFun = async (newsId) => {
  try {
    const response = await newsdetailsGet(newsId); 
    setNewdetails(response?.data?.data);
  } catch (error) {
    return;
  }
};

 useEffect(() => {
  newsdetailsFun(newsId);
    },[newsId]);


    const popularpostFun = async () => {
      try {
        const response = await popularPostget(); 
        setPpost(response?.data?.data);
      } catch (error) {
        return;
      }
    };

    useEffect(() => {
      popularpostFun();
        },[newsId]);


        
const createPost = async() => {
  var bodyFormData = new FormData();
  bodyFormData.append("articleId", newsId);
  bodyFormData.append("comment", commentt.comment);
  bodyFormData.append("senderName", commentt.senderName);
  bodyFormData.append("senderEmail", commentt.senderEmail);
  try {
    const response =  await newscommentPost(bodyFormData);
    if (response?.status === 200) {
      commnetgetFun(newsId);
      swal({
        title:successfullyTitle,
        text: commnetsuccessfullyMsg,
        icon: "success",
        buttons: "OK",
        closeOnClickOutside:false,
        dangerMode: false,
      }).then(() => {
        setCommentt({
          articleId: "",
          comment: "",
          senderName: "",
          senderEmail: "",
        });
      });
    } 
  } 
  catch (error) {
    if (error.response) {
          swal({
            title: WrongTitle,
            text: "",
            icon: "error",
            buttons: "OK",
            closeOnClickOutside:false,
            dangerMode: true,
          })
          .then(() => {
            setCommentt({
              articleId: "",
              comment: "",
              senderName: "",
              senderEmail: "",
            });
          });
          // The request was made and the server responded with a status code
        } 
  }
};

const createPostt = async() => {
  var bodyFormData = new FormData();
  bodyFormData.append("articleId", newsId);
  bodyFormData.append("comment", commentt.comment);
  bodyFormData.append("senderName", name);
  bodyFormData.append("senderEmail", email);
  try {
    const response =  await newscommentPost(bodyFormData);
    if (response?.status === 200) {
      commnetgetFun(newsId);
      Cookies.set("commentnews",response.data.data?.comment);
      Cookies.set("senderName",response.data.data?.senderName);
      Cookies.set("sendDate",response.data.data?.sendDate);
      swal({
        title:successfullyTitle,
        text:commnetsuccessfullyMsg,
        icon:"success",
        buttons:"OK",
        closeOnClickOutside:false,
        dangerMode: false,
      }).then(() => {
        setCommentt({
          articleId: "",
          comment: "",
          senderName: "",
          senderEmail: "",
        });
      });
    } 
  } 
  catch (error) {
    if (error.response) {
      swal({
        title: WrongTitle,
        text:"",
        icon: "error",
        buttons: "OK",
        closeOnClickOutside:false,
        dangerMode: true,
      })
      .then(() => {
        setCommentt({
          articleId: "",
          comment: "",
          senderName: "",
          senderEmail: "",
        });
      });
      // The request was made and the server responded with a status code
    } 
  }
};
const commnetgetFun = async (newsId) => {
  try {
    const response = await newscommentGet(newsId); // You might want to pass some parameters to Dashboardget
    setNewcomment(response?.data?.data);
  } catch (error) {
    return;
  }
};
useEffect(() => {
  commnetgetFun(newsId);
    },[newsId]);


const { errors, validateForm, onBlurField } =
UseCommentformvalidation(commentt);


const onUpdateField = (e) => {
const field = e.target.name;
const nextFormState = { ...commentt, [field]: e.target.value };
setCommentt(nextFormState);
if (errors[field].dirty)
  validateForm({
    commentt: nextFormState,
    errors,
    field,
  });
};


const onSubmitForm = (e) => {
  e.preventDefault();
  const { isValid } = validateForm({ commentt, errors, forceTouchErrors: true });
   if(isValid && (Cookies.get("Paymentdetail")==null||undefined) || (paymentdetail?.subscriptionEndDate==null||undefined)){
      swal({
      title:subscriptionreqTitle,
      text:subscriptionreqMsg,
      icon:"",
      buttons:"OK",
      closeOnClickOutside:false,
      dangerMode: false,
    })
   }
  if (Id && commentt?.comment !== "") {
    createPostt();
} else if (commentt?.comment !== "") {
    createPost();
}
};
const handleNewsIdChange = async(articleId) => {
  setnewsId(articleId);
  await newsdetailsFun(articleId);
  await commnetgetFun(articleId);
  await popularpostFun();
};

  return (
    <div>  
      <Navbarr newsId={newsId} setnewsId={setnewsId}/>
      <div className="jdetailsM">
        <Latestnewsbanner text={"Latest News"}/>
        <br />
        <div className="con_div">
          <div className="new">
            <div  className="blogCc card col-lg-9 col-md-9  col-sm-8"> 
              <div className="imag_div"  >
                <img src={movieposterpath+newdetails?.image} alt="img" onError={(e) => {
            e.target.onerror = null;
            e.target.src = img7 }}/>
              </div>
              <div className="card-bodyy cards_tags ">
                <span className="btn  adbtn_news_details ">
                  {newdetails?.type}
                </span>
              </div>
              <div className="card-bodyy text-white para_div col-md-12">
                <h2 className="card-title">
                  {newdetails?.name}
                </h2>
               
                <div className="card-text text-body-third pp-text newsdesdata" dangerouslySetInnerHTML={{
  __html: newdetails?.description.replace(/<br>/g, ' ')
}} />
              </div>
              <div className="commnet_section">
              <h3 className="ppp">Comments</h3>
             {newcomment?.length>0?<div className="new_comment">
              {newcomment?.map((data, index) => (
              <div className="commnets_divs" key={index}>
                <div className="commnets_profile">
                <img
          src={imgpath+data?.userImage}
          className="profileiconnn"
          href="##"
          alt="proimg" onError={(e) => {
            e.target.onerror = null
            e.target.src = profileimg1 }}/>
                </div>
<div className="c_text">
  <div className="comment_text">
  <div className="d-flex text-white name_time">
  <div className="commnet_sendername">
    <h6>{data?.senderName}</h6>

  </div>
  {newcomment?<div className="date_time_comment">
  { moment(new Date(data?.sendDate + 'Z')).format("dddd, MMMM D, YYYY")}
  {" "}
  </div>:null}
  </div>

<p className="card-text text-body-third csutomcdd">
                 {data?.comment}
                </p>
               </div>
              </div>
              </div>
           ))}
            </div>:<div className="text-white"> <p>No Comments Found</p></div>}
              </div>
              <div className="commentse">
            <div className="headingpara">
              <h4>Leave A Comment</h4>
              <p className="text-body-third pp-text">
                Your email address will not be published Required fields are
                marked*
              </p>
            </div>
            <form className="formcomment">
              <label for="message">
                <b>Your Comment*</b>
              </label>
              <br />
              <textarea
                className="ctextarea"
                id="comment"
                name="comment"
                rows="6"
               
                maxLength={1300}
                value={commentt?.comment}
                onChange={onUpdateField}
                onBlur={onBlurField}
                disabled={(Cookies.get("Paymentdetail")==null||undefined ||paymentdetail?.subscriptionEndDate==null||undefined)?true:false}
              
              />
                {errors.comment.dirty && errors.comment.error ? (
                  <p className="c_leagueFieldErrorMessage">
                    {errors.comment.message}
                  </p>
                ) : null}
              <br />
             { Id || Id==null||undefined? "":<div className="row g-0 neinut">
                <div className="col-md-6">
                  <label for="name">
                    <b>Name*</b>
                  </label>
                  <input
                  type="text"
                    className="ntextarea"
                    id="name"
                    name="senderName"
                    
                    value={commentt?.senderName}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                    disabled={Cookies.get("Paymentdetail")==null||undefined ||paymentdetail?.subscriptionEndDate==null||undefined?true:false}
                  />
                    {errors.senderName.dirty && errors.senderName.error ? (
                  <p className="c_leagueFieldErrorMessage">
                    {errors.senderName.message}
                  </p>
                ) : null}
                </div>

                <div className="col-md-6 email_div">
                  <label for="email">
                    <b>Email*</b>
                  </label>
                  <input
                  type="text"
                    className="etextarea"
                    id="email"
                    name="senderEmail"
                   
                    value={commentt?.senderEmail}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                    disabled={Cookies.get("Paymentdetail")==null||undefined ||paymentdetail?.subscriptionEndDate==null||undefined?true:false}
                  />
                    {errors.senderEmail.dirty && errors.senderEmail.error ? (
                  <p className="c_leagueFieldErrorMessage">
                    {errors.senderEmail.message}
                  </p>
                ) : null}
                </div>
              </div>}
              
              <div className="cbtn" >
              <Submitbtn  onSubmitForm={onSubmitForm} props={"Post Comment"}/>
              </div>
            </form>
          </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4   ppcontent ">
              <h3 className="ppp">Popular Posts</h3>
              {ppost?.length > 0 ? (
              ppost?.map((data, index) => (
              <div className="row jdetails_row" key={index}>
                <div className="col-xl-3 col-lg-12  col-md-12 pp_image " >

                <Link className="card-title pp_card_title"   to={`/Newsdetails/${data?.articleId}`}>
                  <img src={movieposterpath+data?.image} onError={(e) => {
            e.target.onerror = null;
            e.target.src = img7  }} className="img-fluid ppimg" alt="card1" onClick={()=> handleNewsIdChange(data?.articleId)}/>
            </Link>
                </div>
                <div className="col-xl-9 col-lg-12 col-md-12 Post_text">
                  <div className="card-body  ppcbody" >
                 
                    <Link className="card-title pp_card_title"   to={`/Newsdetails/${data?.articleId}`} onClick={()=>handleNewsIdChange(data?.articleId)}>

                    
                      <div  className="news-des-listing newsdesdata mb-2" dangerouslySetInnerHTML={{
          __html: data?.description.replace(/<br>/g, ' ')
        }} />
                      

                    </Link>
                    <p className="card-text para_text_Jdate">
                      <small className="text-body-third pp-text">
                        
                         { moment(new Date(data?.createdDate + 'Z')).format("MMMM D, YYYY")}
                         </small>
                    </p>
                  </div>
                </div>
              </div>))): (
    <div className="text-white"> <h5>No Posts found</h5></div>
  )}
              <div className="row categories">
                <h5>Categories</h5>
                <a  className="a_ele_categ">
                {newdetails?.type}
                </a>
                
              </div>
              <div className="mt-5 mb-5">


<Googleads props="parent_dummyads pt-2 pb-2 text-center"/>
              </div>
            </div>
          </div>
        
        </div>
      </div>
      <Footer />
      <ScrollButton />
    </div>
  );
}

export default Newsdetails;
