import { createSlice } from '@reduxjs/toolkit';

export const updateleagueinfoSlice = createSlice({
  name: 'updateleagueinfo', // Slice name
  initialState: {   
  }
  , // Initial state (empty array in this case)
  reducers: {
    setUpdateleagueinfo: (state, action) => {
      // Update the state with the received data
      return action.payload;
    },
  },
});

// Export the action creator
export const { setUpdateleagueinfo } = updateleagueinfoSlice.actions;

// Export the reducer
export default updateleagueinfoSlice.reducer;