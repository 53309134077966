import React,{useEffect,useState} from "react";
import ScrollButton from "../../../components/scrollbutton/ScrollButton";
import Subheader from "../../../components/subheader/Subheader";
import { Footer } from "../../../components/footer/footer";
import fifthitem from "../../../assets/dummy-movie.png";
import { ReactComponent as YourIcon } from "../../../assets/mdi_calendar.svg";
import { ReactComponent as YourIcon1 } from "../../../assets/time.svg";
import "../../dashboard/Dashboard.css";
import Navbarr from "../../../components/header/Navbarr";
import {myleaguesGet} from "../../../api/handleapi"
import {  imgpath,cookieNames } from "../../../Config/config";
import Pagination from "../../../components/pagination/Pagination";
import { Errormsg } from "../../../components/tostifymsg/Errrormsg";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import {  useDispatch } from 'react-redux';
import { setuplState } from '../../../feature/showSlice/uplstateSlice';
import { Link } from "react-router-dom";
import { errorstatuscheck, reloginMsg } from "../../../components/language/Language";
import { handle401Error } from "../../../components/handle401Error";
import Googleadshorizontal from "../../../components/google-AdSense/Googleadshorizontal";


function Myleagues() {
  const userid=Cookies.get("id")
  const [leaguesget, setLeaguesget] = useState([]);
  const [fetchdata , setFetchData] = useState(false);
  const Navigate=useNavigate();
  const [pageN, setPageN] = useState(1);
  const itemsPerPage = 4; // Replace with your actual items per page
  const totalPages = Math.ceil(leaguesget?.length / itemsPerPage);
  const reversedData = leaguesget?.slice().reverse(); // Create a copy of the array and reverse it
  const currentPageData = reversedData?.slice((pageN- 1) * itemsPerPage, pageN * itemsPerPage);


  const handlePageClick = ({ selected }) => {
    setPageN(selected+1);
  };
  
  const dispatch = useDispatch();

    useEffect(() => {
      const myleaguesFun = async (userid) => {
        try {
          const response = await myleaguesGet(userid); // You might want to pass some parameters to Dashboardget
          setLeaguesget(response?.data?.data);
        } catch (error) {
          if(error?.message==errorstatuscheck){         
            await handle401Error(cookieNames)
            await Errormsg(reloginMsg);
            await Navigate("/");
          }
          
        }
      };
      
      myleaguesFun(userid);
    }, [fetchdata]);

    
    
    const onSubmitForm = (LeagueId,entries,leaguename,visibility,templateTypeid,iimage,cdate,sdate) => {
      Navigate(`/Draft/${LeagueId}`);
        if(LeagueId){
          dispatch(
            setuplState({
              LeagueId:LeagueId,
              LeagueName: leaguename,
              Entries: entries,
              Visibility:visibility,
              templateTypeId:templateTypeid,
              poster:iimage,
              createdDate:cdate,
              startDateTime:sdate,
            })
          );
        }
       };

  
  return (
    <div>
      <Navbarr/>
      <div className="after-nav-dsubheader containers con_div">
      <Googleadshorizontal props="pt-2 pb-2 text-center"/>
        <Subheader />
  
        <div className="Dasboard-cards">
          <div className="afterdashboard_nav">
          {currentPageData?.length > 0 ? 
          currentPageData?.map((data, index) => (
          <div className="card d-Cardf" key={index}>
            <div className="after_row_div">
              <div className="row">
                <div className="col-xl-1 col-lg-3 col-md-12 col-sm-12 imag-cardclass">
                  <img src={imgpath+data?.image} alt="img" className="dleague-card-img"       onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = fifthitem }}/>
                </div>
                <div className="col-xl-11 col-lg-10 col-md-12 col-sm-12 league-card-content">
                  <div>
                    <p className="card-title">{data?.leagueName}</p>
                    <span className="btn btn-primary PP-btn ">
                      {data?.visibility}
                    </span>
                  </div>
                  <div>
                    <p className="card-title">
                     {data?.templateName}
                      </p>
                    <p className="card-title"> No of Players: {data?.totalPlayers}/{data?.entries}</p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="dleague-card-hr" />
            <nav className="dleague-card-nav">
              <span className="dleague-card-nav-span">
                <div className="time_with_date">
                <div className="cal-time">
                  {" "}
                  <YourIcon />
                  <span className="data m-1"> 
                   
                    { moment(new Date(data?.createdDate + 'Z')).format("dddd, MMMM D, YYYY")}
                    </span>
                </div>
                <div className="timeicon-div">
                  <YourIcon1 />
                  <span className="time m-1"> 
                     
                     { moment(new Date(data?.createdDate + 'Z')).format("h:mm a")}
                     
                     </span>
                </div>
                </div>
                            <div className="d-flex gap-2">
                <div>
                <button  className="btn btn-primary JL-btn"  onClick={()=>onSubmitForm(data?.id,data?.entries,data?.leagueName,data?.visibility,data?.templateTypeId,data?.image,data?.createdDate,data?.startDateTime)}>
                                    Draft 
                  </button>
                </div>
                <div>
                  <Link  to={`/viewdetails/${data?.id}`}className="btn btn-primary JL-btn" >
                    View Details
                  </Link>
                </div>
                </div>
              </span>
            </nav>
          </div>
)):<div>
   <div className="no_league_found" >No data found</div>
                  </div>
                  }       
          </div>
          {leaguesget?.length==0?"":<div>
           <Pagination pageCount={totalPages} handlePageClick={handlePageClick} />
          </div>}
        </div>
      </div>
      <Footer />

      <ScrollButton />
    </div>
  );
}

export default Myleagues;
